import React from "react";
import { DatabaseFieldFormatter } from ".";
import LanguageProvider from "~/shared/components/language-provider";

export function FixedText({ data }: DatabaseFieldFormatter) {
  return (
    <span>
      <LanguageProvider id="database.field.type.fixed-text" />
    </span>
  );
}
