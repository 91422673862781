import "../scss/modal-manage-user.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import { useContext, useEffect, useState } from "react";
import {
  Form,
  FormRow,
  InputContainer,
  InputSwitchContainer,
} from "~/shared/components/dcp-form";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { UserContext } from "~/app";
import { useToastContext } from "~/context/ToastContext";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import UserNewPasswordDialog from "./user-new-password-dialog";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const ModalManageUser = ({ isOpen, model, onClose }) => {
  const { showToast } = useToastContext();
  const { user } = useContext(UserContext);
  const queryClient = useQueryClient();

  const dcpAxiosService = useDcpAxiosService();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [newPasswordDialogState, setNewPasswordDialogState] = useState({
    visible: false,
    password: "",
    userName: "",
  });
  const [
    displayConfirmNewPasswordGeneration,
    setDisplayConfirmNewPasswordGeneration,
  ] = useState(false);
  const [displayConfirmUserDeletion, setDisplayConfirmUserDeletion] =
    useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const ValidateForm = (data) => {
    let erros = {};
    try {
      if (data) {
        if (!data.name || data.name.length <= 0)
          erros.name = (
            <LanguageProvider id={"user.create.name.validation.error"} />
          );
        if (!data.email || !data.email.includes("@"))
          erros.email = (
            <LanguageProvider id={"user.create.email.validation.error"} />
          );
        if (!data.userName || data.userName.length <= 0)
          erros.userName = (
            <LanguageProvider id={"user.create.username.validation.error"} />
          );
      }
    } catch (error) {
      console.error(error);
    }
    return erros;
  };

  const Submit = async (data) => {
    try {
      let model = {
        ...data,
        productsPermissions: [],
      };
      products.forEach((x) => {
        if (x.selectedRole) {
          model.productsPermissions.push({
            aplicationClientId: x.idAplicationClient,
            roleAplicationId: x.selectedRole,
          });
        }
      });
      const { data: userResponse } = await dcpAxiosService.post(
        `${settings.Urls.Admin.Users}/create-user-client`,
        model,
        "Auth"
      );
      if (userResponse && userResponse.status) {
        if (userResponse.data.password) {
          setNewPasswordDialogState({
            visible: true,
            newClientPasswordAction: true,
            password: userResponse.data.password,
            userName: userResponse.data.userName,
          });
        }
        showToast({
          message: LanguageProvider({ id: "user.save.success" }),
          severity: "success",
        });
        CloseModal();
      } else {
        showToast({
          severity: "error",
          message: userResponse.message,
        });
      }
    } catch (error) {
      console.error(error);
      CloseModal();
    }
  };

  const IsFormValid = (name) => {
    return !!(userFormik.touched[name] && userFormik.errors[name]);
  };
  const GetFormError = ({ name }) => {
    return IsFormValid(name) ? (
      <small className="p-error">{userFormik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const userMutation = useMutation({
    mutationFn: Submit,
    onSuccess: (_, variables) => {
      const cachedUsers = queryClient.getQueryData(["users"]);
      if (cachedUsers) {
        const userIndex = cachedUsers.findIndex(
          (user) => user.userId === variables.userId
        );

        if (userIndex !== -1) {
          const updatedUsers = [...cachedUsers];
          updatedUsers[userIndex] = {
            ...updatedUsers[userIndex],
            ...variables,
          };

          queryClient.setQueryData(["users"], updatedUsers);
        } else {
          variables.active = true;
          variables.createdAt = new Date().toISOString();

          queryClient.setQueryData(["users"], (oldUsersData) => {
            return [...oldUsersData, variables];
          });

          const cachedUsers = queryClient.getQueryData(["users"]);
        }
      }
    },
  });

  const userFormik = useFormik({
    initialValues: {
      userId: "00000000-0000-0000-0000-000000000000",
      name: "",
      email: "",
      userName: "",
      projects: [],
      createdAt: undefined,
      active: false,
      password: "",
    },
    validate: ValidateForm,
    onSubmit: (values) => {
      userMutation.mutateAsync(values);
    },
  });

  const HandleFormValueChange = (changeEvent) => {
    userFormik.setFieldValue(changeEvent.target.id, changeEvent.target.value);
  };
  const CloseModal = () => {
    if (onClose) {
      userFormik.resetForm();
      onClose();
    }
  };
  const HandleNameBlur = (event) => {
    try {
      let nameParts = event.target.value.split(" ");
      let lastName = nameParts[nameParts.length - 1];
      let userName = nameParts[0];
      if (lastName !== userName) {
        userName += lastName;
      }
      userName = userName.toLowerCase().normalize("NFD");
      userFormik.setFieldValue("userName", userName);
    } catch (error) {
      console.error(error);
    }
  };
  const HandleProductPermissionChange = (value, index) => {
    let updatedProduct = products;
    updatedProduct[index].selectedRole = value;
    setProducts([...updatedProduct]);
  };
  const LoadProducts = async () => {
    setLoading(true);
    const { data } = await dcpAxiosService.get(
      `${settings.Urls.Admin.Users}/list-products-permissions?userId=${userFormik.values.userId}`,
      "Auth"
    );
    if (data.data) {
      setProducts(
        data.data.map((x) => {
          return {
            ...x,
            selectedRole: x.selectedRole === 0 ? undefined : x.selectedRole,
          };
        })
      );
    } else {
      showToast({
        severity: "error",
        message: <LanguageProvider id={"data.message"} />,
      });
    }
    setLoading(false);
  };
  const HandleNewPasswordDialogClose = () => {
    setNewPasswordDialogState({
      visible: false,
      password: "",
      userName: "",
    });
  };
  const OnGenerateNewUserPasswordClick = () => {
    setDisplayConfirmNewPasswordGeneration(true);
  };
  const OnDeleteUserClick = () => {
    setDisplayConfirmUserDeletion(true);
  };
  const HandleGenerateNewPassword = async () => {
    setDisplayConfirmNewPasswordGeneration(false);
    try {
      const { data: userPasswordUpdateReponse } = await dcpAxiosService.post(
        `${settings.Urls.Admin.Users}/update-user-password`,
        {
          userId: userFormik.values.userId,
          autoGenerate: true,
          oldPassword: null,
          newPassword: null,
        },
        "Auth"
      );
      if (userPasswordUpdateReponse.status) {
        setNewPasswordDialogState({
          visible: true,
          newClientPasswordAction: false,
          password: userPasswordUpdateReponse.data.password,
          userName: userPasswordUpdateReponse.data.userName,
        });
      } else {
        showToast({
          severity: "error",
          message: LanguageProvider({ id: userPasswordUpdateReponse.message }),
        });
      }
    } catch (error) {
      showToast({
        severity: "error",
        message: LanguageProvider({ id: "user.password.update.error" }),
      });
    }
  };

  const HandleDeleteUser = async () => {
    setDisplayConfirmUserDeletion(false);
    try {
      const { data: userDeletionReponse } = await dcpAxiosService.delete(
        `${settings.Urls.Admin.Users}/delete-user?userId=${userFormik.values.userId}`,
        "Auth"
      );
      if (userDeletionReponse.status) {
        showToast({
          severity: "success",
          message: LanguageProvider({ id: "gen.message.user.deleted" }),
        });
      } else {
        showToast({
          severity: "error",
          message: LanguageProvider({ id: userDeletionReponse.message }),
        });
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: LanguageProvider({ id: "gen.error" }),
      });
    }
    CloseModal();
  };

  useEffect(() => {
    if (isOpen) {
      userFormik.setValues(model ?? userFormik.initialValues);
    }
  }, [isOpen]);

  useEffect(() => {
    LoadProducts();
  }, [userFormik.values.userId]);

  return (
    <>
      <Dialog
        className="modal-manage-user"
        visible={isOpen}
        onHide={CloseModal}
        header={() => {
          return (
            <div className="modal-header">
              <div className="modal-title-wrapper">
                {userFormik.values != null &&
                userFormik.values.createdAt != null ? (
                  <LanguageProvider id={"gen.update.user"} />
                ) : (
                  <LanguageProvider id={"gen.create.user"} />
                )}
              </div>
            </div>
          );
        }}
        footer={() => {
          return (
            <div className="modal-footer">
              <div className="dcp-btn-wrapper-manage-modal">
                <Button
                  label={<LanguageProvider id={"gen.cancel.button"} />}
                  size="medium"
                  className="p-button p-button-text p-button-plain"
                  onClick={CloseModal}
                />
                <Button
                  label={
                    userFormik.values && userFormik.values.createdAt != null ? (
                      <LanguageProvider id={"gen.save.button"} />
                    ) : (
                      <LanguageProvider id={"gen.add.register"} />
                    )
                  }
                  type="submit"
                  size="medium"
                  appearance="primary"
                  className="p-button"
                  onClick={() => userFormik.handleSubmit()}
                />
              </div>
            </div>
          );
        }}
      >
        <div className="form-wrapper">
          <Form>
            <FormRow>
              <InputContainer
                label={<LanguageProvider id={"gen.complete.name"} />}
                required
              >
                <InputText
                  id="name"
                  name="name"
                  className={[
                    classNames({
                      "p-invalid": IsFormValid("name"),
                    }),
                  ]}
                  value={userFormik.values.name}
                  onChange={HandleFormValueChange}
                  onBlur={HandleNameBlur}
                />
                <GetFormError name={"name"} />
              </InputContainer>
            </FormRow>
            <FormRow>
              <InputContainer
                label={<LanguageProvider id={"gen.email.form"} />}
                required
              >
                <InputText
                  id="email"
                  name="email"
                  className={[
                    classNames({
                      "p-invalid": IsFormValid("email"),
                    }),
                  ]}
                  value={userFormik.values.email}
                  onChange={HandleFormValueChange}
                />
                <GetFormError name={"email"} />
              </InputContainer>
            </FormRow>
            <FormRow>
              <InputContainer
                label={<LanguageProvider id={"gen.user.name.form"} />}
                required
              >
                <div
                  className={classNames({
                    "p-inputgroup": true,
                    "p-invalid": IsFormValid("userName"),
                  })}
                >
                  <span className="p-inputgroup-addon domain">
                    {user.domain}\
                  </span>
                  <InputText
                    id="userName"
                    name="userName"
                    value={userFormik.values.userName}
                    className="modal-user-field-username"
                    onChange={HandleFormValueChange}
                  />
                </div>
                <GetFormError name={"userName"} />
              </InputContainer>
            </FormRow>
            <div className="dcp-table-modal">
              <DataTable value={products} loading={loading}>
                <Column
                  header={<LanguageProvider id={"gen.platform"} />}
                  field="projectName"
                  body={(rowData) => {
                    return <span>{rowData.projectDescription}</span>;
                  }}
                />
                <Column
                  header={<LanguageProvider id={"gen.profile"} />}
                  field="roles"
                  body={(rowData, params) => {
                    return (
                      <Dropdown
                        value={rowData.selectedRole}
                        options={rowData.roles}
                        className="permissions-dropdown"
                        showClear
                        optionValue="id"
                        optionLabel="code"
                        onChange={(e) =>
                          HandleProductPermissionChange(
                            e.value,
                            params.rowIndex
                          )
                        }
                        placeholder={"product.permission.no.access"}
                        itemTemplate={(option) => {
                          if (option)
                            return <LanguageProvider id={option.code} />;
                        }}
                        valueTemplate={(option, props) => {
                          if (option)
                            return <LanguageProvider id={option.code} />;
                          return <LanguageProvider id={props.placeholder} />;
                        }}
                      />
                    );
                  }}
                />
              </DataTable>
            </div>
            {userFormik.values && userFormik.values.createdAt != null && (
              <FormRow>
                <InputContainer>
                  <Accordion
                    activeIndex={activeIndex}
                    className="danger-zone"
                    onTabChange={(e) => setActiveIndex(e.index)}
                  >
                    <AccordionTab
                      header={
                        <div className="header">
                          <div className="title-header">
                            <Icon
                              className={"new-pass-icon"}
                              icon={"alert-triangle"}
                              color="#f9485b"
                              size={24}
                            />
                            <LanguageProvider id={"gen.danger.zone"} />
                          </div>
                        </div>
                      }
                    >
                      <div className="danger-zone-body-wrapper">
                        <div className="action-wrapper">
                          <div className="body-wrapper">
                            <div className="title">
                              <LanguageProvider id="user.new.password" />
                            </div>
                            <div className="description">
                              <LanguageProvider id="user.new.password.temporary.description" />
                            </div>
                          </div>
                          <div className="button-wrapper">
                            <div className="dcp-btn-wrapper-newpass">
                              {userFormik.values != null &&
                                userFormik.values.createdAt != null && (
                                  <Button
                                    label={
                                      <LanguageProvider
                                        id={"user.create.new.password"}
                                      />
                                    }
                                    type="button"
                                    onClick={OnGenerateNewUserPasswordClick}
                                    size="medium"
                                    className="p-button-secondary"
                                    icon={
                                      <Icon
                                        className={"new-pass-icon"}
                                        icon={"passcode-lock"}
                                        color="#4146ff"
                                      />
                                    }
                                    iconPos="left"
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="action-wrapper">
                          <div className="body-wrapper">
                            <div className="title">
                              <LanguageProvider id="user.status" />
                            </div>
                            <div className="description">
                              <LanguageProvider id="user.status.access.description" />
                            </div>
                          </div>
                          <div className="button-wrapper">
                            <FormRow>
                              <InputSwitchContainer>
                                <InputSwitch
                                  checked={userFormik.values.active}
                                  onChange={(e) =>
                                    userFormik.setFieldValue("active", e.value)
                                  }
                                  className={classNames({
                                    "p-inputswitch-sm": true,
                                  })}
                                ></InputSwitch>
                              </InputSwitchContainer>
                            </FormRow>
                          </div>
                        </div>
                        <div className="action-wrapper">
                          <div className="body-wrapper">
                            <div className="title">
                              <LanguageProvider id="user.delete.confirmation.title" />
                            </div>
                            <div className="description">
                              <LanguageProvider id="user.delete.confirmation.description" />
                            </div>
                          </div>
                          <div className="button-wrapper">
                            <Button
                              className="p-button p-button-sm p-button-secondary user-btn-delete"
                              label={"Deletar"}
                              icon={<Icon icon={"trash-02"} color="#F9485B" />}
                              iconPos="left"
                              onClick={OnDeleteUserClick}
                              type="button"
                            />
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </InputContainer>
              </FormRow>
            )}
          </Form>
        </div>
      </Dialog>
      <UserNewPasswordDialog
        visible={newPasswordDialogState.visible}
        newClientAction={newPasswordDialogState.newClientPasswordAction}
        newPassword={newPasswordDialogState.password}
        userName={newPasswordDialogState.userName}
        onClose={HandleNewPasswordDialogClose}
      />
      <ModalConfirmation
        isOpen={displayConfirmNewPasswordGeneration}
        modalTitle={<LanguageProvider id={"gen.confirm.new.pass"} />}
        bodyMessage={
          <LanguageProvider id={"gen.message.user.generate.new.pass"} />
        }
        onClose={() => setDisplayConfirmNewPasswordGeneration(false)}
        onConfirm={HandleGenerateNewPassword}
        onCancel={() => setDisplayConfirmNewPasswordGeneration(false)}
      />
      <ModalConfirmation
        isOpen={displayConfirmUserDeletion}
        isDelete={true}
        modalTitle={<LanguageProvider id={"gen.confirm.user.delete"} />}
        bodyMessage={<LanguageProvider id={"gen.message.user.deletion"} />}
        onClose={() => setDisplayConfirmUserDeletion(false)}
        onConfirm={HandleDeleteUser}
        onCancel={() => setDisplayConfirmUserDeletion(false)}
      />
    </>
  );
};

export default ModalManageUser;
