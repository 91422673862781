import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import {
  LocationLastUpdate,
  DataTableFilter,
  InventoryListModel,
  InventoryModel,
  RackLocationsInfoModel,
} from "~/shared/interfaces";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

/**
 * Retrieves the list of inventories according to filter
 * @param filter - Filter object to be used to return the list.
 * @returns A Promise that resolves to the inventory list.
 */
export async function listInventories(
  filter: DataTableFilter
): Promise<InventoryListModel> {
  try {
    const { data, status } = await axiosService().post(
      settings.Urls.Rest.Inventory + "/list",
      filter,
      "Inventory"
    );

    if (status === 200 && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves the inventory details
 * @param inventoryId - The ID of the inventory.
 * @returns A Promise that resolves to the inventory details.
 */
export async function getInventory(
  inventoryId: number | string
): Promise<InventoryModel> {
  try {
    const { data, status } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/" + inventoryId,
      "Inventory"
    );

    if (status === 200 && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves inventory locations based on the provided area ID and inventory ID.
 *
 * @param areaId - The ID of the area for which to retrieve inventory locations.
 * @param inventoryId - The ID of the inventory to filter locations by.
 * @returns A Promise that resolves to the inventory locations data if successful, or null if no data is found or an error occurs.
 */
export async function getInventoryLocations(
  areaId: number | string,
  inventoryId: number | string
): Promise<any> {
  try {
    const { data } = await axiosService().get(
      `${settings.Urls.Rest.Inventory}/inventory-locations`,
      "Inventory",
      { params: { areaId, inventoryId } }
    );
    if (data && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function getReportCountSummary(inventoryId: number): Promise<any> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/count-summary",
      "Inventory",
      { params: { inventoryId: inventoryId } }
    );

    if (data && data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function getRackLocationsInfoModel(
  areaId: number
): Promise<RackLocationsInfoModel[]> {
  try {
    const { status, data } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/area-history",
      "Inventory",
      { params: { areaId: areaId } }
    );

    if (status === 200 && data.data) return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}

export async function getLocationsLastUpdate(
  areaGroupRackId: number
): Promise<LocationLastUpdate[]> {
  try {
    const { status, data } = await axiosService().get(
      settings.Urls.Rest.Inventory + "/rack-locations-history",
      "Inventory",
      { params: { areaGroupRackId: areaGroupRackId } }
    );

    if (status === 200 && data.data) {
      data.data.forEach((location) => {
        if (location.lastUpdate)
          location.lastUpdate = new Date(location.lastUpdate);
      });
      return data.data;
    } else return [];
  } catch (error) {
    console.error(error);
  }
}
