import "./scss/main-layout.scss";
import React, { createContext, useEffect, useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Outlet, useParams } from "react-router-dom";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import {
  getFromStorage,
  propertyFrommStorage,
  writeStorage,
} from "~/services/storage/storage-access";
import settings from "~/services/settings.json";
import LoadingIndicator from "./components/dcp-loading-indicator";
import NavMenu from "./nav/nav-menu";
import SideMenu from "./nav/side-menu";
import { AddItemButtonPopup } from "./nav/product-navigation/components/add-item-popup";

export const ProductContext = createContext();

const MainLayout = () => {
  const { projectId } = useParams();
  const dcpAxiosService = useDcpAxiosService();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true);
  const [splitterPanelSideMenuWidthValue, setSplitterPanelSideMenuWidthValue] =
    useState(14);
  const [splitterPanelContentWidthValue, setSplitterPanelContentWidthValue] =
    useState(86);

  const spliterPanelDimensions = () => {
    var splitterPanelSideMenuWidth = getFromStorage("sideMenuWidth");
    var splitterPanelContentWidth = getFromStorage("contentMenuWidth");

    console.log(splitterPanelSideMenuWidth);
    console.log(splitterPanelContentWidth);

    if (!splitterPanelSideMenuWidth || !splitterPanelContentWidth) {
      writeStorage("sideMenuWidth", splitterPanelSideMenuWidthValue);
      writeStorage("contentMenuWidth", splitterPanelContentWidthValue);
    }

    setSplitterPanelSideMenuWidthValue(splitterPanelSideMenuWidth);
    setSplitterPanelContentWidthValue(splitterPanelContentWidthValue);
  };

  const handleResizeEnd = (args) => {
    const { sizes } = args;
    console.log("AQUI", sizes);

    setSplitterPanelSideMenuWidthValue(sizes[0]);
    setSplitterPanelContentWidthValue(sizes[1]);
    writeStorage("sideMenuWidth", sizes[0]);
    writeStorage("contentMenuWidth", sizes[1]);
  };

  const LoadCurrentProduct = async () => {
    try {
      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Admin.ApplicationClient}/list-user-products`,
        "Auth"
      );

      if (data.status) {
        const selectedProduct = data.data.find(
          (product) => product.instanceCode == projectId
        );

        if (selectedProduct) {
          setProduct(selectedProduct);
          const appId = selectedProduct.idApplicationClient;
          dcpAxiosService.setHeaders("Inventory");
          dcpAxiosService.setHeaders("Platform");

          const storedAuth = getFromStorage("authentication");
          storedAuth.applicationId = appId;
          writeStorage("authentication", storedAuth);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    LoadCurrentProduct();
    spliterPanelDimensions();
    setLoading(false);
  }, []);

  useEffect(() => {
    LoadCurrentProduct();
  }, [projectId]);

  if (!loading) {
    return (
      <ProductContext.Provider value={{ product }}>
        <NavMenu />
        <Splitter onResizeEnd={handleResizeEnd}>
          <SplitterPanel
            className="main-layout-splitter-panel-side-menu-container"
            size={splitterPanelSideMenuWidthValue}
            style={{
              width: `${splitterPanelSideMenuWidthValue}%`,
              flexBasis: `calc(${splitterPanelSideMenuWidthValue} + % - 4px)`,
              backgroundColor: "--var(--systemBackground)",
            }}
          >
            <SideMenu />
            <AddItemButtonPopup />
          </SplitterPanel>
          <SplitterPanel
            className="main-layout-splitter-panel-content-container"
            size={splitterPanelContentWidthValue}
            style={{
              width: `${splitterPanelContentWidthValue}%`,
              flexBasis: `calc(${splitterPanelContentWidthValue} + % - 4px)`,
            }}
          >
            <div className="main-layout">
              <div className="body">
                <div className="page-content">
                  <div className="page-body">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </SplitterPanel>
        </Splitter>
      </ProductContext.Provider>
    );
  }

  return <LoadingIndicator />;
};

export default MainLayout;
