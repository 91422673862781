import "./Title.scss";

export default function Title(title, configs = []) {
  const fontSizeConfig = configs.find(
    (config) => config.defaultConfig.name === "font-size"
  );

  return (
    <div className="title-type">
      <h2 style={{ fontSize: `${fontSizeConfig.value ?? 20}px` }}>{title}</h2>
    </div>
  );
}
