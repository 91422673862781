import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs.jsx";
import { GenericCounter } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/custom-charts-components/generic-counter";
import { getConfigValue } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers.jsx";

export default function SequenceGenericCounter(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [currentValue, setCurrentValue] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (useMockData) {
      setCurrentValue(74);
      setTitle("Qtd Pedidos Abertos");
    } else {
      // load from api
      setCurrentValue(0);
      setTitle(getConfigValue(configs, "count-title") ?? "");
    }
  }, [useMockData, configs]);

  return <GenericCounter title={title} value={currentValue} />;
}
