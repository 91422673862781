import React from "react";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { SimpleTextProps } from ".";
import inputMasks from "~/shared/helpers/input-masks";

export function SimpleText(props: SimpleTextProps): JSX.Element {
  const mask = inputMasks[props.inputMaskName] || null;

  function removeMaskFromCurrency(value: string) {
    if (!value) return;
    if (props.hasInputMask && props.inputMaskName === "currency" && mask) {
      value.replace("$", "");
      let parsedValue = parseFloat(value);
      return parsedValue;
    } else {
      return value;
    }
  }

  return (
    <>
      <InputText
        tooltip={props.tooltip}
        style={{ width: "100%" }}
        placeholder={props.placeholder}
        className={classNames({
          "p-invalid": props.error !== null || props.error !== undefined,
        })}
        value={props.hasInputMask ? mask(props.value) : props.value}
        id={props.fieldKey}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        onBlur={props.onBlur}
      />
      {props.error ? (
        <small className="p-error">{props.error}</small>
      ) : (
        <small className="p-error">&nbsp;</small>
      )}
    </>
  );
}
