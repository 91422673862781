import { Dropdown } from "primereact/dropdown";
import LanguageProvider from "~/shared/components/language-provider";

export function WMSCounterSourceConfig({
  config,
  updateValue = function () {},
}) {
  const options = [
    {
      code: "total-locations",
      label: LanguageProvider({ id: `dropdown.option.total-locations` }),
    },
    {
      code: "occupied-locations",
      label: LanguageProvider({ id: "dropdown.option.occupied-locations" }),
    },
    {
      code: "free-locations",
      label: LanguageProvider({ id: "dropdown.option.free-locations" }),
    },
    {
      code: "open-shipping-orders",
      label: LanguageProvider({ id: "dropdown.option.open-shipping-orders" }),
    },
    {
      code: "open-receipt-orders",
      label: LanguageProvider({ id: "dropdown.option.open-receipt-orders" }),
    },
  ];

  return (
    <Dropdown
      value={config.value}
      options={options}
      optionLabel="label"
      optionValue="code"
      onChange={(e) => updateValue(e.value)}
    ></Dropdown>
  );
}
