function useHeaderHeight() {
  try {
    let height = 0;
    const navMenu = document.querySelector(".nav-menu");
    const navContainer = document.querySelector(
      ".product-navigation-container"
    );
    if (navContainer) height += navContainer.offsetHeight;
    if (navMenu) height += navMenu.offsetHeight;
    return height;
  } catch (error) {
    console.error(error);
  }
}

export { useHeaderHeight };
