import "./scss/users.scss";

import { useCallback, useContext, useEffect, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~shared/components/language-provider";
import ModalManageUser from "./components/modal-manager-user";
import { ThemeContext } from "~/app";
import { Tooltip } from "primereact/tooltip";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useToastContext } from "~/context/ToastContext";
import { useQuery } from "@tanstack/react-query";
import { getUsers } from "~/services/api/auth/user.ts";

const Users = () => {
  const { currentTheme } = useContext(ThemeContext);
  const { showToast } = useToastContext();
  const dcpAxiosService = useDcpAxiosService();

  const [totalItems, setTotalItems] = useState(0);
  const [users, setUsers] = useState([]);

  // Modals
  const [modalManageUserIsOpen, setModalManageUserIsOpen] = useState(false);
  const [ManageUserModel, setManageUserModel] = useState();

  const fetchUsers = async () => {
    const data = await getUsers();

    if (data.status) {
      setTotalItems(data.data.length);
      return data.data;
    } else {
      showToast({
        severity: "error",
        message: LanguageProvider({ id: data.message }),
      });
      return [];
    }
  };

  const { data: usersData, isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
  });

  const OpenModalManageUser = (model) => {
    setManageUserModel(model);
    setModalManageUserIsOpen(true);
  };
  const CloseModalManageUser = () => {
    setModalManageUserIsOpen(false);
    setManageUserModel();
  };

  const RenderUserInitials = (name) => {
    let names = name.split(" ");
    let initials = "";
    for (let index = 0; index < names.length; index++) {
      if (initials.length <= 1) {
        initials += names[index].substring(0, 1).toUpperCase();
      } else {
        break;
      }
    }
    return initials;
  };

  return (
    <div className="user-dashboard">
      <div className="user-dashboard-container">
        <div className="user-dashboard-content">
          <div className="content-header">
            <div className="total-items-wrapper">
              <span className="header-message">
                <div className="page-identifier-wrapper">
                  <span className="page-name">
                    <LanguageProvider id={"gen.users"} />
                  </span>
                </div>
                <div className="total-table-itens">
                  <span className="total-register-message">
                    {`${totalItems} ${LanguageProvider({
                      id: "gen.registers",
                    })}`}
                  </span>
                </div>
              </span>
            </div>
            <div className="action-buttons-wrapper">
              <Tooltip target=".user-btn-download" position="top">
                <span>
                  <LanguageProvider id={"gen.download"} />
                </span>
              </Tooltip>
              <Button
                className="p-button user-btn-add"
                label={<LanguageProvider id={"gen.add.register"} />}
                onClick={() => OpenModalManageUser()}
                icon={<Icon icon={"plus"} color="#ffffff" />}
                iconPos="left"
              />
            </div>
          </div>
          <div className="content-body">
            <div className="dcp-table">
              <DataTable
                value={usersData}
                removableSort
                rows={10}
                paginator
                loading={isLoading}
              >
                <Column
                  header={<LanguageProvider id={"gen.name"} />}
                  body={(rowData) => (
                    <div className="user-cell">
                      {rowData.active ? (
                        <span
                          className="user-identification active"
                          title="Ativo"
                        >
                          {RenderUserInitials(rowData.name)}
                        </span>
                      ) : (
                        <span
                          className="user-identification active"
                          title="Inativo"
                        >
                          {RenderUserInitials(rowData.name)}
                        </span>
                      )}
                      <span className="user-name">{rowData.name}</span>
                    </div>
                  )}
                  field="name"
                  filter
                  showFilterOperator={false}
                  filterPlaceholder={LanguageProvider({
                    id: `${LanguageProvider({
                      id: "gen.filter.by",
                    })} ${LanguageProvider({ id: "gen.name" })}`,
                  })}
                  sortable
                />
                <Column
                  header={<LanguageProvider id={"gen.user.name"} />}
                  field="userName"
                  filter
                  filterPlaceholder={LanguageProvider({
                    id: `${LanguageProvider({
                      id: "gen.filter.by",
                    })} ${LanguageProvider({ id: "gen.user.name.form" })}`,
                  })}
                  sortable
                />
                <Column
                  header={<LanguageProvider id={"gen.status"} />}
                  body={(rowData) => {
                    return (
                      <div className="status-cell">
                        {rowData.active ? (
                          <span
                            className="status-identification active"
                            title="Ativo"
                          >
                            <Icon
                              icon={"dot"}
                              size={8}
                              color={currentTheme.tableStatusIconActiveColor}
                            />
                            {<LanguageProvider id="gen.active" />}
                          </span>
                        ) : (
                          <span
                            className="status-identification inactive"
                            title="Inativo"
                          >
                            <Icon
                              icon={"dot"}
                              size={8}
                              color={currentTheme.tableStatusIconInactiveColor}
                            />
                            {<LanguageProvider id="gen.inactive" />}
                          </span>
                        )}
                      </div>
                    );
                  }}
                  field="active"
                  filter
                  filterPlaceholder={LanguageProvider({
                    id: `${LanguageProvider({
                      id: "gen.filter.by",
                    })} ${LanguageProvider({ id: "gen.status" })}`,
                  })}
                  sortable
                />
                <Column
                  header={<LanguageProvider id={"gen.email"} />}
                  field="email"
                  filter
                  showFilterOperator={false}
                  filterPlaceholder={LanguageProvider({
                    id: `${LanguageProvider({
                      id: "gen.filter.by",
                    })} ${LanguageProvider({ id: "gen.email" })}`,
                  })}
                  sortable
                />
                <Column
                  header={<LanguageProvider id={"gen.products"} />}
                  field="projects"
                  body={(rowData) => {
                    return <RenderProducts projects={rowData.projects} />;
                  }}
                  filter
                  sortable
                  filterPlaceholder={LanguageProvider({
                    id: `${LanguageProvider({
                      id: "gen.filter.by",
                    })} ${LanguageProvider({ id: "gen.products" })}`,
                  })}
                  showFilterMatchModes={false}
                  filterElement={(options) => {
                    return (
                      <>
                        <Dropdown
                          value={options.value}
                          onChange={(e) =>
                            options.filterCallback(e.value, options.index)
                          }
                        />
                      </>
                    );
                  }}
                />
                <Column
                  header={<LanguageProvider id={"gen.create.at"} />}
                  headerClassName="custom-header-created-at-class"
                  field="createdAt"
                  body={(rowData) => {
                    const createdAtDate = new Date(rowData.createdAt);
                    const formattedDate = createdAtDate.toLocaleDateString();
                    const formattedTime = createdAtDate.toLocaleTimeString();

                    return (
                      <>
                        <div>
                          {formattedDate} {formattedTime}
                        </div>
                      </>
                    );
                  }}
                  filter
                  filterPlaceholder={LanguageProvider({
                    id: `${LanguageProvider({
                      id: "gen.filter.by",
                    })} ${LanguageProvider({ id: "gen.filter.by.creation" })}`,
                  })}
                  sortable
                />
                <Column
                  header={null}
                  headerClassName="custom-header-actions-class"
                  key={"actions"}
                  body={(rowData) => {
                    return (
                      <div className="column-wrapper">
                        <div className="column-actions">
                          <div className="icon-wrapper">
                            <Icon
                              icon={"edit-02"}
                              size={20}
                              onClick={() => OpenModalManageUser(rowData)}
                              className="icon-edit"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </DataTable>

              <ModalManageUser
                isOpen={modalManageUserIsOpen}
                model={ManageUserModel}
                onClose={CloseModalManageUser}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderProducts = ({ projects }) => {
  const firstTwoProjects = projects.slice(0, 2);
  const remainingProjects = projects.length - 2;

  if (Array.isArray(projects))
    return (
      <div className="products-wrapper">
        {firstTwoProjects.map((project, index) => {
          return (
            <div
              key={`project-${index}`}
              className={`project ${project.project
                .toLowerCase()
                .replaceAll(".", "-")}`}
            >
              <span className="project-name">
                <LanguageProvider id={`${project.project}`} />
              </span>
            </div>
          );
        })}

        {remainingProjects > 0 && (
          <div className="remaining-projects">{`+${remainingProjects}`}</div>
        )}
      </div>
    );

  return null;
};

export default Users;
