import "./scss/dcp-button.scss";
import React from "react";
import * as PrimeButton from "primereact/button";

export interface DcpButtonProps extends PrimeButton.ButtonProps {
  appearance?: "primary" | "secondary" | "terciary";
  buttonStyle?: "fill" | "outline";
}

export const Button = (props: DcpButtonProps) => {
  let className = props.className || "";
  className += " dcp-button " + className;
  switch (props.buttonStyle) {
    case "fill":
      className += " dcp-button-fill";
      break;
    case "outline":
      className += " dcp-button-outline";
      break;
    default:
      break;
  }
  switch (props.appearance) {
    case "secondary":
      className += " dcp-button-secondary p-button-link";
      break;
    case "terciary":
      className += " dcp-button-terciary p-button-link";
      break;
    default:
      break;
  }

  return (
    <PrimeButton.Button {...props} className={className}>
      {props.children}
    </PrimeButton.Button>
  );
};
