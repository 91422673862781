import { InputTextarea } from "primereact/inputtextarea";

export function TextAreaConfig({ config, updateValue, onKeyDown }) {
  return (
    <InputTextarea
      value={config.value}
      onChange={(e) => {
        updateValue(e.target.value);
      }}
      rows={5}
      onKeyDown={onKeyDown}
    />
  );
}
