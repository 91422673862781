import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import LanguageProvider from "~shared/components/language-provider";
import { NewEntryEditDialog } from "../components/new-entry-edit";
import {
  getDatabaseItems,
  saveDatabaseItem,
  getDatabase,
  deleteDatabaseItem,
  saveDatabaseItemsFromUploadedFile,
} from "~/services/api/";
import { FieldFormatters } from "../field-formatters/index";
import "../scss/database-list.scss";
import { getFromStorage } from "~/services/storage/storage-access";
import { UploadData, UploadDataDialog } from "../components/upload-data";
import { ThemeContext } from "~/app";
import { useToastContext } from "~/context/ToastContext";
import { HistoricUploadData } from "../components/historic-upload-data";

const DatabaseList = ({ id, hasDetails, uriDetails, showIcon = true }) => {
  const { currentTheme } = useContext(ThemeContext);
  const { showToast } = useToastContext();
  const { idDatabase, itemId, projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isFirstRender = useRef(true);
  const defaultQtyToExportFile = getFromStorage("defaultQtyToExportFile");

  // Data
  const [databaseId, setDatabaseId] = useState(idDatabase);
  const [database, setDatabase] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [filterColumns, setFilterColumns] = useState([]);
  const [globalFilters, setGlobalFilters] = useState("");
  const [originalColumnOrder, setOriginalColumnOrder] = useState([]);
  const [totalRecords, setTotalRecords] = useState(null);
  const [filters, setFilters] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: 1,
    multiSortMeta: [],
    filters: {},
  });

  // Dialog Controll
  const [newEntryEditDialogVisible, setNewEntryEditDialogVisible] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [dataUploadDialogVisible, setDataUploadDialogVisible] = useState(false);
  const [historicUploadDataDialogVisible, setHistoricUploadDataDialogVisible] =
    useState(false);

  // Loading Indicator
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  // Refs
  const columnsOpRef = useRef(null);
  const downloadOptionsOpRef = useRef(null);

  // Set the filters for the first time
  const setupFilterColumns = useCallback(
    (columns) => {
      if (isFirstRender.current) {
        const formattedFilterColumns = {};
        columns.forEach((column) => {
          formattedFilterColumns[column.columnKey] = {
            constraints: [
              {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH,
              },
            ],
          };
        });

        setFilters({ ...filters, filters: formattedFilterColumns });
        isFirstRender.current = false;
      }
    },
    [filters]
  );

  const loadDatabase = useCallback(async (idDatabaseUE) => {
    try {
      const _database = await getDatabase(idDatabaseUE);
      setDatabase(_database);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const loadDatabaseItems = useCallback(
    async (databaseId, applyFilters) => {
      const _filter = formatFilterRequest(filters, applyFilters);
      const _databaseItems = await getDatabaseItems(
        databaseId,
        itemId,
        _filter
      );

      const columns = _databaseItems.headers;
      const rows = _databaseItems.items.map((item) => {
        return { ...item.columns, itemId: item.itemId };
      });

      setTableRows(rows);
      setupFilterColumns(_databaseItems.headers);
      setTotalRecords(_databaseItems.totalRows);
      setTableColumns(columns);
      setFilterColumns(columns);
    },
    [filters, itemId, setupFilterColumns]
  );

  const loadDatabaseItemsToExport = async (idDatabase, applyFilters) => {
    const _filter = formatFilterRequest(filters, applyFilters);
    const _databaseItems = await getDatabaseItems(idDatabase, itemId, _filter);
    const rows = _databaseItems.items.map((item) => {
      return { ...item.columns };
    });
    return rows;
  };

  const handleDeleteUploadedDatabaseItems = async (value) => {
    if (value) {
      showToast({
        severity: "success",
        message: LanguageProvider({
          id: "gen.deleted.uploaded.file.registers",
        }),
      });
      await loadDatabaseItems(databaseId, false);
    }
  };

  // Upload data function
  const saveDatabaseNewValues = async (dataItem) => {
    try {
      const payload = {
        databaseId: database.id,
        columns: dataItem.columns,
        itemId: selectedItem.itemId,
        uploadedFileName: dataItem.fileName,
        parentDatabaseitemId: itemId,
      };

      const saved = await saveDatabaseItemsFromUploadedFile(payload);
      if (saved) {
        await loadDatabaseItems(databaseId, false);
      } else {
        console.log(saved);
      }
    } catch (error) {
      console.error(error);
    }
    setDataUploadDialogVisible(false);
    showToast({
      severity: "success",
      message: LanguageProvider({ id: "gen.upload.success" }),
    });
  };

  const saveDataItem = async (dataItem) => {
    console.log(dataItem);
    setSaving(true);
    delete dataItem.itemId;

    try {
      const payload = {
        databaseId: database.id,
        columns: dataItem,
        itemId: selectedItem.itemId,
        parentDatabaseitemId: itemId,
      };
      console.log(payload);

      const saved = await saveDatabaseItem(payload);
      if (saved) {
        await loadDatabaseItems(databaseId, false);
      }
    } catch (error) {
      console.error(error);
    }
    setNewEntryEditDialogVisible(false);
    setSaving(false);
    setLoading(false);
  };
  const onAddNew = () => {
    let emptyItem = { itemId: 0 };
    tableColumns.forEach((column) => {
      emptyItem[column.columnKey] = "";
    });

    setSelectedItem(emptyItem);
    setNewEntryEditDialogVisible(true);
  };
  const onViewDetails = (rowData) => {
    navigate(`${uriDetails}/${rowData.itemId}`);
  };
  const onEdit = (item) => {
    setSelectedItem(item);
    setNewEntryEditDialogVisible(true);
  };
  const onDelete = (item) => {
    setSelectedItem(item);
    setDeleteConfirmationVisible(true);
  };
  const deleteItem = async () => {
    try {
      if (!selectedItem) return;

      const deleted = await deleteDatabaseItem(
        selectedItem.itemId,
        database.id,
        database.name
      );

      if (deleted) await loadDatabaseItems(database.id, false);
    } catch (error) {
      console.error(error);
    }
  };
  const renderColumn = (rowData, column) => {
    const data = rowData[column.columnKey];
    const Formatter = FieldFormatters[column.columnType.name];
    if (Formatter) return <Formatter data={data} column={column} />;
    else return <span>{data}</span>;
  };
  const onColumnFilterChange = (e) => {
    let _filterColumns = [...filterColumns];

    if (e.checked) _filterColumns.push(e.value);
    else
      _filterColumns = _filterColumns.filter(
        (column) => column.columnKey !== e.value.columnKey
      );

    const orderedColumns = originalColumnOrder.filter((column) =>
      _filterColumns.some((item) => item.columnKey === column.columnKey)
    );

    setFilterColumns(orderedColumns);
  };
  const exportExcel = async () => {
    const columnHeaders = tableColumns.reduce((acc, column) => {
      acc[column.columnKey] = column.columnName;
      return acc;
    }, {});

    const tableRowsToExport = await loadDatabaseItemsToExport(idDatabase, true);
    if (tableRowsToExport && tableRowsToExport.length > 0) {
      const dataWithHeaders = [columnHeaders, ...tableRowsToExport];
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(dataWithHeaders, {
          skipHeader: true,
        });
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveFile(excelBuffer, database.name, ".xlsx");
      });
    }
  };

  const exportCsv = async () => {
    const columnHeaders = tableColumns.reduce((acc, column) => {
      acc[column.columnKey] = column.columnName;
      return acc;
    }, {});

    const tableRowsToExport = await loadDatabaseItemsToExport(idDatabase, true);
    if (tableRowsToExport && tableRowsToExport.length > 0) {
      const dataWithHeaders = [columnHeaders, ...tableRowsToExport];
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(dataWithHeaders, {
          skipHeader: true,
        });
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const csvBuffer = xlsx.write(workbook, {
          bookType: "csv",
          type: "array",
        });
        saveFile(csvBuffer, database.name, ".csv");
      });
    }
  };

  const saveFile = (buffer, fileName, ext) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let FILE_EXTENTION = ext;
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + new Date().toLocaleString() + FILE_EXTENTION
        );
      }
    });
  };

  const exportPdf = async () => {
    const tableRowsToExport = await loadDatabaseItemsToExport(idDatabase, true);
    const columns = tableColumns.map((column) => {
      return { title: column.columnName, dataKey: column.columnKey };
    });

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable({ columns: columns, body: tableRowsToExport });
        doc.save(database.name + "_" + new Date().toLocaleString() + ".pdf");
      });
    });
  };

  const onFilter = (e) => {
    const _event = e;
    _event["first"] = 0;

    // hack to fix clear button
    for (const filter in _event.filters) {
      if (Object.hasOwnProperty.call(_event.filters, filter)) {
        let element = _event.filters[filter];
        if (Object.hasOwnProperty.call(element, "value")) {
          delete _event.filters[filter].value;
          delete _event.filters[filter].matchMode;
          _event.filters[filter].constraints[0].value = null;
        }
      }
    }

    setFilters(_event);
  };

  // Format the filter object to be accepted by the endpoint
  function formatFilterRequest(_filter, applyFilters) {
    try {
      if (!applyFilters) {
        var payload = { ..._filter };
      } else {
        var payload = { ..._filter, rows: defaultQtyToExportFile };
      }

      payload.sortOrder = payload.sortOrder ?? 0;

      let _filters = [];

      for (const columnName in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, columnName)) {
          const _filter = payload.filters[columnName];

          if (_filter)
            _filters.push({
              columnName,
              value: _filter.constraints[0].value,
              matchMode: _filter.constraints[0].matchMode,
            });
        }
      }

      payload.filters = _filters;
      return payload;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function load() {
      let idDatabaseUE;
      if (idDatabase) {
        idDatabaseUE = idDatabase;
      } else {
        idDatabaseUE = id;
      }
      setDatabaseId(idDatabaseUE);
      setLoading(true);

      await loadDatabase(idDatabaseUE);
      await loadDatabaseItems(idDatabaseUE, false);
    }
    load();
    setLoading(false);
  }, [location]);

  useEffect(() => {
    setOriginalColumnOrder([...tableColumns]);
  }, [tableColumns]);

  useEffect(() => {
    async function load() {
      setLoading(true);
      if (idDatabase && idDatabase > 0) {
        await loadDatabase(idDatabase);
        await loadDatabaseItems(idDatabase, false);
      }
    }
    load();
    setLoading(false);
  }, [idDatabase, filters, loadDatabase, loadDatabaseItems]);

  return (
    <>
      <div className="database-list">
        <div className="list-area">
          <div className="container">
            <div className="header">
              <div className="title-wrapper">
                <span className="header-message">
                  <div className="page-identifier-wrapper">
                    {showIcon && (
                      <Icon
                        className="icon"
                        icon="database-03"
                        size={24}
                        color={"#7893FF"}
                      />
                    )}
                    <span className="database-name">
                      {database ? database.name : ""}
                    </span>
                  </div>
                </span>
                <div className="total-items-wrapper">
                  <span className="total-items-count">
                    {totalRecords}{" "}
                    <LanguageProvider id="dashboard.database.registers" />
                  </span>
                </div>
              </div>
              <div className="options-wrapper">
                <span className="search-input">
                  <Icon icon={"search-md"} size={20} color={"#667085"} />
                  <InputText
                    className="p-inputtext-md"
                    placeholder={LanguageProvider({
                      id: "search.field.placeholder",
                    })}
                    value={globalFilters}
                    onChange={(e) => setGlobalFilters(e.target.value)}
                  />
                </span>
                <div className="icon-options">
                  <Tooltip target=".btn-column" position="top">
                    <span>
                      <LanguageProvider id="datatable.list.hide.columns" />
                    </span>
                  </Tooltip>
                  <Button
                    text
                    className="p-button p-button-text btn-column"
                    onClick={(e) => columnsOpRef.current.toggle(e)}
                  >
                    <Icon icon="columns-04" color={"#717BBC"} />
                  </Button>
                  <Tooltip target=".btn-edit" position="top">
                    <span>
                      <LanguageProvider id="gen.edit.database" />
                    </span>
                  </Tooltip>
                  <NavLink
                    className="p-button p-button-text btn-edit"
                    to={
                      projectId
                        ? `/${projectId}/database/edit/${databaseId}`
                        : `/database/edit/${databaseId}`
                    }
                  >
                    <Icon icon="edit-05" color={"#717BBC"} />
                  </NavLink>
                  <Tooltip target=".btn-uploadData" position="top">
                    <span>
                      <LanguageProvider id="gen.edit.upload.database" />
                    </span>
                  </Tooltip>
                  <NavLink
                    className="p-button p-button-text btn-uploadData"
                    onClick={() => setDataUploadDialogVisible(true)}
                  >
                    <Icon icon="upload-cloud-02" color={"#717BBC"} />
                  </NavLink>
                  <Tooltip target=".btn-historicUploadData" position="top">
                    <span>
                      Histórico de uploads
                      {/* <LanguageProvider id="gen.edit.upload.database" /> */}
                    </span>
                  </Tooltip>
                  <NavLink
                    className="p-button p-button-text btn-historicUploadData"
                    onClick={() => setHistoricUploadDataDialogVisible(true)}
                  >
                    <Icon icon="archive" color={"#717BBC"} />
                  </NavLink>
                </div>
              </div>
              <div className="buttons">
                <Tooltip target=".btn-download-database-list" position="top">
                  <span>
                    <LanguageProvider id="gen.download" />
                  </span>
                </Tooltip>
                <Button
                  className="p-button p-button-secondary btn-download-database-list"
                  onClick={(e) => downloadOptionsOpRef.current.toggle(e)}
                  icon={<Icon icon="download-01" color={"var(--primary)"} />}
                />
                <Button
                  label={<LanguageProvider id="gen.add.register" />}
                  onClick={onAddNew}
                  icon={<Icon icon="plus" color="white" />}
                />
              </div>
            </div>
            <div className="datatable-container">
              {Object.keys(filters.filters).length > 0 && (
                <DataTable
                  lazy
                  loading={loading}
                  value={tableRows}
                  className="export-datatable"
                  rows={filters.rows}
                  first={filters.first}
                  onPage={setFilters}
                  onSort={setFilters}
                  onFilter={onFilter}
                  totalRecords={totalRecords}
                  sortField={filters.sortField}
                  sortOrder={filters.sortOrder}
                  filters={filters.filters}
                  paginator
                  rowsPerPageOptions={[10, 20, 30, 50]}
                  emptyMessage={<LanguageProvider id="gen.no.registers" />}
                >
                  {filterColumns.map((column, index) => {
                    return (
                      <Column
                        key={"dynamic-column-" + index}
                        field={column.columnKey}
                        body={(rowData) => renderColumn(rowData, column)}
                        sortable
                        filter
                        header={() => (
                          <span className="tb-header-title">
                            {column.columnName}
                          </span>
                        )}
                      />
                    );
                  })}
                  {!loading && filterColumns.length > 0 && (
                    <Column
                      headerStyle={{ width: "10%", minWidth: "100px" }}
                      body={(rowData) => {
                        return (
                          <div className="column-wrapper">
                            {hasDetails && (
                              <div
                                className="column-actions"
                                onClick={() => onViewDetails(rowData)}
                              >
                                <div className="icon-wrapper">
                                  <Icon
                                    icon="eye"
                                    size={20}
                                    color={currentTheme.textPrimary}
                                    className="icon-row"
                                  />
                                </div>
                              </div>
                            )}
                            <div className="column-actions">
                              <div
                                className="icon-wrapper"
                                onClick={() => onEdit(rowData)}
                              >
                                <Icon
                                  icon="edit-02"
                                  size={20}
                                  color={currentTheme.textPrimary}
                                  className="icon-row"
                                />
                              </div>
                            </div>
                            <div className="column-actions">
                              <div
                                className="icon-wrapper"
                                onClick={() => onDelete(rowData)}
                              >
                                <Icon
                                  icon="trash-02"
                                  size={20}
                                  color={currentTheme.textPrimary}
                                  className="icon-row"
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    />
                  )}
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Dialogs */}
      <HistoricUploadData
        visible={historicUploadDataDialogVisible}
        idDatabase={databaseId}
        onHide={() => setHistoricUploadDataDialogVisible(false)}
        onFinish={handleDeleteUploadedDatabaseItems}
      />

      <UploadDataDialog
        visible={dataUploadDialogVisible}
        onHide={() => setDataUploadDialogVisible(false)}
        databaseColumns={tableColumns.map((column) => ({
          keyName: column.columnKey,
          name: column.columnName,
          rows: tableRows.map((row) => row[column.columnKey]),
        }))}
        onFinish={saveDatabaseNewValues}
      />

      <NewEntryEditDialog
        visible={newEntryEditDialogVisible}
        onHide={() => setNewEntryEditDialogVisible(false)}
        onFinish={saveDataItem}
        database={database}
        loading={saving}
        selectedItem={selectedItem}
      ></NewEntryEditDialog>
      <ModalConfirmation
        isOpen={deleteConfirmationVisible}
        onCancel={() => setDeleteConfirmationVisible(false)}
        onConfirm={() => {
          setDeleteConfirmationVisible(false);
          deleteItem();
        }}
        isDelete={true}
        modalTitle={<LanguageProvider id={"gen.confirm.database.delete"} />}
        bodyMessage={<LanguageProvider id={"gen.message.database.deletion"} />}
      />

      {/* Overlay Panels */}
      <OverlayPanel ref={columnsOpRef} className="database-list-columns">
        <div className="popup-wrapper">
          <div className="popup-header">
            <span className="popup-title">
              <LanguageProvider id="gen.columns" />
            </span>
          </div>
          <div className="popup-column-list-wrapper">
            {tableColumns.map((column) => {
              return (
                <div
                  key={`column-filter-${column.columnKey}`}
                  className="popup-column-list"
                >
                  <Checkbox
                    className="checkbox-icon"
                    inputId={column.columnKey}
                    name="columnFilter"
                    value={column}
                    onChange={onColumnFilterChange}
                    checked={filterColumns.some(
                      (item) => item.columnKey === column.columnKey
                    )}
                  />
                  <label className="column-list-label">
                    {column.columnName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </OverlayPanel>
      <OverlayPanel
        ref={downloadOptionsOpRef}
        className="download-list-columns-database-list"
      >
        <div className="popup-wrapper">
          <div className="popup-header">
            <span className="popup-title">
              <LanguageProvider id="gen.download" />
            </span>
          </div>
          <div className="popup-column-download-wrapper">
            <div
              className="popup-excel-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportExcel();
              }}
            >
              <Icon icon={"excel-icon"} color={"#98A2B3"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.excel"} />
              </label>
            </div>
            <div
              className="popup-pdf-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportPdf();
              }}
            >
              <Icon icon={"pdf-icon"} color={"#98A2B3"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.pdf"} />
              </label>
            </div>
            <div
              className="popup-csv-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportCsv();
              }}
            >
              <Icon icon={"csv-icon"} color={"#98A2B3"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.csv"} />
              </label>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </>
  );
};

export default DatabaseList;
