import "./scss/dcp-form.scss";
import React from "react";

import * as PrimeInputText from "primereact/inputtext";

export const Form = (props) => {
  let _props = { ...props };
  _props.className += " dcp-form";
  delete _props.children;
  return <form {..._props}>{props.children}</form>;
};

export const FormRow = ({ className = "", children }) => {
  className += " form-row";
  className = className.trim();
  return <div className={className}>{children}</div>;
};

interface InputContainerProps {
  className?: string;
  children?: any;
  label?: string | React.ReactElement;
  required?: boolean;
}

export const InputContainer = ({
  className = "",
  children,
  label,
  required,
}: InputContainerProps) => {
  className += " input-container";
  className = className.trim();
  return (
    <div className={className}>
      <label className="identifier">
        {label} {required && " *"}
      </label>
      {children}
    </div>
  );
};

export const InputSwitchContainer = ({
  className = "",
  children,
  label,
  required,
}) => {
  className += " input-container-switch";
  className = className.trim();
  return (
    <div className={className}>
      <label className="identifier">
        {label} {required && " *"}
      </label>
      {children}
    </div>
  );
};

export const InputText = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  className = "",
  style,
  size = "medium",
  placeholder,
  disabled,
  readOnly,
  type,
  invalid = false,
  onKeyDown = () => {},
}) => {
  className += " dcp-input-text";
  switch (size) {
    case "small":
      className += " p-inputtext-sm";
      break;
    case "medium":
      className += " p-inputtext-md";
      break;
    case "large":
      className += " p-inputtext-lg";
      break;
    default:
      break;
  }
  className = className.trim();
  return (
    <PrimeInputText.InputText
      id={id}
      name={name}
      className={className}
      style={style}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readOnly}
      type={type}
    />
  );
};
