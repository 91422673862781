import DashboardGraphs from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";

const HomeMes = () => {
  return (
    <div className="home">
      <DashboardGraphs previewMode={true} isMain={true} />
    </div>
  );
};

export default HomeMes;
