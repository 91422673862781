import { Currency } from "./currency";
import { DateTime } from "./date-time";
import { DateFormatter } from "./date";
import { FixedText } from "./fixed-text";
import { LongText } from "./long-text";
import { ReceivingType } from "./receiving-type";
import { ShortText } from "./short-text";
import { Time } from "./time";
import { TypeConference } from "./type-conference";
import { Number } from "./number";

export interface DatabaseFieldFormatter {
  data: string;
  column: any;
}

export const FieldFormatters = {
  "short-text": ShortText,
  "long-text": LongText,
  "fixed-text": FixedText,
  date: DateFormatter,
  datetime: DateTime,
  "due-date": DateFormatter,
  email: ShortText,
  telephone: ShortText,
  time: Time,
  number: Number,
  currency: Currency,
  "tipo-recebimento": ReceivingType,
  "type-conference": TypeConference,
};
