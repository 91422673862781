import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { getConfigValue } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import "./ProductivityHorizontalBar.scss";

export default function ProductivityHorizontalBar(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({ totalValue: 0, currentValue: 0, goal: 0 });

  useEffect(() => {
    if (useMockData) {
      // Mock data
      const goalConfig = getConfigValue(configs, "ton-goal");

      const fakeData = {
        totalValue: 1000,
        currentValue: 310,
        goal: goalConfig,
      };
      setData(fakeData);
    } else {
      const goalConfig = getConfigValue(configs, "ton-goal");
      // fetch data from api
      const apiData = {
        totalValue: 0,
        currentValue: 0,
        goal: goalConfig ?? 0,
      };
      setData(apiData);
    }
  }, [useMockData, configs]);

  const currentValuePercentage = (data.currentValue / data.totalValue) * 100;
  const goalPercentage = (data.goal / data.totalValue) * 100;
  const barColor =
    data.currentValue >= data.goal ? "var(--green-500)" : "var(--red-500)";

  return (
    <div className="productivity-horizontal-bar">
      <div className="bar-wrapper">
        <div className="bar">
          <span className="background-fill"></span>
          <span
            className="value"
            style={{
              background: barColor,
              width: `${currentValuePercentage}%`,
            }}
          ></span>
          <span
            className="goal-bar"
            style={{
              width: `${goalPercentage}%`,
              display: data.goal > 0 ? "block" : "none",
            }}
          ></span>
        </div>
      </div>
      <div className="legend">
        <p>
          Meta: <span className="label-value">{`${data.goal}t`}</span>
        </p>
        <p>
          Atual: <span className="label-value">{`${data.currentValue}t`}</span>
        </p>
        <p>
          Total: <span className="label-value">{`${data.totalValue}t`}</span>
        </p>
      </div>
    </div>
  );
}
