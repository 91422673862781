import React, { useContext } from "react";
import { UserContext } from "../../../app";
import DcpAccessGranted from "~/shared/components/dcp-granted-access";

export default function Logout() {
  const { setUser } = useContext(UserContext);
  
  return <>   
    <DcpAccessGranted />
  </>;
}
