import "./scss/auth-layout.scss";

import { Splitter, SplitterPanel } from "primereact/splitter";
import { useEffect, useState } from "react";

import AuthSideMenu from "./nav/auth-side-menu";
import LoadingIndicator from "./components/dcp-loading-indicator";
import NavMenu from "./nav/nav-menu";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (!loading) {
    return (
      <div className="auth-layout">
        <div className="content">
          <NavMenu />
          <Splitter>
            <SplitterPanel
              className="auth-side-menu-splitter-panel-container"
              size={14}
              minSize={1}
            >
              <AuthSideMenu />
            </SplitterPanel>
            <SplitterPanel
              className="auth-content-splitter-panel-container"
              size={86}
            >
              <Outlet />
            </SplitterPanel>
          </Splitter>
        </div>
      </div>
    );
  }
  return <LoadingIndicator />;
};

export default AuthLayout;
