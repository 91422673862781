import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Menu } from "primereact/menu";
import { Skeleton } from "primereact/skeleton";
import { useCallback, useEffect, useRef, useState } from "react";
import { useToastContext } from "~/context/ToastContext";
import { useUsersContext } from "~/context/UsersContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import "./scss/location-pictures-view.scss";

export function LocationPicturesView({ inventoryLocation, isVisible, onHide }) {
  const users = useUsersContext();
  const menuRef = useRef(null);
  const { showToast } = useToastContext();

  // Data
  const [locationImages, setLocationImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  // Loading indicators
  const [loading, setLoading] = useState(false);

  const dcpAxiosService = useDcpAxiosService();

  const menuTemplate = (item, options, label, icon, color) => {
    return (
      <div
        onClick={(e) => options.onClick(e)}
        className={options.className}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          padding: "12px 16px",
        }}
      >
        <Icon icon={icon} color={color} size={16} />
        <p
          style={{ margin: 0, color, fontSize: "14px" }}
          className="menu-title"
        >
          <LanguageProvider id={label} />
        </p>
      </div>
    );
  };

  const menuItems = [
    {
      template: (item, options) => {
        return (
          <p
            style={{ padding: "12px 16px", margin: 0, fontWeight: 600 }}
            className="menu-title"
          >
            <LanguageProvider id="gen.menu" />
          </p>
        );
      },
    },
    { separator: true },
    {
      template: (item, options) =>
        menuTemplate(item, options, "gen.download", "download-01", "black"),
      command: downloadSelectedImage,
    },
    {
      template: (item, options) =>
        menuTemplate(
          item,
          options,
          "gen.exclude.button",
          "trash-02",
          "#F9485B"
        ),
      command: () => deleteImage(),
    },
  ];

  function downloadSelectedImage() {
    let a = document.createElement("a");
    a.href = selectedImage.url;
    a.setAttribute("download", selectedImage.inventoryImage.fileName);
    a.click();
  }

  async function deleteImage() {
    try {
      setLoading(true);
      await dcpAxiosService.delete(
        settings.Urls.Rest.Inventory + "/image",
        "Inventory",
        { params: { idInventoryImage: selectedImage.inventoryImage.id } }
      );
      showToast({
        severity: "success",
        message: LanguageProvider({ id: "image.deleted" }),
      });
      await loadImages();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const loadImages = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await dcpAxiosService.get(
        settings.Urls.Rest.Inventory + "/location-images",
        "Inventory",
        {
          params: {
            idInvetoryLocation: inventoryLocation.id,
          },
        }
      );

      if (data.data) {
        setLocationImages(data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [inventoryLocation]);

  useEffect(() => {
    if (isVisible) {
      loadImages();
    }
  }, [isVisible, loadImages]);

  return (
    <Dialog
      className="location-pictures-view-dialog"
      header={() => <LanguageProvider id="gen.galery" />}
      footer={() => {
        return (
          <div className="button">
            <Button onClick={onHide}>
              <LanguageProvider id="gen.ok" />
            </Button>
          </div>
        );
      }}
      visible={isVisible}
      onHide={onHide}
    >
      <div className="location-pictures-view">
        {loading ? (
          <div className="loading">
            <div className="skeleton row">
              <Skeleton height="190px" width="160px"></Skeleton>
              <Skeleton height="190px" width="160px"></Skeleton>
              <Skeleton height="190px" width="160px"></Skeleton>
              <Skeleton height="190px" width="160px"></Skeleton>
            </div>
            <div className="skeleton row">
              <Skeleton height="190px" width="160px"></Skeleton>
              <Skeleton height="190px" width="160px"></Skeleton>
              <Skeleton height="190px" width="160px"></Skeleton>
            </div>
          </div>
        ) : (
          <div className="images-container">
            {locationImages &&
              locationImages.map((locationUser, index) => {
                const user = users.find(
                  (u) => u.userId === locationUser.idUser
                );
                if (!user || locationUser.images.length < 1) return null;
                return (
                  <div key={"user-picture" + index} className="user-wrapper">
                    <div className="header">
                      <p className="user-name">{user.userName}</p>
                      <div className="icon">
                        <Icon
                          icon="dots-vertical"
                          size={20}
                          color="#98A2B3"
                        ></Icon>
                      </div>
                    </div>
                    <Menu
                      model={menuItems}
                      popup
                      ref={menuRef}
                      className="image-menu"
                    />
                    <div className="images row">
                      {locationUser.images &&
                        locationUser.images.map((image, index) => {
                          return (
                            <div
                              key={"image-" + index}
                              className="image-wrapper"
                            >
                              <div className="image-header">
                                <span>
                                  <Icon icon="image-01" />
                                  <p className="filename">file.png</p>
                                </span>
                                <div
                                  className="icon"
                                  onClick={(e) => {
                                    setSelectedImage(image);
                                    menuRef.current.toggle(e);
                                  }}
                                >
                                  <Icon
                                    icon="dots-vertical"
                                    size={20}
                                    color="#98A2B3"
                                  ></Icon>
                                </div>
                              </div>
                              <Image src={image.url} alt="Image" preview />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </Dialog>
  );
}
