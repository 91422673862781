import React, { useContext } from "react";

import { Navigate } from "react-router-dom";
import { UserContext } from "../../../app";
import { removeStorage } from "../../../services/storage/storage-access";

export default function Logout() {
  const { setUser } = useContext(UserContext);

  removeStorage("authentication");
  setUser({ loggedIn: false, userName: "" });

  return <Navigate to="/auth/login" replace />;
}
