import { createContext, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import showToast from "../shared/components/toast-custom";

const ToastContext = createContext(undefined);

export const ToastContextProvider = ({ children }) => {
  const handleShowToast = (options) => {
    showToast(options);
  };

  return (
    <ToastContext.Provider value={{ showToast: handleShowToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToastContext must be used within ToastContextProvider");
  }

  return context;
};
