import { Dropdown } from "primereact/dropdown";
import LanguageProvider from "~/shared/components/language-provider";

export function SequenceCounterSourceConfig({
  config,
  updateValue = function () {},
}) {
  const options = [
    {
      code: "open-orders",
      label: LanguageProvider({ id: `dropdown.option.open-orders` }),
    },
    {
      code: "open-trips",
      label: LanguageProvider({ id: "dropdown.option.open-trips" }),
    },
  ];

  return (
    <Dropdown
      value={config.value}
      options={options}
      optionLabel="label"
      optionValue="code"
      onChange={(e) => updateValue(e.value)}
    ></Dropdown>
  );
}
