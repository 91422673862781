import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { DataSourceModel, ReportDetailModel } from "~/shared/interfaces";

function axiosService(): DcpAxiosService {
  return new DcpAxiosService();
}

/**
 * Retrieves a datasource list by the applicationId
 * @param applicationId - The ID of the client application of the report product origin
 * @returns A Promise that resolves to the retrieved DataSource list.
 */
export async function getDataSources(
  applicationId: string
): Promise<DataSourceModel[]> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.Report + "/data-sources",
      "Platform",
      {
        params: {
          applicationId: applicationId,
        },
      }
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}

/**
 * Retrieves a ReportDetailsModel by the report id.
 * @param reportId - The ID of the report to be fetched
 * @returns A Promise that resolves to the retrieve the Report.
 */
export async function getReport(reportId: number): Promise<ReportDetailModel> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.Report + "/" + reportId,
      "Platform"
    );

    if (data.data) return data.data;
    else throw new Error("No data returned");
  } catch (error) {
    console.error(error);
  }
}
