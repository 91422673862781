import "./scss/inventory.scss";
import { FilterMatchMode } from "primereact/api";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUsersContext } from "~/context/UsersContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import PageHeader from "~/shared/components/page-header/page-header";
import { InventoryCreate } from "./inventory-create/inventory-create";
import { listInventories } from "~/services/api";

export function Inventory() {
  const navigate = useNavigate();
  const users = useUsersContext();

  const dcpAxiosService = useDcpAxiosService();

  // Data
  const [inventories, setInventories] = useState([]);

  // Filter
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: 1, // 1 or -1
    filters: {
      code: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      name: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      status: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      estimatedLocations: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.GREATER_THAN,
          },
        ],
      },
      accuracy: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.GREATER_THAN,
          },
        ],
      },
      duration: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
      responsibles: {
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH,
          },
        ],
      },
    },
  });

  // Dialog controll
  const [createDialogVisible, setCreateDialogVisible] = useState(false);

  // Loading indicators
  const [loading, setLoading] = useState(false);

  const status = [
    {
      id: 1,
      label: "gen.success",
      name: "complete",
      color: "#03CEB7",
      icon: "check-circle",
    },
    {
      id: 2,
      label: "gen.running",
      name: "inProgress",
      color: "#2E90FA",
      icon: "clock-rewind",
    },
    {
      id: 3,
      label: "gen.paused",
      name: "paused",
      color: "#667085",
      icon: "pause-circle",
    },
    {
      id: 4,
      label: "gen.canceled",
      name: "canceled",
      color: "#F9485B",
      icon: "x-circle",
    },
  ];

  const loadInventories = useCallback(async () => {
    try {
      setLoading(true);
      const _inventories = await listInventories(formatFilterRequest(filter));

      if (_inventories && Array.isArray(_inventories.inventories)) {
        setInventories(_inventories.inventories);
        setTotalRecords(_inventories.totalRecords);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const headerActions = () => {
    return (
      <div className="button-actions">
        <Button onClick={() => setCreateDialogVisible(true)}>
          <LanguageProvider id="inventory.create" />
        </Button>
      </div>
    );
  };

  // Column templates
  const statusColumnTemplate = (rowData) => {
    try {
      const _status = status.find((s) => s.name === rowData.status);
      if (!_status) return;

      return (
        <div className="status">
          <div className="icon">
            <Icon icon={_status.icon} color={_status.color} />
          </div>
          <p className="cell-text" style={{ color: _status.color }}>
            <LanguageProvider id={_status.label} />
          </p>
        </div>
      );
    } catch (error) {
      console.error(error);
    }
  };

  const accuracyColumnTemplate = (rowData) => {
    const color = rowData.accuracy > 60 ? "#03CEB7" : "#F9485B";

    return (
      <p className="cell-text" style={{ color }}>
        {rowData.accuracy}%
      </p>
    );
  };

  const typeColumnTemplate = (rowData) => {
    let type;
    if (rowData.drone && rowData.manual) type = "both";
    else if (rowData.drone && !rowData.manual) type = "drone";
    else type = "manual";

    return (
      <p className="">
        <LanguageProvider id={`inventory.type.${type}`} />
      </p>
    );
  };

  const responsiblesColumnTemplate = (rowData) => {
    return (
      <AvatarGroup>
        {rowData.responsibles.map((userId, index) => {
          if (index < 3) {
            const user = users.find((u) => u.userId === userId);
            if (!user) return null;

            let initials = user.name[0];
            if (user.name.split(" ").length > 1) {
              initials = user.name
                .split(" ")
                .map((n) => n[0])
                .join("");
            }

            return (
              <Avatar label={initials} shape="circle" size="large"></Avatar>
            );
          } else if (index === 4) {
            return (
              <Avatar
                label={`+${rowData.responsibles.length - 3}`}
                shape="circle"
                size="large"
              ></Avatar>
            );
          } else {
            return null;
          }
        })}
      </AvatarGroup>
    );
  };

  const columnActionsTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          className="p-button-plain p-button-text"
          onClick={() => navigate(rowData.id.toString())}
        >
          <Icon icon="edit-02" className="action-edit" />
        </Button>
        <Button className="p-button-plain p-button-text">
          <Icon icon="trash-02" className="action-delete" />
        </Button>
      </div>
    );
  };

  // Filter templates

  // Filter events
  const onPage = (e) => {
    setFilter(e);
  };

  const onSort = (e) => {
    setFilter(e);
  };

  const onFilter = (e) => {
    const _event = e;
    _event["first"] = 0;

    // hack to fix clear button
    for (const filter in _event.filters) {
      if (Object.hasOwnProperty.call(_event.filters, filter)) {
        let element = _event.filters[filter];
        if (Object.hasOwnProperty.call(element, "value")) {
          delete _event.filters[filter].value;
          delete _event.filters[filter].matchMode;
          _event.filters[filter].constraints[0].value = null;
        }
      }
    }

    setFilter(_event);
  };

  // Format the filter object to be accepted by the endpoint
  function formatFilterRequest(filter) {
    try {
      let payload = { ...filter };
      payload.sortOrder = payload.sortOrder ?? 0;

      let _filters = [];

      for (const columnName in payload.filters) {
        if (Object.hasOwnProperty.call(payload.filters, columnName)) {
          const _filter = payload.filters[columnName];

          if (_filter.constraints[0].value)
            _filters.push({
              columnName,
              value: _filter.constraints[0].value,
              matchMode: _filter.constraints[0].matchMode,
            });
        }
      }

      payload.filters = _filters;
      return payload;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadInventories();
  }, [loadInventories]);

  return (
    <div className="inventory-list">
      <PageHeader
        title={<LanguageProvider id="inventories" />}
        recordsCount={totalRecords}
        actions={headerActions}
      />
      <div className="datatable-container">
        <DataTable
          loading={loading}
          value={inventories}
          className="dcp-table"
          rows={filter.rows}
          first={filter.first}
          onPage={onPage}
          onSort={onSort}
          onFilter={onFilter}
          totalRecords={totalRecords}
          sortField={filter.sortField}
          sortOrder={filter.sortOrder}
          filters={filter.filters}
          scrollable
          scrollHeight="flex"
          paginator
          rowsPerPageOptions={[10, 20, 30, 50]}
        >
          <Column
            sortable
            filter
            field="code"
            header={<LanguageProvider id="gen.code" />}
          ></Column>
          <Column
            sortable
            filter
            field="name"
            header={<LanguageProvider id="gen.name" />}
          ></Column>
          <Column
            sortable
            field="status"
            header={<LanguageProvider id="gen.status" />}
            body={statusColumnTemplate}
          ></Column>
          <Column
            sortable
            filter
            dataType="numeric"
            field="estimatedLocations"
            header={<LanguageProvider id="locations.qtd" />}
          ></Column>
          <Column
            sortable
            header={<LanguageProvider id="inventory.accuracy" />}
            body={accuracyColumnTemplate}
          ></Column>
          <Column
            sortable
            body={typeColumnTemplate}
            header={<LanguageProvider id="gen.type" />}
          ></Column>
          <Column
            sortable
            filter
            dataType="numeric"
            field="duration"
            header={<LanguageProvider id="gen.duration" />}
            body={(rowData) => rowData.duration.slice(0, -8)}
          ></Column>
          <Column
            body={responsiblesColumnTemplate}
            header={<LanguageProvider id="gen.responsibles" />}
          ></Column>
          <Column body={columnActionsTemplate}></Column>
        </DataTable>
      </div>
      <InventoryCreate
        isOpen={createDialogVisible}
        onHide={() => setCreateDialogVisible(false)}
        onSave={loadInventories}
      />
    </div>
  );
}
