import { InputNumber } from "primereact/inputnumber";
import LanguageProvider from "~/shared/components/language-provider";

export function PercentageThresholdConfig({ config, updateValue }) {
  const values = JSON.parse(config.value);
  const styles = {
    height: "6px",
    position: "absolute",
    right: "0",
  };

  return (
    <div
      className="threshold-group"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        position: "relative",
      }}
    >
      <span className="p-inputgroup">
        <span
          className="p-inputgroup-addon"
          style={{ background: "var(--green-500)" }}
        ></span>
        <InputNumber
          value={values[0]}
          onChange={(e) =>
            updateValue(JSON.stringify([e.value, values[1], values[2]]))
          }
          placeholder={LanguageProvider({ id: "unit.percents" })}
          suffix="%"
          max={100}
          min={0}
        />
      </span>
      <span className="p-inputgroup">
        <span
          className="p-inputgroup-addon"
          style={{ background: "var(--yellow-500)" }}
        ></span>
        <InputNumber
          value={values[1]}
          onChange={(e) =>
            updateValue(JSON.stringify([values[0], e.value, values[2]]))
          }
          placeholder={LanguageProvider({ id: "unit.percents" })}
          suffix="%"
          max={values[0]}
          min={0}
        />
      </span>
      <span className="p-inputgroup">
        <span
          className="exemple-bar"
          style={{ ...styles, background: "var(--red-500)", width: "100%" }}
        ></span>
        <span
          className="exemple-bar"
          style={{
            ...styles,
            background: "var(--yellow-500)",
            width: `${100 - values[1]}%`,
          }}
        ></span>
        <span
          className="exemple-bar"
          style={{
            ...styles,
            background: "var(--green-500)",
            width: `${100 - values[0]}%`,
          }}
        ></span>
      </span>
    </div>
  );
}
