import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { defaultChartOptions } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakeData from "../../fake-data/sequence/dispatch-demand.json";
import { ThemeContext } from "~/app";

export default function DispatchDemand(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (useMockData) {
      setData(fakeData);
    } else {
      setData({ labels: [], datasets: [] });
    }
  }, [useMockData]);

  const options = {
    ...defaultChartOptions,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 90,
          minRotation: 90,
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
  };

  return (
    <Chart
      type="line"
      data={data}
      options={options}
      width="100%"
      height="100%"
    />
  );
}
