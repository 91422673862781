import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import {
  CHART_COLORS,
  defaultChartOptions,
  getConfigValue,
} from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import { ThemeContext } from "~/app";

export default function ProcessVolume(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({
    received: [],
    labels: [],
    expedited: [],
    orders: [],
  });

  useEffect(() => {
    if (useMockData) {
      const labels = ["13/01", "14/01", "15/01", "16/01"];
      const received = [300, 50, 100];
      const expedited = [200, 100, 50];
      const orders = [100, 200, 300];

      setData({ labels, received, expedited, orders });
    } else {
      // fetch data from api
      let startDate = getConfigValue(configs, "start-date");
      let endDate = getConfigValue(configs, "end-date");

      setData({ labels: [], received: [], expedited: [], orders: [] });
    }
  }, [useMockData, configs]);

  const dataset = {
    labels: data.labels,
    datasets: [
      {
        data: data.received,
        backgroundColor: CHART_COLORS[0],
        label: "Ordens recebidas",
      },
      {
        data: data.expedited,
        backgroundColor: CHART_COLORS[1],
        label: "Expediçôes criadas",
      },
      {
        data: data.orders,
        backgroundColor: CHART_COLORS[2],
        label: "Ordens expedidas",
        labelColor: "#fff",
      },
    ],
  };

  const options = {
    ...defaultChartOptions,
    plugins: {
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={dataset}
      options={options}
      width="100%"
      height="100%"
    />
  );
}
