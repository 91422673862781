import axios from "axios";
import { Chart } from "primereact/chart";
import { Skeleton } from "primereact/skeleton";
import { useCallback, useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import {
  CHART_COLORS,
  defaultChartOptions,
  getConfigValue,
} from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakeData from "../../fake-data/mes/parada-producao-stack-chart.json";
import { ThemeContext } from "~/app";

export default function LibracomParadaProducaoStackChart(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);

  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const startDate = getConfigValue(configs, "start-date");
      const endDate = getConfigValue(configs, "end-date");

      if (!startDate || !endDate) return;

      const { data, status } = await axios.get(
        "https://api.duettsoftware.com/dashboard/api/open-dashboard/get-parada-producao-por-grupo",
        {
          params: {
            dataStart: new Date(
              startDate.setUTCHours(0, 0, 0, 0)
            ).toISOString(),
            dataEnd: new Date(
              endDate.setUTCHours(23, 59, 59, 999)
            ).toISOString(),
          },
        }
      );
      if (status !== 200) return;
      return data.data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [configs]);

  useEffect(() => {
    function parseChartData(data) {
      try {
        const datasets = data.map((item, index) => {
          return {
            type: "bar",
            label: item.grupo,
            backgroundColor: CHART_COLORS[index],
            data: [item.quantidade],
          };
        });
        return { labels: [`Paradas`], datasets };
      } catch (error) {
        console.error(error);
      }
    }

    async function load() {
      const data = await loadData();
      const parsedChartData = parseChartData(data);
      setChartData(parsedChartData);
    }

    if (useMockData) {
      setChartData(fakeData);
    } else {
      load();
    }
  }, [loadData, useMockData]);

  const options = {
    ...defaultChartOptions,
    plugins: {
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
  };

  return !loading ? (
    <Chart
      type="bar"
      data={chartData}
      options={options}
      width="100%"
      height="100%"
    />
  ) : (
    <div className="chart-load">
      <Skeleton height="33%"></Skeleton>
      <Skeleton height="100%"></Skeleton>
      <Skeleton height="66%"></Skeleton>
    </div>
  );
}
