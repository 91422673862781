import LanguageProvider from "~/shared/components/language-provider";
import "../scss/loan.scss";

const Loan = () => {
  return (
    <>
      <div className="loan-main-container">
        <div className="loan-header-wrapper">
          <div className="container">
            <div className="header">
              <div className="title-wrapper">
                <span className="header-message">
                  <span className="title">
                    <LanguageProvider
                      id={"dcp.side.menu.warehouse.actions.loan"}
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loan;
