export enum MapItemType {
  Floor = 0,
  Rack = 1,
}

export interface MapItemModel {
  id: number;
  name: string;
  x: number;
  y: number;
  w: number;
  h: number;
  type: MapItemType;
  orientation: string;
  areaId: number;
  areaGroupId: number | null;
  floorAreaId: number | null;
  color: string;
}
