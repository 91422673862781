import LanguageProvider from "~/shared/components/language-provider";
import * as GridItems from "../../../shared-modules/dashboard-graphs/components/grid-item/items";
import { DashboardMenuItemCategory } from "~/pages/shared-modules/dashboard-graphs/components/item-list-dialog/menu-items";

export const AndonComponentMenuItems: DashboardMenuItemCategory[] = [
  {
    category: "layout",
    productCode: "platform",
    icon: "layout-grid-01",
    label: LanguageProvider({ id: "dashboard.category.layout" }),
    items: [
      {
        type: "andon-text",
        label: LanguageProvider({ id: "dashboard.item.type.text" }),
        icon: "align-left",
        component: GridItems.AndonText,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
    ],
  },
  {
    category: "andon",
    productCode: "sequence",
    icon: "layout-grid-01",
    label: LanguageProvider({ id: "dashboard.category.andon" }),
    items: [
      {
        type: "position",
        label: LanguageProvider({ id: "dashboard.item.type.andon.position" }),
        icon: "align-left",
        component: GridItems.Position,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
      {
        type: "divider",
        label: LanguageProvider({ id: "dashboard.item.type.andon.divider" }),
        icon: "align-left",
        component: GridItems.Divider,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
    ],
  },
];
