import Lottie from "lottie-react";
import GrantedAccessImg from "../../theme/media/assets/allowedAccess.json";
import LanguageProvider from "~/shared/components/language-provider";
import "./scss/dcp-access-granted.scss";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";


const DcpAccessGranted = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [showErrorTryAgain, setShowErrorTryAgain] = useState(false);

  const queryParams = queryString.parse(location.search);
  const { appId } = queryParams;

  
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowErrorTryAgain(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, [showErrorTryAgain]);

  const Return = async () => {
    navigate("/auth/login?appId=" + appId);
  };


  return (
    <>
      <div className="granted-access-wrapper">
        <div className="granted-access-body">
          <Lottie
            animationData={GrantedAccessImg}
            alt="Granted Access"
            className="granted-access-image"
            loop={true}
            autoPlay={true}
            style={{ fill: "#fefefe" }}
          />
        </div>
        {showErrorTryAgain && (
          <>
          <div className="error-container">            
          <label><LanguageProvider id={"login.mobile.fail"} /></label>
            <Button
              label={<LanguageProvider id={"login.error.mobile.try.again"} />}
              size="medium"
              onClick={Return}
              iconPos="left"
              className="p-button-outlined p-button-plain p-button-login-sso"
              icon="pi pi-arrow-left"
            />
          </div>
          </>
        )}
      </div>

    </>
  );
};

export default DcpAccessGranted;
