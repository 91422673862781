import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import ColorPickerButton from "../dcp-color-picker-button";

export function ShelvesColorSubgroup({ colors, onChange = () => {} }) {
  return (
    <div className="subgroup-container">
      <h3>
        <LanguageProvider id="settings.colors.levels" />
      </h3>
      <p>
        <LanguageProvider id="settings.colors.description" />
      </p>
      <Form className="settings">
        {colors.map((color, index) => {
          return (
            <FormRow key={color.name + "-" + index}>
              <InputContainer
                className="custom-input-container"
                label={<LanguageProvider id="settings.color.level" />}
              >
                <InputText
                  value={color.name}
                  focus
                  onChange={(e) => {
                    const _defaultColors = [...colors];
                    _defaultColors[_defaultColors.indexOf(color)].name =
                      e.target.value;

                    onChange(_defaultColors);
                  }}
                />
              </InputContainer>

              <InputContainer
                label={<LanguageProvider id="gen.color" />}
                className="color-picker-container"
              >
                <ColorPickerButton
                  value={color.colorHex}
                  onChange={(e) => {
                    const _defaultColors = [...colors];
                    _defaultColors[_defaultColors.indexOf(color)].colorHex = e;
                  }}
                />
              </InputContainer>
            </FormRow>
          );
        })}
        <Button
          className="new-color-btn"
          severity="secondary"
          onClick={(e) => {
            e.preventDefault();
            onChange([
              ...colors,
              {
                name: "",
                colorHex: "#2660D2",
                sequence: colors.length + 1,
              },
            ]);
          }}
        >
          <LanguageProvider id="settings.colors.add" />
        </Button>
      </Form>
    </div>
  );
}
