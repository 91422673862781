import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import "./scss/rack-model.scss";

export const RackModel = ({ groupRack, style, onUpdateName, manualConfig }) => {
  const [shelves, setShelves] = useState([]);
  const [topShelf, setTopShelf] = useState();
  const [totalShelfHeight, setTotalShelfHeight] = useState();
  const [modifiedName, setModifiedName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState({});
  const op = useRef(null);

  const treatValues = () => {
    const locations = groupRack.rackLocations;
    const maxShelfLevel = locations.slice(locations.length - 1)[0].shelfLevel;

    let _shelves = [];
    for (let i = 0; i < maxShelfLevel; i++) {
      let _locations = [];
      for (let j = 0; j < locations.length; j++) {
        if (locations[j].shelfLevel === i + 1) {
          _locations.push(locations[j]);
        }
      }
      _shelves.push({ locations: _locations, height: _locations[0].height });
    }

    if (groupRack.rack.rack.topShelf) {
      setTopShelf(_shelves.shift());
    } else {
      setTopShelf();
    }

    // Calculate total shelf height
    let height = 0;
    _shelves.forEach((shelf) => {
      height += parseInt(shelf.height);
    });
    setTotalShelfHeight(height);

    setShelves(_shelves);
  };

  const getSegmentPercentage = (segmentHeight) => {
    const height = totalShelfHeight;
    return `${((segmentHeight * 100) / height).toFixed(2)}%`;
  };

  useEffect(() => {
    treatValues();
  }, [groupRack]);

  return (
    <div className="rack-model" style={style}>
      <div className="rack">
        <div className="side-container">
          <div className="rack-side">
            <div className="side" />
            <div className="interior" />
            <div className="side" />
          </div>
        </div>
        <div className="rack-middle">
          <div className="top-shelf">
            {topShelf && topShelf.locations && (
              <div className="shelf">
                <div className="locations">
                  {topShelf.locations.map((location, index) => {
                    return (
                      <div
                        className="location"
                        key={location.name + "-location-top-" + index}
                      >
                        <span
                          className="level-color"
                          style={{ background: location.color }}
                        ></span>
                        <span
                          className="name"
                          onClick={(e) => {
                            if (manualConfig) {
                              setSelectedLocation(location);
                              op.current.toggle(e);
                            }
                          }}
                        >
                          {location.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="shelves-container">
            <div className="shelves">
              <div className="base">
                <div className="line" />
              </div>
              {shelves.length > 0 &&
                shelves.map((shelf, index) => {
                  return (
                    <div
                      className="shelf"
                      key={groupRack.rack.rack.name + "-shelf-" + index}
                      style={{
                        height: getSegmentPercentage(shelf.height),
                      }}
                    >
                      <div className="locations">
                        {shelf.locations &&
                          Array.isArray(shelf.locations) &&
                          shelf.locations.map((location, index) => {
                            return (
                              <div
                                className="location"
                                key={
                                  groupRack.rack.rack.name +
                                  "-location-" +
                                  index
                                }
                              >
                                <span
                                  className="level-color"
                                  style={{ background: location.color }}
                                ></span>
                                <span
                                  className="name"
                                  onClick={(e) => {
                                    if (manualConfig) {
                                      setSelectedLocation(location);
                                      op.current.toggle(e);
                                    }
                                  }}
                                >
                                  {location.name}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                      <div className="base">
                        <div className="line" />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div
              className="bottom-space"
              style={{
                height: `${(
                  (groupRack.rack.rack.firstShelfDistance * 100) /
                  groupRack.rack.rack.height
                ).toFixed(2)}%`,
              }}
            />
          </div>
        </div>
        <div className="side-container">
          <div className="rack-side">
            <div className="side" />
            <div className="interior" />
            <div className="side" />
          </div>
        </div>
      </div>

      <OverlayPanel
        ref={op}
        onShow={() => {
          setModifiedName(selectedLocation.name ? selectedLocation.name : "");
        }}
      >
        <div className="custom-shelf-name-op">
          <InputText
            className="name"
            value={modifiedName}
            autoFocus
            onChange={(e) => setModifiedName(e.target.value)}
          />
          <Button
            onClick={(e) => {
              onUpdateName(
                modifiedName,
                groupRack.rack.sequenceRack,
                selectedLocation
              );
              op.current.toggle(e);
            }}
          >
            Save
          </Button>
        </div>
      </OverlayPanel>
    </div>
  );
};

export const RackModelPreview = ({ rack }) => {
  return (
    <div className="rack-model preview" style={{ background: "white" }}>
      {rack.topShelf && (
        <div className="top-shelf">
          <div className="shelf">
            <div className="locations">
              {[...Array(rack.shelves[0].locationsAmount)].map((_, index) => {
                return (
                  <div
                    className="location"
                    key={rack.name + "-location-" + index}
                    style={{ border: "3px dashed #4146ff" }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="rack">
        <div className="rack-side">
          <div className="side" />
          <div className="interior" />
          <div className="side" />
        </div>
        <div className="rack-middle">
          <div className="base">
            <div className="line" />
          </div>
          <div className="shelves">
            {rack.shelves.length > 0 &&
              rack.shelves.map((shelf, index) => {
                if (rack.topShelf && rack.shelves.indexOf(shelf) === 0)
                  return null;

                return (
                  <div className="shelf" key={rack.name + "-shelf-" + index}>
                    <div className="locations">
                      {[...Array(shelf.locationsAmount)].map((_, index) => {
                        return (
                          <div
                            className="location"
                            key={rack.name + "-location-" + index}
                            style={{ border: "3px dashed #4146ff" }}
                          />
                        );
                      })}
                    </div>
                    <div className="base">
                      <div className="line" />
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="bottom-space" />
        </div>
        <div className="rack-side">
          <div className="side" />
          <div className="interior" />
          <div className="side" />
        </div>
      </div>
    </div>
  );
};
