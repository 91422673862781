import "./scss/side-menu.scss";

import {
  DcpNavMenuItemTemplate,
  DcpNewItemPopupMenuTemplate,
} from "./product-navigation/components/nav-menu-item-template";
import { useContext, useEffect, useRef, useState } from "react";

import LanguageProvider from "../components/language-provider";
import LoadingIndicator from "../components/dcp-loading-indicator";
import { PanelMenu } from "primereact/panelmenu";
import { ProductContext } from "../main-layout";
import { Toast } from "primereact/toast";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

const SideMenu = () => {
  const { product } = useContext(ProductContext);
  const [loadingMenus, setLoadingMenus] = useState(true);
  const toast = useRef(null);

  const dcpAxiosService = useDcpAxiosService();
  const [datalakes, setDatalakes] = useState([]);
  const [dashboards, setDashboards] = useState([]);

  const [menuItems, setMenuItems] = useState([
    {
      key: "product-menu",
      className: "product-menu-panel-menu",
      label: "",
      items: [],
      // template: DcpNewItemPopupMenuTemplate,
      icon: "chevron-right",
      iconPosition: "start",
    },
    {
      key: "dashboards",
      className: "dashboards-panel-menu",
      label: LanguageProvider({ id: "gen.dashboards.upperCase" }),
      items: [],
      template: DcpNavMenuItemTemplate,
      listType: "dashboards",
      icon: "chevron-right",
      isActive: true,
      iconPosition: "start",
    },
    {
      key: "datalakes",
      className: "databases-panel-menu",
      label: LanguageProvider({ id: "gen.datalakes.upperCase" }),
      items: [],
      template: DcpNavMenuItemTemplate,
      listType: "datalakes",
      icon: "chevron-right",
      iconPosition: "start",
    },
  ]);

  async function loadDashboards() {
    try {
      const dashboardResponse = await dcpAxiosService.get(
        settings.Urls.Rest.Dashboard + "/list",
        "Platform"
      );
      if (dashboardResponse.status === 200 && dashboardResponse.data.status) {
        setDashboards(dashboardResponse.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadDatalakes() {
    try {
      const datalakeResponse = await dcpAxiosService.get(
        `${settings.Urls.Rest.Datalake}/list`,
        "Platform",
        {
          params: {
            applicationId: product && product.idApplicationClient,
          },
        }
      );
      if (datalakeResponse.status === 200 && datalakeResponse.data.status) {
        setDatalakes(datalakeResponse.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (product && dashboards && datalakes) {
      const navigationMenu = setupNavigation(product, datalakes, dashboards);

      let menuItemModel = menuItems;
      if (navigationMenu) {
        if (navigationMenu.productMenu) {
          const productMenuIndex = menuItemModel.findIndex(
            (x) => x.key === "product-menu"
          );
          menuItemModel[productMenuIndex] = navigationMenu.productMenu;
        } else {
          menuItemModel = menuItemModel.filter((x) => x.key !== "product-menu");
        }

        if (navigationMenu.dashboardMenus) {
          const dashboardMenuIndex = menuItemModel.findIndex(
            (x) => x.key === "dashboards"
          );
          menuItemModel[dashboardMenuIndex].items =
            navigationMenu.dashboardMenus;
          menuItemModel[dashboardMenuIndex].expanded = false;
        }

        if (navigationMenu.datalakeMenus) {
          const databasesMenuIndex = menuItemModel.findIndex(
            (x) => x.key === "datalakes"
          );
          menuItemModel[databasesMenuIndex].items =
            navigationMenu.datalakeMenus;
        }
      }

      setLoadingMenus(false);
      setMenuItems(menuItemModel);
    }
  }, [product, datalakes, dashboards, menuItems]);

  useEffect(() => {
    loadDatalakes();
    loadDashboards();
  }, [product]);

  return (
    <div className="side-menu">
      <div className="side-menu-container">
        <div className="navigation">
          <Toast ref={toast}></Toast>
          {loadingMenus ? (
            <PanelMenuLoadingState />
          ) : (
            <PanelMenu className="dcp-panel-menu" model={menuItems} multiple />
          )}
        </div>
      </div>
    </div>
  );
};

const PanelMenuLoadingState = () => {
  //TODO need to change this to render a skeleton of the menu
  return <LoadingIndicator />;
};
function setupNavigation(product, datalakes, dashboards) {
  try {
    let datalakeMenus = [];
    if (datalakes && Array.isArray(datalakes)) {
      datalakes.forEach((datalake, datalakeIndex) => {
        let url =
          datalake.type === settings.DatalakeType.Database
            ? `database/list/${datalake.idDataSource}`
            : `reports/${datalake.idDataSource}`;
        datalakeMenus.push({
          key: `datalakes-${datalakeIndex}`,
          label: datalake.name,
          url: url,
          template: DcpNavMenuItemTemplate,
          type: datalake.type,
        });
      });
    }
    let dashboardMenus = [];
    if (dashboards && Array.isArray(dashboards)) {
      dashboards.forEach((dashboard, dashboardIndex) => {
        dashboardMenus.push({
          key: `dashboards-${dashboardIndex}`,
          label: dashboard.name,
          url: `dashboard-graphs/${dashboard.id}`,
          template: DcpNavMenuItemTemplate,
          type: "dashboard",
        });
      });
    }
    let productMenu;
    if (product) {
      switch (product.code) {
        case "drone-inventory":
          productMenu = {
            key: "drone-inventory",
            className: "drone-inventory-panel-menu",
            label: LanguageProvider({ id: "gen.inventory.upperCase" }),
            expanded: true,
            template: DcpNewItemPopupMenuTemplate,
            items: [
              {
                key: "drone-inventory-dashboard",
                url: "home/drone-inventory",
                label: LanguageProvider({ id: "dcp.side.menu.dashboard" }),
                icon: "monitor-02",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "drone-inventory-inventory",
                url: "inventory",
                label: LanguageProvider({ id: "dcp.side.menu.inventory" }),
                icon: "file-02",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "area-map-history",
                url: "area-map/history",
                label: LanguageProvider({
                  id: "dcp.side.menu.inventory.area.history",
                }),
                icon: "calendar-check-02",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "inventory-registrations",
                label: LanguageProvider({ id: "dcp.side.menu.registrations" }),
                icon: "file-06",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-inventory-racks",
                    url: "layout",
                    icon: "rack-01",
                    label: LanguageProvider({ id: "dcp.side.menu.racks" }),
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-inventory-floor-area",
                    url: "floor-area",
                    icon: "layout-grid-01",
                    label: LanguageProvider({ id: "dcp.side.menu.floor.area" }),
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-inventory-storage",
                    url: "area-map",
                    label: LanguageProvider({ id: "dcp.side.menu.storage" }),
                    icon: "warehouse-02",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-inventory-skus",
                    label: LanguageProvider({
                      id: "dcp.side.menu.skus",
                    }),
                    url: "skuInventory",
                    icon: "file-06",
                    iconPosition: "end",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "drone-inventory-devices",
                devices: "devices",
                label: LanguageProvider({ id: "dcp.side.menu.devices" }),
                icon: "rocket-02",
                url: "devices",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "drone-inventory-reports",
                label: LanguageProvider({ id: "dcp.side.menu.reports" }),
                icon: "line-chart-up-01",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-inventory-reports-manage",
                    label: LanguageProvider({ id: "reports.manage" }),
                    url: "reports",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "drone-inventory-settings",
                url: "settings-inventory",
                label: LanguageProvider({ id: "dcp.side.menu.settings" }),
                icon: "settings-04",
                template: DcpNavMenuItemTemplate,
              },
            ],
          };
          break;
        case "wms":
          productMenu = {
            key: "wms",
            className: "wms-panel-menu",
            label: LanguageProvider({ id: "product.wms" }),
            expanded: true,
            template: DcpNewItemPopupMenuTemplate,
            items: [
              {
                key: "wms-dashboard",
                url: "home/wms",
                label: LanguageProvider({ id: "dcp.side.menu.dashboard" }),
                icon: "monitor-02",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "wms-registrations",
                label: LanguageProvider({ id: "dcp.side.menu.registrations" }),
                icon: "file-06",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-wms-registrations-manage-docks",
                    label: LanguageProvider({ id: "product.wms.menu.docks" }),
                    url: "docks",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-wms-registrations-manage-racks",
                    label: LanguageProvider({ id: "product.wms.menu.racks" }),
                    url: "layout",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-wms-registrations-manage-areas",
                    label: LanguageProvider({ id: "product.wms.menu.areas" }),
                    url: "area-map",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-wms-registrations-manage-containers",
                    label: LanguageProvider({
                      id: "product.wms.menu.containers",
                    }),
                    url: "containers",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-wms-registrations-manage-families",
                    label: LanguageProvider({
                      id: "product.wms.menu.families",
                    }),
                    url: "families",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "wms-receipt",
                url: "receiving",
                label: LanguageProvider({ id: "dcp.side.menu.wms.receipt" }),
                icon: "recebimento",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "wms-dispatch",
                url: "dispatch",
                label: LanguageProvider({ id: "dcp.side.menu.wms.dispatch" }),
                icon: "truck-01",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "wms-occupation-map",
                url: "wms-occupation-map",
                label: LanguageProvider({ id: "product.area.map.occupation" }),
                icon: "map-01",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "wms-reports",
                label: LanguageProvider({ id: "dcp.side.menu.reports" }),
                icon: "line-chart-up-01",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "wms-reports-manage",
                    label: LanguageProvider({ id: "reports.manage" }),
                    url: "reports",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "wms-settings",
                url: "settings-wms",
                label: LanguageProvider({ id: "dcp.side.menu.settings" }),
                icon: "settings-04",
                template: DcpNavMenuItemTemplate,
              },
            ],
          };

          break;
        case "mes":
          productMenu = {
            key: "mes",
            className: "mes-panel-menu",
            label: LanguageProvider({ id: "gen.inventory.upperCase" }),
            expanded: true,
            template: DcpNewItemPopupMenuTemplate,
            items: [
              {
                key: "mes-dashboard",
                url: "home/mes",
                label: LanguageProvider({ id: "dcp.side.menu.dashboard" }),
                icon: "monitor-02",
                template: DcpNavMenuItemTemplate,
              },
            ],
          };
          break;
        case "sequence":
          productMenu = {
            key: "sequence",
            className: "sequence-panel-menu",
            label: LanguageProvider({ id: "product.sequence.upperCase" }),
            expanded: true,
            template: DcpNewItemPopupMenuTemplate,
            items: [
              {
                key: "sequence-dashboard",
                url: "home/sequence",
                label: LanguageProvider({ id: "dcp.side.menu.dashboard" }),
                icon: "monitor-02",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "sequence-registrations",
                label: LanguageProvider({ id: "dcp.side.menu.registrations" }),
                icon: "file-06",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-sequence-part-number",
                    label: LanguageProvider({
                      id: "product.sequence.menu.part.number",
                    }),
                    url: "part-number",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-sequence-serial-block",
                    label: LanguageProvider({
                      id: "product.sequence.menu.serial.block",
                    }),
                    url: "serial-block",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-sequence-printers",
                    label: LanguageProvider({
                      id: "product.sequence.menu.printers",
                    }),
                    url: "printers",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "sequence-trips",
                url: "trips",
                label: LanguageProvider({ id: "dcp.side.menu.sequence.trips" }),
                icon: "recebimento",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "sequence-andon",
                label: LanguageProvider({ id: "dcp.side.menu.sequence.andon" }),
                icon: "truck-01",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-sequence-andon-trip",
                    label: LanguageProvider({
                      id: "dcp.side.menu.sequence.andon.trip",
                    }),
                    url: "andon/trip",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-sequence-andon-component",
                    label: LanguageProvider({
                      id: "dcp.side.menu.sequence.andon.component",
                    }),
                    url: "andon/component",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "sequence-reports",
                label: LanguageProvider({ id: "dcp.side.menu.reports" }),
                icon: "line-chart-up-01",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-sequence-registrations-manage",
                    label: LanguageProvider({ id: "dcp.side.menu.manage" }),
                    url: "reports",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "sequence-settings",
                url: "settings-sequence",
                label: LanguageProvider({ id: "dcp.side.menu.settings" }),
                icon: "settings-04",
                template: DcpNavMenuItemTemplate,
              },
            ],
          };

          break;
        case "warehouse":
          productMenu = {
            key: "warehouse",
            className: "warehouse-panel-menu",
            label: LanguageProvider({ id: "product.warehouse.upperCase" }),
            expanded: true,
            template: DcpNewItemPopupMenuTemplate,
            items: [
              {
                key: "dashboard",
                url: "home/warehouse",
                label: LanguageProvider({ id: "dcp.side.menu.dashboard" }),
                icon: "monitor-02",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "warehouse-registrations",
                label: LanguageProvider({ id: "dcp.side.menu.registrations" }),
                icon: "file-06",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-warehouse-registrations-manage-sku",
                    label: LanguageProvider({
                      id: "product.warehouse.menu.skus",
                    }),
                    url: "sku-warehouse",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-warehouse-registrations-manage-family",
                    label: LanguageProvider({
                      id: "product.warehouse.menu.family",
                    }),
                    url: "family",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-sequence-registrations-manage-requesters",
                    label: LanguageProvider({
                      id: "product.warehouse.menu.requesters",
                    }),
                    url: "applicants",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-sequence-registrations-manage-addresses",
                    label: LanguageProvider({
                      id: "product.warehouse.menu.addresses",
                    }),
                    url: "addresses",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-sequence-registrations-manage-providers",
                    label: LanguageProvider({
                      id: "product.warehouse.menu.providers",
                    }),
                    url: "providers",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "warehouse-purchase-requests",
                url: "purchase-requests",
                label: LanguageProvider({
                  id: "dcp.side.menu.purchase.requests",
                }),
                icon: "shopping-cart-01",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "warehouse-movements",
                label: LanguageProvider({
                  id: "dcp.side.menu.movements.sub.menu",
                }),
                icon: "dataflow-03",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "warehouse-movements-entrance",
                    label: LanguageProvider({
                      id: "dcp.side.menu.movements.entrance",
                    }),
                    url: "entry",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "warehouse-movements-exit",
                    label: LanguageProvider({
                      id: "dcp.side.menu.movements.exit",
                    }),
                    url: "output",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "warehouse-movements-transfer-branches",
                    label: LanguageProvider({
                      id: "dcp.side.menu.movements.transfer.branches",
                    }),
                    url: "transfer-branches",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "warehouse-movements-return",
                    label: LanguageProvider({
                      id: "dcp.side.menu.movements.return",
                    }),
                    url: "return",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "warehouse-movements-loan",
                    label: LanguageProvider({
                      id: "dcp.side.menu.movements.loan",
                    }),
                    url: "loan",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "warehouse-movements-movement",
                    label: LanguageProvider({
                      id: "dcp.side.menu.movements.movement",
                    }),
                    url: "movement",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "warehouse-movements-discard",
                    label: LanguageProvider({
                      id: "dcp.side.menu.movements.discard",
                    }),
                    url: "discard",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "warehouse-reports",
                label: LanguageProvider({ id: "dcp.side.menu.reports" }),
                icon: "line-chart-up-01",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-warehouse-registrations-manage",
                    label: LanguageProvider({ id: "dcp.side.menu.manage" }),
                    url: "reports",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "warehouse-settings",
                url: "settings-warehouse",
                label: LanguageProvider({ id: "dcp.side.menu.settings" }),
                icon: "settings-04",
                template: DcpNavMenuItemTemplate,
              },
            ],
          };

          break;
        case "area-movement-control":
          productMenu = {
            key: "movement-control",
            className: "movement-control-panel-menu",
            label: LanguageProvider({
              id: "product.control.movement.upperCase",
            }),
            expanded: true,
            template: DcpNewItemPopupMenuTemplate,
            items: [
              {
                key: "movement-control-dashboard",
                url: "home/area-movement-control",
                label: LanguageProvider({ id: "dcp.side.menu.dashboard" }),
                icon: "monitor-02",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "movement-control-registrations",
                label: LanguageProvider({ id: "dcp.side.menu.registrations" }),
                icon: "file-06",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-movement-control-skus",
                    label: LanguageProvider({
                      id: "product.warehouse.menu.skus",
                    }),
                    url: "skus",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-movement-control-racks",
                    label: LanguageProvider({
                      id: "dcp.side.menu.racks",
                    }),
                    url: "layout",
                    template: DcpNavMenuItemTemplate,
                  },
                  {
                    key: "drone-movement-control-areas",
                    label: LanguageProvider({
                      id: "dcp.side.menu.areas",
                    }),
                    url: "area-map",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "movement-control-settings",
                url: "requests",
                label: LanguageProvider({ id: "product.area.requests" }),
                icon: "settings-04",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "movement-control-occupation-map",
                url: "amc-occupation-map",
                label: LanguageProvider({ id: "product.area.map.occupation" }),
                icon: "map-01",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "movement-control-reports",
                label: LanguageProvider({ id: "dcp.side.menu.reports" }),
                icon: "line-chart-up-01",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
                items: [
                  {
                    key: "drone-movement-control-registrations-manage",
                    label: LanguageProvider({ id: "dcp.side.menu.manage" }),
                    url: "reports",
                    icon: "",
                    template: DcpNavMenuItemTemplate,
                  },
                ],
              },
              {
                key: "movement-control-settings",
                url: "settings-area-control-movement",
                label: LanguageProvider({ id: "dcp.side.menu.settings" }),
                icon: "settings-04",
                template: DcpNavMenuItemTemplate,
              },
            ],
          };

          break;
        case "product-wd":
          productMenu = {
            key: "productWd",
            className: "product-wd-panel-menu",
            label: LanguageProvider({ id: "product.wd.upperCase" }),
            expanded: true,
            template: DcpNewItemPopupMenuTemplate,
            items: [
              {
                key: "product-wd-accounting-balances",
                label: LanguageProvider({
                  id: "dcp.side.menu.product.wd.actions.accounting.balance",
                }),
                url: "accounting-balances",
                icon: "",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "product-wd-open-titles",
                label: LanguageProvider({
                  id: "dcp.side.menu.product.wd.actions.open.titles",
                }),
                url: "open-titles",
                icon: "",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "product-wd-fixed-assets",
                label: LanguageProvider({
                  id: "dcp.side.menu.product.wd.actions.fixed.assets",
                }),
                url: "fixed-assets",
                icon: "",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "product-wd-xml-files",
                label: LanguageProvider({
                  id: "dcp.side.menu.product.wd.actions.xml.files",
                }),
                url: "xml-files",
                icon: "",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "product-dw-settings",
                url: "settings-product-dw",
                label: LanguageProvider({ id: "dcp.side.menu.settings" }),
                icon: "settings-04",
                template: DcpNavMenuItemTemplate,
              },
            ],
          };

          break;
        case "print":
          productMenu = {
            key: "print",
            className: "print-panel-menu",
            label: LanguageProvider({ id: "product.print.upperCase" }),
            expanded: true,
            template: DcpNewItemPopupMenuTemplate,
            items: [
              {
                key: "print-dashboard",
                url: "home/print",
                label: LanguageProvider({ id: "dcp.side.menu.dashboard" }),
                icon: "monitor-02",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "drone-print-printers",
                label: LanguageProvider({
                  id: "product.print.menu.printers",
                }),
                url: "printersFromPrinterProduct",
                icon: "printer",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "drone-printed-data",
                label: LanguageProvider({
                  id: "product.print.menu.printed.data",
                }),
                url: "printerData",
                icon: "file-06",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
              },
              {
                key: "drone-template",
                label: LanguageProvider({
                  id: "product.print.menu.template",
                }),
                url: "template",
                icon: "file-06",
                iconPosition: "end",
                template: DcpNavMenuItemTemplate,
              },
            ],
          };

          break;
        default:
          break;
      }
    }
    return { dashboardMenus, datalakeMenus, productMenu };
  } catch (error) {
    console.error(error);
  }
  return;
}

export default SideMenu;
