import { useEffect, useState } from "react";
import LanguageProvider from "./language-provider";
import "./scss/rack-layout-model.scss";

export const RackLayoutModel = ({ rack, style }) => {
  const [topShelf, setTopShelf] = useState();
  const [localRack, setLocalRack] = useState();
  const [totalShelfHeight, setTotalShelfHeight] = useState();

  const getSegmentPercentage = (segmentHeight) => {
    const height = totalShelfHeight;
    const result = ((segmentHeight * 100) / height).toFixed(2);

    if (result != NaN) return `${result}%`;
    return null;
  };

  useEffect(() => {
    if (!Array.isArray(rack.shelves)) return;

    let modifiedRack = { ...rack };
    modifiedRack.shelves = modifiedRack.shelves.toReversed();
    setLocalRack(modifiedRack);

    if (modifiedRack.topShelf) {
      setTopShelf(modifiedRack.shelves[0]);
      let deleteShelve = { ...modifiedRack };
      let filterShelves = deleteShelve.shelves.filter(
        (shelf, index) => index > 0
      );
      setLocalRack({ ...deleteShelve, shelves: filterShelves });
    } else {
      setTopShelf(null);
    }
  }, [rack]);

  useEffect(() => {
    if (localRack) {
      // Calculate total shelf height
      let height = 0;
      localRack.shelves.forEach((shelf) => {
        height += parseInt(shelf.height);
      });
      setTotalShelfHeight(height);
    }
  }, [localRack]);

  return (
    <div className="rack-model" style={style}>
      <div className="rack">
        <div className="side-container">
          <div className="rack-side">
            <div className="side" />
            <div className="interior" />
            <div className="side" />
          </div>
        </div>
        <div className="rack-middle">
          <div className="top-shelf">
            {topShelf && (
              <div className="shelf">
                <div className="locations">
                  {[...Array(topShelf.locationsAmount)].map((_, index) => {
                    return (
                      <div
                        className="location"
                        key={localRack.name + "-location-" + index}
                      >
                        <span className="name">
                          <LanguageProvider id={"gen.height"} />:{" "}
                          {topShelf.height}
                          <LanguageProvider id={"gen.cm"} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="shelves-container">
            <div className="shelves">
              <div className="base">
                <div className="line" />
              </div>
              {localRack &&
                localRack.shelves.map((shelf, index) => {
                  let height = getSegmentPercentage(shelf.height);

                  if (height === "NaN%" || height === null) {
                    height = `${100 / localRack.shelves.length}%`;
                  }
                  return (
                    <div
                      className="shelf"
                      key={localRack.name + "-shelf-" + index}
                      style={{
                        height,
                      }}
                    >
                      <div className="locations">
                        {[...Array(parseInt(shelf.locationsAmount))].map(
                          (_, index) => {
                            return (
                              <div
                                className="location"
                                key={localRack.name + "-location-" + index}
                              >
                                <span className="name">
                                  <LanguageProvider id={"gen.height"} />:{" "}
                                  {shelf.height}
                                  <LanguageProvider id={"gen.cm"} />
                                </span>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className="base">
                        <div className="line" />
                      </div>
                    </div>
                  );
                })}
            </div>
            {localRack && (
              <div
                className="bottom-space"
                style={{
                  height: `${(
                    (localRack.firstShelfDistance * 100) /
                    localRack.height
                  ).toFixed(2)}%`,
                }}
              />
            )}
          </div>
        </div>
        <div className="side-container">
          <div className="rack-side">
            <div className="side" />
            <div className="interior" />
            <div className="side" />
          </div>
        </div>
      </div>
    </div>
  );
};
