import { InputNumber } from "primereact/inputnumber";
import LanguageProvider from "~/shared/components/language-provider";

export function TonThresholdConfig({ config, updateValue = function () {} }) {
  const values = JSON.parse(config.value);
  return (
    <div
      className="threshold-group"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        position: "relative",
      }}
    >
      <span className="p-inputgroup">
        <span
          className="p-inputgroup-addon"
          style={{ background: "var(--green-500)" }}
        ></span>
        <InputNumber
          value={values[0]}
          minFractionDigits={1}
          onChange={(e) =>
            updateValue(JSON.stringify([e.value, values[1], values[2]]))
          }
          placeholder={LanguageProvider({ id: "unit.tons" })}
          suffix="t"
        />
      </span>
      <span className="p-inputgroup">
        <span
          className="p-inputgroup-addon"
          style={{ background: "var(--yellow-500)" }}
        ></span>
        <InputNumber
          value={values[1]}
          max={values[0]}
          minFractionDigits={1}
          onChange={(e) =>
            updateValue(JSON.stringify([values[0], e.value, values[2]]))
          }
          placeholder={LanguageProvider({ id: "unit.tons" })}
          suffix="t"
        />
      </span>
    </div>
  );
}
