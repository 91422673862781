import enUS from "../../language/en-us.json";
import esES from "../../language/es-es.json";
import ptBR from "../../language/pt-br.json";

/** Provides the corresponding label based on the provided ID and user provided language
 *  @param id
 *  @returns string
 */
export const currentLanguage = "pt-br";

const LanguageProvider = ({ id }) => {
  if (!id) return "Language id required!";
  let label;
  switch (currentLanguage) {
    case "pt-br":
      label = ptBR[id];
      break;
    case "es-es":
      label = esES[id];
      break;
    case "en-us":
    default:
      label = enUS[id];
      break;
  }
  return label ? label : id;
};

export default LanguageProvider;
