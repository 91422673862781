import React, { useCallback, useEffect, useState } from "react";
import LanguageProvider from "~/shared/components/language-provider";
import "../scss/movement.scss";
import PageHeader from "~/shared/components/page-header/page-header";
import { Button } from "~/shared/components/dcp-button";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import qs from "qs";
import { useFormik } from "formik";
import { MoveItemModel } from "~/shared/interfaces/warehouse";
import {
  loadWarehouseConnections,
  moveItem,
} from "~/services/api/warehouse/movement";

export function Movement() {
  const axiosService = useDcpAxiosService();
  const [skuConnectionValues, setSkuConnectionValues] = useState<any>([]);
  const [addresses, setAddresses] = useState<any>([]);
  const [databaseItem, setDatabaseItem] = useState<any>();
  const [itemLocations, setItemLocation] = useState<any>([]);

  // Element Visibility
  const [loadingSkus, setLoadingSkus] = useState<boolean>(false);
  const [loadingtItemLocation, setLoadingItemLocation] =
    useState<boolean>(false);
  const [loadtingAddresses, setLoadingAddressese] = useState<boolean>(false);

  const form = useFormik<MoveItemModel>({
    initialValues: {
      skuId: 0,
      fromId: 0,
      toId: 0,
      qtd: 0,
    },
    onSubmit: submit,
  });

  async function submit(values) {
    try {
      const data = await moveItem(values);

      if (data) {
        form.setValues({
          skuId: form.values.skuId,
          fromId: 0,
          toId: 0,
          qtd: 0,
        });
        loadItemLocations();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function loadSkuConnectionValues() {
    try {
      setLoadingSkus(true);
      const data = await loadWarehouseConnections(["sku"]);

      if (!Array.isArray(data) || data.length < 1)
        throw new Error("Missing data");

      const options = Object.entries(data[0].values).map(([key, value]) => {
        return {
          key: parseInt(key),
          value,
        };
      });

      setSkuConnectionValues(options);
      setLoadingSkus(false);
    } catch (error) {
      console.error(error);
      setLoadingSkus(false);
    }
  }

  const loadSkuTableColums = useCallback(async () => {
    try {
      const { data } = await axiosService.get(
        `${settings.Urls.Rest.DatabaseItem}/single-database-list-item`,
        "Platform",
        {
          params: {
            itemId: form.values.skuId,
          },
        }
      );
      if (data.data) {
        setDatabaseItem(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [axiosService, form.values.skuId]);

  async function loadAddresses() {
    try {
      setLoadingAddressese(true);
      const { data, status } = await axiosService.get(
        settings.Urls.Rest.Connection + "/list-data",
        "Platform",
        {
          params: {
            connectionCode: ["address"],
          },
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        }
      );

      if (!Array.isArray(data.data) || data.data.length < 1)
        throw new Error("Missing data");

      const options = Object.entries(data.data[0].values).map(
        ([key, value]) => {
          return {
            key: parseInt(key),
            value,
          };
        }
      );

      setAddresses(options);
    } catch (error) {
      console.error(error);
    }
    setLoadingAddressese(false);
  }

  async function loadItemLocations() {
    try {
      setLoadingItemLocation(true);
      const { data } = await axiosService.get(
        `${settings.Urls.Rest.Movements}/list-locations`,
        "Warehouse",
        {
          params: {
            itemId: form.values.skuId,
          },
        }
      );

      if (data.data && Array.isArray(data.data)) {
        setItemLocation(data.data);
      }
      setLoadingItemLocation(false);
    } catch (error) {
      console.log(error);
      setLoadingItemLocation(false);
    }
  }

  useEffect(() => {
    loadSkuConnectionValues();
    loadAddresses();
  }, []);

  useEffect(() => {
    loadSkuTableColums();
    loadItemLocations();
  }, [loadSkuTableColums]);

  useEffect(() => {
    console.log(form.values);
  }, [form]);

  return (
    <div className="movement">
      <div className="movement-container">
        <PageHeader
          recordsCount={undefined}
          onReturn={undefined}
          actions={undefined}
          titleTemplate={undefined}
          title={<LanguageProvider id="movement" />}
        ></PageHeader>
        <div className="content">
          <div className="source-selection">
            <span className="title">
              <LanguageProvider id="gen.item.search" />
            </span>
            <Form>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.item" />}>
                  <Dropdown
                    options={skuConnectionValues}
                    optionValue="key"
                    optionLabel="value"
                    value={form.values.skuId}
                    filter
                    loading={loadingSkus}
                    onChange={(e) => form.setFieldValue("skuId", e.value)}
                  ></Dropdown>
                </InputContainer>
              </FormRow>
              {databaseItem &&
                form.values.skuId > 0 &&
                databaseItem.headers.map((item) => {
                  const value = databaseItem.item.columns[item.columnKey];
                  return (
                    <FormRow>
                      <InputContainer label={item.columnName}>
                        <InputText
                          disabled
                          id={undefined}
                          name={undefined}
                          value={value}
                          onChange={undefined}
                          onBlur={undefined}
                          style={undefined}
                          placeholder={undefined}
                          readOnly={undefined}
                          type={undefined}
                        ></InputText>
                      </InputContainer>
                    </FormRow>
                  );
                })}
            </Form>
          </div>
          <div className="operation">
            <span className="title">
              <LanguageProvider id="movement" />
            </span>
            <Form>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.from" />}>
                  <Dropdown
                    options={getItemLocationAddresses(itemLocations, addresses)}
                    optionValue="key"
                    optionLabel="value"
                    filter
                    loading={loadingtItemLocation}
                    value={form.values.fromId}
                    onChange={(e) => form.setFieldValue("fromId", e.value)}
                  ></Dropdown>
                </InputContainer>
                <InputContainer label={<LanguageProvider id="gen.to" />}>
                  <Dropdown
                    options={destinationAddressFilter(
                      addresses,
                      form.values.fromId
                    )}
                    optionValue="key"
                    optionLabel="value"
                    value={form.values.toId}
                    filter
                    loading={loadtingAddresses}
                    onChange={(e) => form.setFieldValue("toId", e.value)}
                  ></Dropdown>
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="gen.total.ammount" />}
                >
                  <InputNumber
                    disabled
                    value={getLocationAmmount(
                      itemLocations,
                      form.values.fromId
                    )}
                  ></InputNumber>
                </InputContainer>
                <InputContainer
                  label={<LanguageProvider id="movement.ammount" />}
                >
                  <InputNumber
                    value={form.values.qtd}
                    onChange={(e) => form.setFieldValue("qtd", e.value)}
                    max={getLocationAmmount(itemLocations, form.values.fromId)}
                  ></InputNumber>
                </InputContainer>
              </FormRow>
            </Form>
          </div>
        </div>
        <div className="footer">
          <Button className="p-button-text" onClick={() => form.resetForm()}>
            <LanguageProvider id="dcp.platform.warehouse.clean.data" />
          </Button>
          <Button
            disabled={submitDisabled(form.values)}
            onClick={() => form.submitForm()}
          >
            Mover item
          </Button>
        </div>
      </div>
    </div>
  );
}

function submitDisabled(values) {
  const objValues = Object.values(values);
  return objValues.some((e) => e === 0) ?? false;
}

function destinationAddressFilter(addresses, fromId) {
  try {
    return addresses.filter((ad) => ad.key !== fromId);
  } catch (error) {}
}

function getItemLocationAddresses(locations, addresses) {
  try {
    return addresses.filter((address) =>
      locations.map((loc) => loc.idLocation).includes(address.key)
    );
  } catch (error) {
    console.error(error);
  }
}

function getLocationAmmount(itemLocations: any, from: number): number {
  try {
    if (!itemLocations || !from) return 0;
    const location = itemLocations.find(
      (location) => location.idLocation === from
    );
    if (location) {
      return location.qty;
    } else {
      return 0;
    }
  } catch (error) {
    console.error(error);
  }
}
