import React, { useEffect, useMemo, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import GridLayout, { Layout } from "react-grid-layout";
import "../scss/warehouse-map.scss";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import Icon from "~/shared/components/icons";
import { ReactComponent as FlipIcon } from "~/theme/custom-icons/flip.svg";
import {
  LocationLastUpdate,
  MapItemModel,
  MapItemType,
  RackLocationsInfoModel,
} from "~/shared/interfaces";

export interface MapRef {
  update: () => void;
}

export interface Item extends MapItemModel {
  i: string;
  group?: any;
  //exceptions?: exception[];
}

export interface WarehouseMapProps {
  isStatic: boolean;
  items: Item[];
  exceptions?: any[];
  markedLocations?: any[];
  rackGroupLocationHistory?: RackLocationsInfoModel[];
  onLocationHistoryClick?: (rackLocation: RackLocationsInfoModel) => void;
  onUpdate?: (items: Layout[]) => void;
  onFlip?: (i: string) => void;
  onDelete?: (i: string) => void;
  onEdit?: (item: Item) => void;
  onExceptionClick?: (e: any) => void;
}

export function WarehouseMap(props: WarehouseMapProps) {
  const [selectedItem, setSelectedItem] = useState<Item>(null);
  const [selectedRackException, setSelectedRackException] = useState<any>();

  // const [updateLayout, setUpdateLayout] = useState<boolean>(false);

  const layoutItems = useMemo(() => {
    if (!Array.isArray(props.items)) return [];
    return props.items.map((item) => {
      const flexDirection = item.orientation === "vertical" ? "column" : "row";

      if (item.id < 0) return null;
      return (
        <div
          key={item.i}
          // data-grid={item}
          className="grid-item"
          style={{ background: getItemColor(item) }}
          onClick={(e) => {
            if (!props.isStatic) {
              op.current.toggle(e);
              setSelectedItem(item);
            }
          }}
        >
          {props.isStatic ? (
            <div className="rack-exception" style={{ flexDirection }}>
              {item.group &&
                item.group.groupRacks &&
                item.group.groupRacks.map((rack, index) => {
                  const rackLocations = rack.rackLocations.map(
                    (location) => location.id
                  );

                  const exceptionLocations = props.exceptions.map(
                    (exception: any) => exception.location.id
                  );

                  const hasException = rackLocations.some((location) =>
                    exceptionLocations.includes(location)
                  );

                  const outlineRack = rackLocations.some(
                    (location) => props.markedLocations?.includes(location)
                  );

                  let hasHistory =
                    props.rackGroupLocationHistory &&
                    props.rackGroupLocationHistory.length > 0;

                  let rackStyle = {};
                  if (hasException || outlineRack) {
                    rackStyle = {
                      background: "#ffc9ce",
                      border: "2px solid #f9485b",
                    };
                  } else if (hasHistory) {
                    var historyRack = props.rackGroupLocationHistory.find(
                      (x) => x.areaGroupRackId === rack.rack.id
                    );
                    if (historyRack)
                      rackStyle = {
                        background: historyRack.colorHex,
                        border: "2px solid #98a2b3",
                      };
                  }

                  return (
                    <div
                      key={index}
                      className="rack"
                      style={rackStyle}
                      onClick={(e) => {
                        if (hasException) {
                          const locations = rack.rackLocations.map((loc) => {
                            const exception = props.exceptions.find(
                              (exception) => exception.location.id === loc.id
                            );

                            return {
                              location: loc,
                              locationStatus: exception
                                ? exception.locationStatus
                                : 0,
                              exception: exception,
                            };
                          });

                          const rackException = {
                            isle: rack.rackLocations[0].name.split("-")[0],
                            locations: locations.sort((a, b) => {
                              return b.locationStatus - a.locationStatus;
                            }),
                          };

                          setSelectedRackException(rackException);
                          exceptionOpRef.current.toggle(e);
                        }
                        if (hasHistory && props.onLocationHistoryClick) {
                          var _historyRack =
                            props.rackGroupLocationHistory.find(
                              (x) => x.areaGroupRackId === rack.rack.id
                            );
                          if (_historyRack)
                            props.onLocationHistoryClick(_historyRack);
                        }
                      }}
                    ></div>
                  );
                })}
              <p style={getTextStyle(item.orientation)}>
                {item.name ? item.name : "no name"}
              </p>
            </div>
          ) : (
            <p style={getTextStyle(item.orientation)}>{item.name}</p>
          )}
        </div>
      );
    });
  }, [props.exceptions, props.isStatic, props.items, props.markedLocations]);

  const itemRef = useRef(null);
  const op = useRef(null);
  const exceptionOpRef = useRef(null);

  function onLayoutChange(layout: Layout[]) {
    const newItems = [];
    for (const item of props.items) {
      const newItem = layout.find((x) => x.i === item.i);
      if (!newItem) continue;

      newItems.push({
        ...item,
        ...newItem,
      });
    }

    if (props.onUpdate) props.onUpdate(layout);
  }

  function onDragStart() {
    if (itemRef.current) itemRef.current.resetTransform(0);
    if (op.current) op.current.hide();
  }

  return (
    <div className="warehouse-map">
      <TransformWrapper
        ref={itemRef}
        wheel={undefined}
        panning={{
          excluded: ["grid-item"],
        }}
        doubleClick={{ excluded: ["grid-item"] }}
      >
        <TransformComponent>
          <GridLayout
            layout={props.items}
            onDragStart={onDragStart}
            className="layout"
            cols={100}
            rowHeight={20}
            width={2000}
            margin={[0, 0]}
            compactType={null}
            autoSize={false}
            onLayoutChange={onLayoutChange}
            isResizable={false}
            isDraggable={!props.isStatic}
            preventCollision
          >
            {layoutItems}
          </GridLayout>
        </TransformComponent>
      </TransformWrapper>

      {/* Overlay Panel */}
      <OverlayPanel className="options-menu" appendTo="self" ref={op}>
        <div className="detail"></div>
        <div className="icons">
          <Button
            className="icon-btn p-button-plain"
            text
            disabled={selectedItem && selectedItem.type === MapItemType.Floor}
            onClick={() => {
              if (props.onEdit) props.onEdit(selectedItem);
            }}
          >
            <Icon icon="edit-02" size="20px" color="#6468ff" />
          </Button>
          <Button
            className="icon-btn p-button-plain"
            text
            onClick={() => {
              op.current.hide();
              if (props.onFlip) props.onFlip(selectedItem.i);
            }}
          >
            <FlipIcon />
          </Button>
          <Button
            className="icon-btn p-button-plain"
            text
            onClick={() => {
              op.current.hide();
              if (props.onDelete) props.onDelete(selectedItem.i);
            }}
          >
            <Icon icon="trash-02" size="20px" color="#6468ff" />
          </Button>
        </div>
      </OverlayPanel>

      {/* Rack locations list */}
      <OverlayPanel
        ref={exceptionOpRef}
        dismissable={false}
        className="rack-location-list"
      >
        <div className="popup-wrapper">
          <div className="popup-header">
            <div className="header-icons">
              <div className="popup-icon">
                <Icon icon={"marker-pin-06"} color={"#4146FF"} size={24}></Icon>
              </div>
              <div className="popup-close-icon">
                <Icon
                  icon={"x-close"}
                  color={"#8189A3"}
                  size={22}
                  onClick={() => exceptionOpRef.current.hide()}
                  className={"popup-icon-header"}
                ></Icon>
              </div>
            </div>
            <span className="popup-title">
              Corredor {selectedRackException ? selectedRackException.isle : ""}
            </span>
          </div>
          <div className="popup-column-list-wrapper">
            <div className="popup-list-content-wrapper">
              <p>Locations</p>
              {selectedRackException &&
                Array.isArray(selectedRackException.locations) &&
                selectedRackException.locations.map((location, rackIndex) => {
                  return (
                    <div
                      key={"location-rack-" + rackIndex}
                      className="location"
                    >
                      <div
                        className={`status code-${location.locationStatus}`}
                      ></div>
                      <p className="location-name">{location.location.name}</p>
                      <div className="buttons">
                        <Button className={"p-button-secondary"}>
                          <Icon icon="image-01" color="#4146FF" size={20} />
                        </Button>
                        <Button
                          className={"p-button-secondary"}
                          disabled={!location.exception}
                          onClick={() =>
                            props.onExceptionClick(location.exception)
                          }
                        >
                          <Icon icon="arrow-right" color="#4146FF" size={20} />
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
}

function getTextStyle(orientation): Object {
  let textStyle = {};
  if (orientation === "vertical") {
    textStyle = {
      writingMode: "tb-rl",
      transform: "rotate(-180deg)",
    };
  }

  return textStyle;
}

function getItemColor(item: Item): string {
  if (!item || !item.color || item.type !== MapItemType.Floor) {
    return "#d5d9eb";
  } else {
    return item.color.startsWith("#") ? item.color : `#${item.color}`;
  }
}
