import "./scss/summary.scss";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { ReactComponent as ClockStop } from "~/theme/custom-icons/clock-stop.svg";
import { ReactComponent as ClockStart } from "~/theme/custom-icons/clock-start.svg";
import { ReactComponent as Drone } from "~/theme/custom-icons/drone-inventory.svg";
import { ReactComponent as Manual } from "~/theme/custom-icons/manual-inventory.svg";
import { RadioButton } from "primereact/radiobutton";
import { Avatar } from "primereact/avatar";
import { useEffect, useState } from "react";
import { useUsersContext } from "~/context/UsersContext";

export function Summary({ inventory, loading }) {
  const users = useUsersContext();

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (!dateStr) return "-";

    const result = `${date.getDate().toString().padStart(2, 0)}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, 0)}/${date.getFullYear()} ${date
      .getHours()
      .toString()
      .padStart(2, 0)}:${date.getMinutes().toString().padStart(2, 0)}`;

    return result ?? "-";
  };

  return (
    <div className="inventory-details-header">
      <div className="header-container">
        <h1>
          <LanguageProvider id="inventory.details" />
        </h1>
        <div className="icon">
          <Icon icon={"dots-vertical"} />
        </div>
      </div>
      <div className="content-container">
        <div id="first-section">
          <div className="info-wrapper">
            <div className="field">
              <p className="label">
                <LanguageProvider id="gen.code" />
              </p>
              <p className="text">{inventory.code}</p>
            </div>
            <div className="field">
              <p className="label">
                <LanguageProvider id="gen.name" />
              </p>
              <p className="text">{inventory.name}</p>
            </div>
          </div>
          <div className="area-wrapper">
            <div className="label">
              <LanguageProvider id="areas" />
            </div>
            <div className="areas">
              {inventory.areas &&
                inventory.areas.map((area, index) => {
                  return (
                    <p key={"area-" + index} className="area">
                      {area.name}
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="time-wrapper">
            <div className="row">
              <div className="time">
                <p className="label">
                  <LanguageProvider id="inventory.programmed.to.date" />
                </p>
                <div className="content">
                  <Icon icon="calendar" color="#667085" />
                  <p className="text">{formatDate(inventory.programmedTo)}</p>
                </div>
              </div>
              <div className="time">
                <p className="label">
                  <LanguageProvider id="gen.duration" />
                </p>
                <div className="content">
                  <Icon icon="clock" color="#667085" />
                  <p className="text">{inventory.duration}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="time">
                <p className="label">
                  <LanguageProvider id="gen.begin" />
                </p>
                <div className="content">
                  <ClockStart icon="clock" color="#667085" />
                  <p className="text">{formatDate(inventory.startedAt)}</p>
                </div>
              </div>
              <div className="time">
                <p className="label">
                  <LanguageProvider id="gen.end" />
                </p>
                <div className="content">
                  <ClockStop icon="clock" color="#667085" />
                  <p className="text">{formatDate(inventory.finishedAt)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="location-wrapper">
            <p className="label">
              <LanguageProvider id="inventory.predicted.locations" />
            </p>
            <div className="bar-wrapper">
              <div className="bar">
                <div
                  className="section success"
                  style={{
                    width: `${
                      (340 / inventory.estimatedLocations) *
                      inventory.locationsFinished
                    }px`,
                  }}
                >
                  <span className="text">{inventory.locationsFinished}</span>
                </div>
                <div
                  className="section error"
                  style={{
                    width: `${
                      (340 / inventory.estimatedLocations) *
                      inventory.locationsWithDivergency
                    }px`,
                  }}
                >
                  <span className="text">
                    {inventory.locationsWithDivergency > 0
                      ? inventory.locationsWithDivergency
                      : ""}
                  </span>
                </div>
                <div className="section pending"></div>
              </div>
              <p className="text">{`${
                inventory.locationsWithDivergency + inventory.locationsFinished
              }/${inventory.estimatedLocations}`}</p>
            </div>
          </div>
        </div>
        <div id="second-section">
          <div className="type-container">
            <p className="label">
              <LanguageProvider id="inventory.type" />
            </p>
            <div className="types">
              {inventory.drone && (
                <div className="type">
                  <p className="text">
                    <LanguageProvider id="inventory.type.drone" />
                  </p>
                  <Drone />
                </div>
              )}
              {inventory.manual && (
                <div className="type">
                  <p className="text">
                    <LanguageProvider id="inventory.type.manual" />
                  </p>
                  <Manual />
                </div>
              )}
            </div>
          </div>
          <div className="drones-container">
            <p className="label">
              <LanguageProvider id="devices.drones" />
            </p>
            <div className="drones">
              {inventory.drones &&
                inventory.drones.map((drone, index) => {
                  return (
                    <p key={"drone-" + index} className="drone">
                      {drone.name}
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="read-type-container">
            <div className="label">
              <LanguageProvider id="inventory.read.type" />
            </div>
            <div className="read-type">
              <RadioButton checked={true} />
              <span className="label">
                {inventory.readType === 1 ? "SKU" : "Serial"}
              </span>
            </div>
          </div>
        </div>
        <div id="third-section">
          <div className="label">
            <LanguageProvider id="gen.responsibles" />
          </div>
          <div className="resposibles-wrapper">
            {users.length > 0 &&
              inventory.responsibles &&
              inventory.responsibles.map((responsible, index) => {
                const user = users.find((user) => user.userId === responsible);
                if (!user) return null;

                return (
                  <div key={"responsible" + index} className="responsible">
                    <Avatar
                      label={user.name[0].toUpperCase()}
                      size="normal"
                      shape="circle"
                    />
                    <div className="info">
                      <p className="text">{user.name}</p>
                      <p className="label">{user.email}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div id="fourth-section">
          <p className="label">
            <LanguageProvider id="gen.status" />
          </p>
          <div className="dropdown">
            <Icon icon="check-circle" color="#03CEB7" size={20} />
            <span>
              <LanguageProvider id="inventory.done" />
            </span>
            <Icon icon="chevron-down" color="#475467" size={20} />
          </div>
          <Gauge percentage={inventory.accuracy} />
        </div>
      </div>
    </div>
  );
}

// Accuracy Gauge Component
function Gauge({ percentage }) {
  const [path, setPath] = useState("");

  function percentageInRadians(percentage) {
    return percentage * (Math.PI / 100);
  }

  useEffect(() => {
    function setGaugeValue() {
      const gaugeRadius = 107;
      const startingY = 120;
      const startingX = 12;

      const zeroBasedY =
        gaugeRadius * Math.sin(percentageInRadians(percentage));
      const y = -zeroBasedY + startingY;
      const zeroBasedX =
        gaugeRadius * Math.cos(percentageInRadians(percentage));
      const x = -zeroBasedX + gaugeRadius + startingX;

      setPath(`M ${startingX} ${startingY}
             A ${gaugeRadius} ${gaugeRadius} 0 0 1 ${x} ${y}
             `);
    }
    setGaugeValue();
  }, [percentage]);

  return (
    <div className="svg-container">
      <div className="content">
        <p className="label">
          <LanguageProvider id="inventory.accuracy" />
        </p>
        <p className="value">{percentage}%</p>
      </div>
      <svg width="240" height="137" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 12 120
          A 107 107 0 0 1 226 120
          "
          stroke="#F5F5FD"
          stroke-width="24"
          stroke-linecap="round"
        />
        <path
          d={path}
          stroke="#03CEB7"
          stroke-width="24"
          stroke-linecap="round"
          color="blue"
        />

        <g id="svg-graph-meter-value"></g>
      </svg>
    </div>
  );
}
