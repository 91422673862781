import "./AndonErrors.scss";
import LanguageProvider from "~/shared/components/language-provider";
import { useContext, useEffect } from "react";

export default function AndonErrors() {
  const errors = [
    {
      id: 1,
      message: "LOREM IPSUM DOLOR SIT AMET",
      time: "01/01/24 12:00",
      severity: "error",
    },
    {
      id: 2,
      message:
        "LOREM IPSUM DOLOR SIT AMET AM ILLUM DOLORUM RERUM DOLOR SIT AMET",
      time: "01/01/24 8:30",
      severity: "warning",
    },
    {
      id: 3,
      message: "LOREM IPSUM DOLOR SIT AMET",
      time: "01/01/24 8:12",
      severity: "warning",
    },
  ];

  return (
    <div className="andon-errors">
      <div className="header">
        <span className="title">
          <LanguageProvider id="dashboard.andon.errors" />
        </span>
      </div>
      <div className="error-list">
        {errors.map((error, index) => {
          return (
            <span key={index} className={`error-line ${error.severity}`}>
              {error.time + " - " + error.message}
            </span>
          );
        })}
      </div>
    </div>
  );
}
