import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { Button } from "./dcp-button";
import "./scss/dcp-color-picker-button.scss";

function ColorPickerButton({ color: initialColor = "#ffffff", onChange }) {
  const [color, setColor] = useState(initialColor);
  const [showPicker, setShowPicker] = useState(false);

  const handleChange = (selectedColor) => {
    const hexColor = selectedColor.hex;
    setColor(hexColor);
    onChange(hexColor);
  };

  return (
    <div className="dcp-color-picker">
      <div className="color-picker-button">
        <Button
          className="color-button"
          type="button"
          style={{ backgroundColor: color }}
          onClick={() => setShowPicker(!showPicker)}
        />
        {showPicker && (
          <div className="color-picker-container">
            <SketchPicker color={color} onChange={handleChange} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ColorPickerButton;
