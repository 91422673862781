import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LanguageProvider from "./language-provider";
import { Skeleton } from "primereact/skeleton";

export default function SkeletonDatabase({ columnNames = [] }) {
  const columns =
    columnNames.length > 0
      ? columnNames
      : [
          LanguageProvider({ id: "reports.column" }),
          LanguageProvider({ id: "reports.column" }),
          LanguageProvider({ id: "reports.column" }),
        ];
  return (
    <DataTable value={[{}, {}, {}]}>
      {columns.map((column, index) => (
        <Column
          field="id"
          key={"skeleton-column-" + index}
          header={column}
          style={{ width: "25%" }}
          body={(e) => <Skeleton />}
        ></Column>
      ))}
    </DataTable>
  );
}
