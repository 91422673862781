import React from "react";
import { DatabaseFieldFormatter } from ".";

const sufixes = {
  kilos: " kg",
  grams: " g",
  temperature: " ℃",
};

export function Number({ data, column }: DatabaseFieldFormatter) {
  const sufix = sufixes[column.inputMaskName];
  const isCurrency = column.inputMaskName === "currency";

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <span>
      {isCurrency ? USDollar.format(parseFloat(data)) : `${data}${sufix ?? ""}`}
    </span>
  );
}
