export function generateUniqueKey(arr: Object[], keyProp: string): string {
  let key = "";
  let maxRuns = 50;
  let runs = 0;

  if (arr.length < 1) return "1";
  if (!Object.getOwnPropertyNames(arr[0]).includes(keyProp))
    throw new Error("Invalid keyProp");

  do {
    key = (Math.floor(Math.random() * 9999) + 1).toString();
    if (runs > maxRuns) throw new Error("Unexpected Error");
    runs++;
  } while (arr.find((x) => x[keyProp] === key));

  return key;
}
