import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AreaCounts } from "./area-counts";
import "./scss/inventory-details.scss";
import { Summary } from "./summary";
import { getInventory } from "~/services/api";

export function InventoryDetails() {
  const { idInventory } = useParams();

  // Loading Indicators
  const [loading, setLoading] = useState(false);

  // Data
  const [inventory, setInventory] = useState({ areas: [] });

  useEffect(() => {
    async function loadInventory() {
      try {
        setLoading(true);
        const data = await getInventory(idInventory);

        if (data) {
          setInventory(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    loadInventory();
  }, [idInventory]);

  return (
    <div className="inventory-details">
      <Summary inventory={inventory} loading={loading} />
      <AreaCounts inventory={inventory} loading={loading} />
    </div>
  );
}
