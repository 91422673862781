import "./AndonText.scss";
import { useEffect, useState } from "react";

export default function AndonText(configs) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    try {
      const parsedContent = JSON.parse(configs.content);
      if (
        parsedContent &&
        Object.keys(parsedContent).includes("text") &&
        Object.keys(parsedContent).includes("fontSize")
      ) {
        setContent(parsedContent);
      } else {
        setContent(null);
      }
    } catch (error) {
      console.error(error);
    }
  }, [configs.content]);

  return (
    <div className="andon-text">
      {content && (
        <p style={{ fontSize: content.fontSize + "px" }}>
          {content.text ?? ""}
        </p>
      )}
    </div>
  );
}
