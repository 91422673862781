import "../scss/area-side-menu.scss";
import { useState } from "react";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { InputText } from "~/shared/components/dcp-form";
import { Skeleton } from "primereact/skeleton";
import { Button } from "~/shared/components/dcp-button";

export function AreaSideMenu({
  height,
  groups,
  onCreateGroup,
  areaItems,
  loading,
  onAddGroupToArea,
  floorAreas,
  onAddFloorArea,
}) {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <nav
      className={`area-side-menu ${sideMenuOpen ? "open" : null}`}
      style={{ height: `${height()}px` }}
    >
      <div className="menu-container-placeholder">
        <div className="category">
          <Icon
            icon="layout-alt-02"
            color={"#717BBC"}
            onClick={() => setSideMenuOpen(true)}
          />
        </div>
      </div>
      <div className="menu-container">
        <div className="left-column">
          <div className="header">
            <p className="title">
              {<LanguageProvider id="area.map.rack.groups" />}
            </p>
            <Icon
              className="icon"
              icon="x-close"
              color={"#717BBC"}
              onClick={() => setSideMenuOpen(false)}
            />
          </div>
          <p className="description">
            {<LanguageProvider id="area.map.rack.groups.description" />}
          </p>
          <span className="p-input-icon-left search-input">
            <div className="icon-search">
              <Icon icon={"search-md"} color="#98A2B3" size={20} />
            </div>
            <InputText
              showClear={true}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              placeholder={LanguageProvider({
                id: "search.field.placeholder",
              })}
            ></InputText>
          </span>
          <Button className={"p-button-outlined"} onClick={onCreateGroup}>
            <Icon icon={"plus"} size={20} color="#4146ff" />
            <LanguageProvider id="area.map.new.group" />
          </Button>
          <div className="groups-list">
            {loading ? (
              <div className="skeleton-container">
                <Skeleton height="42px"></Skeleton>
                <Skeleton height="42px"></Skeleton>
                <Skeleton height="42px"></Skeleton>
              </div>
            ) : (
              <ul>
                {groups &&
                  groups.length > 0 &&
                  groups.map((group, index) => {
                    if (
                      searchQuery.length > 0 &&
                      !group.areaGroup.name
                        .toUpperCase()
                        .includes(searchQuery.toUpperCase())
                    )
                      return null;

                    return (
                      <li
                        key={`group-${group.areaGroup.name}-${index}`}
                        onClick={() => {
                          onAddGroupToArea(group);
                        }}
                      >
                        <span>{group.areaGroup.name}</span>
                      </li>
                    );
                  })}
                {floorAreas &&
                  floorAreas.length > 0 &&
                  floorAreas.map((floorArea, index) => {
                    return (
                      <li
                        key={`group-${floorArea.name}-${index}`}
                        onClick={() => {
                          onAddFloorArea(floorArea);
                        }}
                      >
                        <span>{floorArea.name}</span>
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
