import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import {
  FloorArea,
  FloorAreaCreateModel,
  FloorAreaModel,
} from "~/shared/interfaces";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function createFloorArea(
  model: FloorAreaCreateModel
): Promise<FloorAreaCreateModel> {
  try {
    if (model.floorArea.id === 0) {
      delete model.floorArea.clientId;
    }

    const { data } = await axiosService().post(
      settings.Urls.Rest.FloorArea,
      model,
      "Platform"
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function listFloorAreas(): Promise<FloorAreaModel[]> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.FloorArea,
      "Platform"
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function getFloorArea(
  id: number | string
): Promise<FloorAreaCreateModel> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.FloorArea + "/" + id,
      "Platform"
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}

export async function deleteFloorArea(id: number | string): Promise<any> {
  try {
    const { data } = await axiosService().delete(
      settings.Urls.Rest.FloorArea + "/" + id,
      "Platform"
    );

    if (data.data) return data.data;
    else return null;
  } catch (error) {
    console.error(error);
  }
}
