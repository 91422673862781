import "./scss/area-map-history.scss";
import React, { useEffect, useState } from "react";
import { Map } from "../inventory/inventory-details/map";
import {
  getLocationsLastUpdate,
  getRackLocationsInfoModel,
  listAreaGroups,
  listAreaMaps,
} from "~/services/api";
import {
  LocationLastUpdate,
  AreaListModel,
  RackLocationsInfoModel,
} from "~/shared/interfaces";
import Icon from "~/shared/components/icons";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "~/shared/components/language-provider";
import { Item, WarehouseMap } from "../area-map/components/warehouse-map";
import { listMapItems } from "~/services/api/inventory/area";
import { generateUniqueKey } from "~/shared/helpers/generator";
import { Dialog } from "primereact/dialog";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { getContrastFontColorFromHex } from "~/shared/helpers/utils";
import { AreaHistoryLocationDialog } from "../area-map/components/area-history-location-dialog";

export function AreaMapHistory() {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [areas, setAreas] = useState<AreaListModel[]>([]);
  const [areaGroups, setAreaGroups] = useState([]);
  const [areaItems, setAreaItems] = useState([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [dialogItems, setDialogItems] = useState<LocationLastUpdate[]>([]);
  const [locationsLastUpdate, setLocationsLastUpdate] = useState<
    RackLocationsInfoModel[]
  >([]);
  const [selectedAreaIndex, setSelectedAreaIndex] = useState<number>();

  async function loadItems(id?: number, groups?: any[]) {
    try {
      const data = await listMapItems(id ?? areas[selectedAreaIndex].id);
      if (!data) return;

      const items: Item[] = [];
      setAreaItems(items);

      for (const item of data) {
        items.push({
          ...item,
          i: "",
          group: {},
        });
      }

      let _groups = groups && groups.length > 0 ? groups : areaGroups;

      for (const item of items) {
        item.i = generateUniqueKey(items, "i");
        const group = _groups.find((g) => g.areaGroup.id === item.areaGroupId);
        if (group) item.group = group;
      }

      console.log(items);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function load() {
      let areas = await listAreaMaps();
      if (areas) {
        setAreas(areas);
        setSelectedAreaIndex(0);
      }
    }
    load();
  }, []);

  useEffect(() => {
    async function load() {
      setLoading(true);
      let areaId = areas[selectedAreaIndex].id;
      let groups = await listAreaGroups(areaId);
      setAreaGroups(groups);
      setLocationsLastUpdate(await getRackLocationsInfoModel(areaId));

      await loadItems(areaId, groups);
      setLoading(false);
    }
    load();
  }, [areas, selectedAreaIndex]);

  async function onLocationClick(rackLocationsInfo: RackLocationsInfoModel) {
    setLoadingLocations(true);
    setModalVisible(true);
    var locations = await getLocationsLastUpdate(
      rackLocationsInfo.areaGroupRackId
    );

    setDialogItems(locations);
    setLoadingLocations(false);
  }

  return (
    <div className="area-map-history">
      <div className="header">
        <div className="area-selection">
          <div className="selector">
            <div
              className="icon"
              onClick={() => {
                if (selectedAreaIndex != null && selectedAreaIndex > 0) {
                  setSelectedAreaIndex(selectedAreaIndex - 1);
                }
              }}
            >
              <Icon
                icon="chevron-left"
                size={24}
                color={
                  selectedAreaIndex != null && selectedAreaIndex > 0
                    ? "#344054"
                    : "#D0D5DD"
                }
              />
            </div>
            <span className="name">
              {selectedAreaIndex != null && areas && areas.length > 0
                ? areas[selectedAreaIndex].name
                : null}
            </span>
            <div
              className="icon"
              onClick={() => {
                if (
                  selectedAreaIndex != null &&
                  selectedAreaIndex < areas.length - 1
                ) {
                  setSelectedAreaIndex(selectedAreaIndex + 1);
                }
              }}
            >
              <Icon
                icon="chevron-right"
                size={24}
                color={
                  selectedAreaIndex != null &&
                  selectedAreaIndex < areas.length - 1
                    ? "#344054"
                    : "#D0D5DD"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="map-container">
        {loading ? (
          <LoadingIndicator />
        ) : (
          <WarehouseMap
            isStatic={true}
            items={areaItems}
            exceptions={[]}
            rackGroupLocationHistory={locationsLastUpdate}
            onLocationHistoryClick={onLocationClick}
            onExceptionClick={() => {}}
            onUpdate={() => {}}
            onFlip={() => {}}
            onDelete={() => {}}
            onEdit={() => {}}
          />
        )}
        <AreaHistoryLocationDialog
          isOpen={modalVisible}
          onClose={() => setModalVisible(false)}
          locations={dialogItems}
        />
        {/* <Dialog
          visible={modalVisible}
          onHide={() => setModalVisible(false)}
          appendTo="self"
          className="location-history-dialog"
          header={<LanguageProvider id="location.history.header" />}
        >
          <div className="history-dialog-content">
            {!loadingLocations ? (
              dialogItems.map((item) => {
                return (
                  <div className="history-location">
                    <span
                      style={{
                        color: getContrastFontColorFromHex(item.colorHex),
                        background: item.colorHex,
                      }}
                      className="name"
                    >
                      {item.locationName}
                    </span>
                    <span>
                      {item.lastUpdate
                        ? item.lastUpdate.toLocaleDateString()
                        : LanguageProvider({
                            id: "location.history.no.record",
                          })}
                    </span>
                  </div>
                );
              })
            ) : (
              <LoadingIndicator></LoadingIndicator>
            )}
          </div>
        </Dialog> */}
      </div>
    </div>
  );
}
