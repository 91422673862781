import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function deleteRack(rackId: number): Promise<boolean> {
  try {
    const { data } = await axiosService().delete(
      `${settings.Urls.Rest.Layout}/delete?id=${rackId}`,
      "Platform"
    );

    if (data && data.data) return true;
    else return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}
