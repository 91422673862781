import { useContext } from "react";
import IcoMoon from "react-icomoon";
import { ThemeContext } from "../../app";
import IconSet from "../../theme/fonts/selection.json";

const Icon = ({ icon, size, color, className = "", onClick = () => {} }) => {
  const { currentTheme } = useContext(ThemeContext);
  className += " dcp-icon";
  className = className.trim();
  return (
    <IcoMoon
      className={className}
      icon={icon}
      iconSet={IconSet}
      size={size ?? currentTheme.iconDefaultSize}
      style={{ color: color ?? currentTheme.iconDefaultColor }}
      onClick={onClick}
    />
  );
};

export default Icon;
