import { AndonBoard } from "./components/andon-board";
import { AndonSettingsContextProvider } from "./context/AndonDashboardSettingsContext";

export default function AndonTrip() {
  return (
    <AndonSettingsContextProvider>
      <AndonBoard dashboardType={2} />
    </AndonSettingsContextProvider>
  );
}
