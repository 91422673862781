import "./occupation-map.scss";
import LanguageProvider from "../../../shared/components/language-provider.jsx";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../../shared/components/dcp-button";
import Icon from "../../../shared/components/icons.jsx";
import DcpAxiosService, {
  useDcpAxiosService,
} from "~/services/axios/dcp-axios-service";
import { classNames } from "primereact/utils";
import settings from "~/services/settings.json";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { WarehouseMap } from "~/pages/inventory/area-map/components/warehouse-map";
import { generateUniqueKey } from "~/shared/helpers/generator";
import { listMapItems } from "~/services/api/inventory/area";

export interface OccupationMapProps {}

export function OccupationMap(props: OccupationMapProps) {
  // Custom hooks
  const axiosService = useDcpAxiosService();

  // Data
  const [areas, setAreas] = useState([]);
  const [areaItems, setAreaItems] = useState([]);
  const [selectedAreaIndex, setSelectedAreaIndex] = useState(0);
  const [selectedSkus, setSelectedSkus] = useState([]);

  // Elements visibility
  const [isFullscreen, toggleFullscreen] = useToggle(false);
  const [loading, setLoading] = useState(false);

  const fakeSkuslocations = [
    {
      sku: "P123456",
      locationId: 34,
    },
    {
      sku: "P789012",
      locationId: 40,
    },
    {
      sku: "P345678",
      locationId: 48,
    },
    {
      sku: "P901234",
      locationId: 49,
    },
  ];

  const loadAreas = useCallback(async () => {
    try {
      const response = await fetchAreas(axiosService);
      if (response.length > 0) {
        setSelectedAreaIndex(0);
      }
      setAreas(response);
    } catch (error) {
      console.error(error);
    }
  }, [axiosService]);

  const loadAreaItems = useCallback(async () => {
    try {
      setLoading(true);
      const areaId = areas[selectedAreaIndex].id;
      const items = await listMapItems(areaId);
      const groups = await fetchAreaGroups(axiosService, areaId);

      for (const item of items) {
        item["i"] = "";
      }

      const parsedItems = items.map((item) => {
        const group = groups.find((group) => group.id === item.areaGroupId);
        return {
          ...item,
          i: generateUniqueKey(items, "i"),
          group: group,
        };
      });

      setAreaItems(parsedItems);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, [axiosService, areas, selectedAreaIndex]);

  useEffect(() => {
    async function load() {
      await loadAreas();
    }
    load();
  }, [loadAreas]);

  useEffect(() => {
    loadAreaItems();
  }, [selectedAreaIndex, loadAreaItems]);

  return (
    <div
      className={classNames("occupation-map-component", {
        fullscreen: isFullscreen,
      })}
    >
      <header className="header">
        <div className="title-wrapper">
          <span className="title">
            <LanguageProvider id="product.area.map.occupation" />
          </span>
          <Button
            className="p-button-plain p-button-text"
            onClick={() => toggleFullscreen()}
          >
            <Icon
              icon={isFullscreen ? "minimize-01" : "maximize-01"}
              size="18"
              color="var(--gray-400)"
            ></Icon>
          </Button>
        </div>
        <div className="actions">
          <div className="area-selection">
            <Button
              className="p-button-text p-button-plain chevron-button"
              disabled={selectedAreaIndex === 0}
              onClick={() => setSelectedAreaIndex(selectedAreaIndex - 1)}
            >
              <Icon
                icon="chevron-left"
                size="18"
                color={
                  selectedAreaIndex === 0
                    ? "var(--gray-400)"
                    : "var(--gray-600)"
                }
              />
            </Button>
            <span className="area-name">
              {(areas.length > 0 && areas[selectedAreaIndex].name) ||
                "locations not found"}
            </span>
            <Button
              className="p-button-text p-button-plain chevron-button"
              disabled={selectedAreaIndex >= areas.length - 1}
              onClick={() => setSelectedAreaIndex(selectedAreaIndex + 1)}
            >
              <Icon
                icon="chevron-right"
                size="18"
                color={
                  selectedAreaIndex >= areas.length - 1
                    ? "var(--gray-400)"
                    : "var(--gray-600)"
                }
              />
            </Button>
          </div>
          <div className="filters">
            <MultiSelect
              style={{ minWidth: "200px" }}
              filter
              maxSelectedLabels={3}
              display="chip"
              options={fakeSkuslocations}
              value={selectedSkus}
              optionLabel="sku"
              optionValue="locationId"
              onChange={(e) => setSelectedSkus(e.value)}
            />
            <span className="search-input p-input-icon-left">
              <div className="icon-search">
                <Icon icon={"search-md"} color="#98A2B3" size={20} />
              </div>
              <InputText
                className="p-inputtext-md"
                placeholder={LanguageProvider({
                  id: "search.field.placeholder",
                })}
              />
            </span>
          </div>
        </div>
      </header>
      <div className="layout">
        <WarehouseMap
          items={areaItems}
          isStatic={true}
          markedLocations={selectedSkus}
        />
      </div>
    </div>
  );
}

function useToggle(initialValue: boolean): [boolean, () => void] {
  const [value, setValue] = useState(initialValue);
  const toggle = () => setValue(!value);
  return [value, toggle];
}

async function fetchAreas(axiosService: DcpAxiosService): Promise<Array<any>> {
  try {
    const { data } = await axiosService.get(
      `${settings.Urls.Rest.AreaMap}/list-areas`,
      "Platform"
    );

    if (Array.isArray(data.data)) return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}

async function fetchAreaItems(
  axiosService: DcpAxiosService,
  areaId: string | number
): Promise<Array<any>> {
  try {
    const { data } = await axiosService.get(
      settings.Urls.Rest.AreaMap + "/list-map-items",
      "Platform",
      { params: { areaId } }
    );

    if (Array.isArray(data.data)) return data.data;
  } catch (error) {
    console.error(error);
  }
}

async function fetchAreaGroups(
  axiosService: DcpAxiosService,
  areaId: number | string
): Promise<Array<any>> {
  try {
    const { data } = await axiosService.get(
      `${settings.Urls.Rest.AreaGroup}/list`,
      "Platform",
      { params: { areaId } }
    );

    if (Array.isArray(data.data)) return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}
