import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { CHART_COLORS } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import "./OpenInventoryAccuracy.scss";

export default function OpenInventoryAccuracy(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (useMockData) {
      setData([
        { id: 1, name: "Inventário exemplo 1", value: 52 },
        { id: 2, name: "Inventário exemplo 2", value: 89 },
        { id: 3, name: "Inventário exemplo 3", value: 70 },
        { id: 4, name: "Inventário exemplo 4", value: 40 },
      ]);
    } else {
      // load data from API
      setData([]);
    }
  }, [useMockData]);

  return (
    <div className="open-inventory-accuracy">
      {Array.isArray(data) &&
        data.map((item, index) => {
          return (
            <div className="inventory-wrapper" key={"inventory-" + index}>
              <p className="label">{item.name}</p>
              <div className="inv-row">
                <div className="bar-container">
                  <div
                    className="bar"
                    style={{
                      background: CHART_COLORS[1],
                      width: `${item.value}%`,
                    }}
                  ></div>
                </div>
                <p className="percentage-label">{item.value}%</p>
              </div>
            </div>
          );
        })}
    </div>
  );
}
