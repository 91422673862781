import { GenericCounter } from "../../custom-charts-components/generic-counter";
import LanguageProvider from "~/shared/components/language-provider";
import "./AndonLastIntegrationOrder.scss";

export default function AndonLastIntegrationOrder() {
  return (
    <div className="andon-last-integration-order">
      <GenericCounter
        title={
          <LanguageProvider id="dashboard.andon.andon.last.integration.order" />
        }
        value={"00:03:00"}
      />
    </div>
  );
}
