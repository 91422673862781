interface InputMasks {
  [key: string]: (value: string) => string;
}

const inputMasks: InputMasks = {
  email: emailRegexMask,
  phone: phoneRegexMask,
  currency: currencyRegexMask,
  licensePlate: licensePlateRegexMask,
};

export function emailRegexMask(value: string): string {
  return value.replace(/[^a-zA-Z0-9@._-]/g, "");
}

export function phoneRegexMask(value: string): string {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
}

export function currencyRegexMask(value: string): string {
  if (!value) return "";
  return `$ ${value.toString().replace(/[^0-9.,]/g, "")}`;
}

export function licensePlateRegexMask(value: string): string {
  return value
    .replace(/[^a-zA-Z0-9]/g, "")
    .replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
}

export default inputMasks;
