import "./Grafana.scss";
import { getConfigValue } from "../../grid-item-helpers";

export default function Grafana(configs) {
  const url = getConfigValue(configs, "url");
  if (!url) return null;

  return (
    <div className="grafana">
      <iframe
        id="grafana"
        src={url}
        frameborder="0"
        title="grafana-embed"
      ></iframe>
    </div>
  );
}
