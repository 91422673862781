import React, { useEffect, useState } from "react";
import LanguageProvider from "~/shared/components/language-provider";
import "../scss/return.scss";
import { Button } from "~/shared/components/dcp-button";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import {
  listReturnItems,
  loadWarehouseConnections,
  returnItem,
} from "~/services/api/warehouse/movement";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputNumber } from "primereact/inputnumber";
import {
  ItemModel,
  ReturnItemListModel,
  ReturnItemModel,
} from "~/shared/interfaces/warehouse";
import { useFormik } from "formik";
import { useToastContext } from "~/context/ToastContext";

export function Return() {
  const [skus, setSkus] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [itemList, setItemList] = useState<ReturnItemListModel[]>([]);
  const [addresses, setAddresses] = useState([]);

  // Element Visibility
  const [submiting, setSubmiting] = useState<boolean>(false);
  const [loadingItemList, setLoadingItemList] = useState<boolean>(false);
  const [loadingFilterValues, setLoadingFilterValues] =
    useState<boolean>(false);

  const { showToast } = useToastContext();

  const form = useFormik<ReturnItemModel>({
    initialValues: {
      skuId: 0,
      applicantId: 0,
      items: [],
    },
    onSubmit: onSubmit,
  });

  async function onSubmit(values: ReturnItemModel) {
    try {
      const items = values.items.filter(
        (item) => item.qty > 0 && item.addressId > 0
      );

      values.items = items;

      const data = await returnItem(values);

      if (data) {
        showToast({
          severity: "success",
          message: <LanguageProvider id="return.success" />,
        });
        form.resetForm();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadConnectionValues() {
    try {
      setLoadingFilterValues(true);
      const data = await loadWarehouseConnections([
        "sku",
        "applicants",
        "address",
      ]);

      if (!Array.isArray(data) || data.length < 1)
        throw new Error("Missing data");

      const appliacantOptions = formatConnection(
        data.find((ao) => ao.applicationConnectionCode === "applicants")
      );
      const skuOptions = formatConnection(
        data.find((ao) => ao.applicationConnectionCode === "sku")
      );
      const addressesOptions = formatConnection(
        data.find((ao) => ao.applicationConnectionCode === "address")
      );

      setApplicants(appliacantOptions);
      setSkus(skuOptions);
      setAddresses(addressesOptions);
    } catch (error) {
      console.log(error);
    }
    setLoadingFilterValues(false);
  }

  async function loadReturnItems() {
    try {
      setLoadingItemList(true);
      const data = await listReturnItems(
        form.values.skuId,
        form.values.applicantId
      );
      if (!Array.isArray(data)) return null;

      setItemList(data);

      const _returnItems = data.map((item) => {
        return {
          movementId: item.movementId,
          qty: undefined,
          addressId: 0,
        };
      });
      form.setFieldValue("items", _returnItems);
    } catch (error) {
      console.error(error);
    }
    setLoadingItemList(false);
  }

  function updateQty(movementId: number, qty: number) {
    const _returnItems = [...form.values.items];

    for (let i = 0; i < _returnItems.length; i++) {
      if (_returnItems[i].movementId === movementId) _returnItems[i].qty = qty;
    }

    form.setFieldValue("items", _returnItems);
  }

  function updateAddress(movementId: number, addressId: number) {
    const _returnItems = [...form.values.items];

    for (let i = 0; i < _returnItems.length; i++) {
      if (_returnItems[i].movementId === movementId)
        _returnItems[i].addressId = addressId;
    }

    form.setFieldValue("items", _returnItems);
  }

  useEffect(() => {
    loadConnectionValues();
  }, []);

  return (
    <div className="return">
      <div className="content-container">
        <div className="header">
          <p>
            <LanguageProvider id="return" />
          </p>
        </div>
        <div className="content">
          <div className="filters">
            <p>
              <LanguageProvider id="return.register" />
            </p>
            <Form className="filter-form">
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="datasource.column.itemCode" />}
                >
                  <Dropdown
                    options={skus}
                    optionValue="key"
                    optionLabel="value"
                    value={form.values.skuId}
                    filter
                    loading={loadingFilterValues}
                    onChange={(e) => form.setFieldValue("skuId", e.value)}
                  ></Dropdown>
                </InputContainer>
                <InputContainer
                  label={
                    <LanguageProvider id="dcp.platform.warehouse.output.requester" />
                  }
                >
                  <Dropdown
                    options={applicants}
                    optionValue="key"
                    optionLabel="value"
                    loading={loadingFilterValues}
                    filter
                    value={form.values.applicantId}
                    onChange={(e) => form.setFieldValue("applicantId", e.value)}
                  ></Dropdown>
                </InputContainer>
                <InputContainer className="search-btn-container">
                  <Button
                    type="button"
                    disabled={
                      form.values.applicantId < 1 || form.values.skuId < 1
                    }
                    onClick={() => loadReturnItems()}
                  >
                    <Icon
                      icon={"search-refraction"}
                      size={undefined}
                      color={"white"}
                    ></Icon>
                    <LanguageProvider id="gen.search" />
                  </Button>
                </InputContainer>
              </FormRow>
            </Form>
          </div>
          <div className="list">
            <p>
              <LanguageProvider id="gen.item.list" />
            </p>
            <div className="datatable-container">
              <DataTable
                value={itemList}
                paginator
                rows={10}
                scrollable
                scrollHeight="flex"
                loading={loadingItemList}
                emptyMessage={<LanguageProvider id="gen.no.registers" />}
              >
                <Column
                  field="moveDate"
                  header={<LanguageProvider id="movement.date" />}
                  body={(rowData) => {
                    return new Date(rowData.moveDate).toLocaleString();
                  }}
                ></Column>
                <Column
                  field="user"
                  header={<LanguageProvider id="gen.user" />}
                ></Column>
                <Column
                  field="qtd"
                  header={<LanguageProvider id="gen.current.qtd" />}
                ></Column>
                <Column
                  header={<LanguageProvider id="gen.qtd" />}
                  body={(rowData) => {
                    return (
                      <InputNumber
                        value={
                          form.values.items.length > 0 &&
                          form.values.items.find(
                            (rim) => rim.movementId === rowData.movementId
                          ).qty
                        }
                        onChange={(e) => updateQty(rowData.movementId, e.value)}
                      ></InputNumber>
                    );
                  }}
                ></Column>
                <Column
                  header={<LanguageProvider id="gen.destination" />}
                  body={(rowData) => {
                    return (
                      <Dropdown
                        options={addresses}
                        optionValue="key"
                        optionLabel="value"
                        value={
                          form.values.items.length > 0 &&
                          form.values.items.find(
                            (rim) => rim.movementId === rowData.movementId
                          ).addressId
                        }
                        onChange={(e) =>
                          updateAddress(rowData.movementId, e.value)
                        }
                        filter
                      ></Dropdown>
                    );
                  }}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div className="footer">
          <Button className="p-button-text" onClick={() => form.resetForm()}>
            <LanguageProvider id="dcp.platform.warehouse.clean.data" />
          </Button>
          <Button loading={submiting} onClick={() => form.submitForm()}>
            <Icon icon={"download-02"} size={undefined} color={"white"}></Icon>
            <LanguageProvider id="return.to" />
          </Button>
        </div>
      </div>
    </div>
  );
}

function formatConnection(data: any[]): { key: number; value: string }[] {
  return Object.entries(data.values).map(([key, value]) => {
    return {
      key: parseInt(key),
      value,
    };
  });
}
