import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { CHART_COLORS } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import "./PlannedLocations.scss";
export default function PlannedLocations(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({
    plannedLocations: 0,
    completedLocations: 0,
  });

  useEffect(() => {
    if (useMockData) {
      setData({
        plannedLocations: 400,
        completedLocations: 137,
      });
    } else {
      // fetch data from API
      setData({
        plannedLocations: 0,
        completedLocations: 0,
      });
    }
  }, [useMockData]);

  const completedPercentage =
    (data.completedLocations / data.plannedLocations) * 100;

  return (
    <div className="planned-locations">
      <div className="bar-wrapper">
        <div className="bar">
          <span className="background-fill"></span>
          <span
            className="value"
            style={{
              background: CHART_COLORS[1],
              width: `${completedPercentage}%`,
            }}
          ></span>
        </div>
        <span className="percentage-label">
          {`${data.completedLocations} / ${data.plannedLocations}`}
        </span>
      </div>
      <div className="legend">
        <p>
          Locações previstas:{" "}
          <span className="label-value">{`${data.plannedLocations}`}</span>
        </p>
        <p>
          Locações realizadas:{" "}
          <span className="label-value">{`${data.completedLocations}`}</span>
        </p>
      </div>
    </div>
  );
}
