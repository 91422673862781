import "./scss/ai-analytics-dialog.scss";

import { FormRow, InputContainer } from "./dcp-form";
import { ThemeContext, UserContext } from "~/app";
import { useContext, useEffect, useRef, useState } from "react";

import { Button } from "./dcp-button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import Icon from "./icons";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "./language-provider";
import { ProductContext } from "../main-layout.jsx";
import { RadioButton } from "primereact/radiobutton";
import settings from "../../services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

const AiAnalytics = ({
  visible,
  onClose,
  data,
  openingSource = null,
  idDashboard = null,
  dashboardItem = null,
  dashboardItemTypes = [],
}) => {
  const { currentTheme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const dcpAxiosService = useDcpAxiosService();

  const [dashboard, setDashboard] = useState();
  const [dialogMessages, setDialogMessages] = useState([]);
  const [availableCharts, setAvailableCharts] = useState();
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [firstInteraction, setFirstInteraction] = useState(true);
  const [chatState, setChatState] = useState("awaiting-user-question");
  const [selectedAnalyticOption, setSelectedAnalyticOption] = useState("");

  async function loadDashboard(idDashboard) {
    try {
      const dasboardRequest = await dcpAxiosService.get(
        `${settings.Urls.Rest.Dashboard}?idDashboard=${idDashboard}`,
        "Platform"
      );
      if (
        dasboardRequest &&
        dasboardRequest.data &&
        dasboardRequest.data.status
      ) {
        setDashboard(dasboardRequest.data.data);
        const dasboardItemsRequest = await dcpAxiosService.get(
          `${settings.Urls.Rest.DashboardItem}?idDashboard=${idDashboard}`,
          "Platform"
        );
        if (dasboardItemsRequest.data.status) {
          setAvailableCharts(dasboardItemsRequest.data.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadDashboardItems(dashboardItem) {
    try {
      const dasboardItemRequest = await dcpAxiosService.get(
        `${settings.Urls.Rest.DashboardItem}?idDashboard=${dashboardItem.dashboardId}`,
        "Platform"
      );
      if (
        dasboardItemRequest &&
        dasboardItemRequest.data &&
        dasboardItemRequest.data.status
      ) {
        let selectedDashboardItem = dasboardItemRequest.data.data.filter(
          (x) => x.id === dashboardItem.id
        );
        setAvailableCharts(selectedDashboardItem);
        setSelectedCharts(selectedDashboardItem);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function askQuestion(message) {
    if (message && message.length > 0) {
      setFirstInteraction(false);
      setDialogMessages([
        ...dialogMessages,
        {
          origin: "user",
          type: "plain-text",
          content: message,
        },
      ]);
    }
  }
  function analyseChart() {
    setFirstInteraction(false);
    try {
      setChatState("chart-analysis-selection");
      let currentDialog = dialogMessages;
      currentDialog.push({
        origin: "user",
        type: "plain-text",
        content: LanguageProvider({
          id: "dcp.ai.analytics.chart.analysis.title",
        }),
      });
      setDialogMessages([...currentDialog]);
    } catch (error) {
      console.error(error);
    }
  }
  function handleChartSelection(value) {
    try {
      let charts = selectedCharts;
      const chartIndex = charts.findIndex((x) => x === value);
      if (chartIndex >= 0) {
        setSelectedCharts(charts.filter((x) => x != value));
      } else {
        charts.push(value);
        setSelectedCharts(charts);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function abortChartAnalytic() {
    setChatState("awaiting-user-question");
    setSelectedCharts([]);
    setDialogMessages([
      ...dialogMessages,
      {
        origin: "user",
        type: "plain-text",
        content: <LanguageProvider id={"gen.back"} />,
      },
    ]);
  }
  function startChartAnalyticSelection() {
    try {
      if (selectedCharts && selectedCharts.length > 0) {
        let selectedChartNames = selectedCharts.map((x) => x.title);
        setChatState("chart-analysis-process-method-options");
        let currentDialog = dialogMessages;
        currentDialog.push({
          origin: "user",
          type: "plain-text",
          content: selectedChartNames.join(", "),
        });
        setDialogMessages([...currentDialog]);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function abortAnalyticOptionSelection() {
    setChatState("chart-analysis-selection");
    setDialogMessages([
      ...dialogMessages,
      {
        origin: "user",
        type: "plain-text",
        content: <LanguageProvider id={"gen.back"} />,
      },
    ]);
  }
  function startChartAnalytic() {
    try {
      setDialogMessages([
        ...dialogMessages,
        {
          origin: "user",
          type: "plain-text",
          content: (
            <LanguageProvider
              id={`dcp.ai.analytics.chart.analysis.action.${selectedAnalyticOption}`}
            />
          ),
        },
      ]);
      switch (selectedAnalyticOption) {
        case "productivity":
        case "chart-comparison":
          setChatState("analyzing-data");
          beginDataAnalyzis();
          break;
        case "custom-action":
        default:
          setChatState("awaiting-user-question");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function beginDataAnalyzis() {
    try {
      setDialogMessages([
        ...dialogMessages,
        {
          origin: "system",
          type: "plain-text",
          content: <SystemResponseLoading />,
        },
      ]);

      let selectedChart = [];

      selectedCharts.map(function (x) {
        selectedChart.push({
          type: "ensaque",
          idChart: x.id,
        });
      });

      const analyticsResponse = await dcpAxiosService.post(
        `${settings.Urls.Rest.ChatGpt}`,
        {
          message: "",
          analyticType: selectedAnalyticOption,
          data: "",
          charts: selectedChart,
          mockData: dashboard.useMockData,
          language: "pt-br",
        },
        "AI"
      );
      if (analyticsResponse && analyticsResponse.data.status) {
        setDialogMessages([
          ...dialogMessages,
          {
            origin: "system",
            type: "plain-text",
            content: analyticsResponse.data.data
              .split("\n")
              .map((str) => <span>{str}</span>),
          },
        ]);
        setChatState("awaiting-user-question");
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (visible) {
      setDashboard();
      setDialogMessages([]);
      setAvailableCharts([]);
      setSelectedCharts([]);
      setFirstInteraction(true);
      setChatState("awaiting-user-question");
      setSelectedAnalyticOption("");
      if (idDashboard) loadDashboard(idDashboard);
      if (dashboardItem && openingSource === "report-item") {
        setChatState("chart-analysis-process-method-options");
        loadDashboardItems(dashboardItem);
      }
    }
  }, [visible, openingSource]);

  /**This function is responsible for render the user interaction section of the dialog */
  const RenderAiAnalyticsInteractionSection = () => {
    switch (chatState) {
      case "chart-analysis-process-method-options":
        return (
          <div className="analytics-actions-step-control">
            <Button
              className={"btn-ai-analytics-actions-step-control"}
              onClick={abortAnalyticOptionSelection}
            >
              <Icon
                icon={"arrow-narrow-left"}
                color={currentTheme.iconDefaultColor}
              />
              <LanguageProvider id={"gen.back"} />
            </Button>
            <Button
              className={"btn-ai-analytics-actions-step-control"}
              onClick={startChartAnalytic}
            >
              <LanguageProvider id={"gen.next"} />
              <Icon
                icon={"arrow-narrow-right"}
                color={currentTheme.iconDefaultColor}
              />
            </Button>
          </div>
        );
      case "chart-analysis-selection":
        return (
          <div className="analytics-actions-step-control">
            <Button
              className={"btn-ai-analytics-actions-step-control"}
              onClick={abortChartAnalytic}
            >
              <Icon icon={"arrow-narrow-left"} color={currentTheme.primary} />
              <LanguageProvider id={"gen.back"} />
            </Button>
            <Button
              className={"btn-ai-analytics-actions-step-control"}
              onClick={startChartAnalyticSelection}
            >
              <LanguageProvider id={"gen.next"} />
              <Icon icon={"arrow-narrow-right"} color={currentTheme.primary} />
            </Button>
          </div>
        );
      case "analyzing-data":
        return (
          <div className="ai-analytics-custom-input-container">
            <FormRow>
              <InputContainer>
                <AiAnalyticCustomInput onSubmit={askQuestion} disabled />
              </InputContainer>
            </FormRow>
          </div>
        );
      case "awaiting-user-question":
      default:
        return (
          <div className="ai-analytics-custom-input-container">
            <FormRow>
              <InputContainer>
                <AiAnalyticCustomInput
                  onSubmit={askQuestion}
                  disabled={firstInteraction}
                />
              </InputContainer>
            </FormRow>
          </div>
        );
    }
  };

  /**
   * This function is responsible for rendering all of the system action recomendation for the user
   * depending of the current state of the chat
   */
  const RenderAiAnalyticsSuggestionsSection = () => {
    switch (chatState) {
      case "chart-analysis-process-method-options":
        return (
          <div className="ai-analytics-sugestions-area">
            <span className="ai-analytics-user-selection-options-title">
              <LanguageProvider
                id={"dcp.ai.analytics.chart.analysis.action.selection"}
              />
            </span>
            <SystemSuggestionRadioSelection
              label={LanguageProvider({
                id: "dcp.ai.analytics.chart.analysis.action.productivity",
              })}
              option={"productivity"}
              value={selectedAnalyticOption}
              onChange={setSelectedAnalyticOption}
            />
            {selectedCharts && selectedCharts.length > 1 && (
              <SystemSuggestionRadioSelection
                label={LanguageProvider({
                  id: "dcp.ai.analytics.chart.analysis.action.chart-comparison",
                })}
                option={"chart-comparison"}
                value={selectedAnalyticOption}
                onChange={setSelectedAnalyticOption}
              />
            )}
            <SystemSuggestionRadioSelection
              label={LanguageProvider({
                id: "dcp.ai.analytics.chart.analysis.action.custom-question",
              })}
              option={"custom-question"}
              value={selectedAnalyticOption}
              onChange={setSelectedAnalyticOption}
            />
          </div>
        );
      case "chart-analysis-selection":
        return (
          <div className="ai-analytics-sugestions-area">
            <span className="ai-analytics-user-selection-options-title">
              <LanguageProvider
                id={"dcp.ai.analytics.chart.analysis.response"}
              />
            </span>
            {availableCharts &&
              Array.isArray(availableCharts) &&
              availableCharts.map((chart) => {
                const aiEnabled = dashboardItemTypes.find(
                  (type) => type.id === chart.itemTypeId
                ).ai;

                if (chart.title && aiEnabled) {
                  return (
                    <SystemSuggestionSelection
                      option={chart}
                      label={chart.title}
                      onSelect={handleChartSelection}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </div>
        );
      case "awaiting-user-question":
        return (
          <div className="ai-analytics-sugestions-area">
            <span className="ai-analytics-sugestions-title">
              <LanguageProvider id={"dcp.ai.analytics.sugestion.title"} />
            </span>
            {!data && (
              <div className="ai-analytics-sugestions-container">
                {idDashboard && (
                  <div
                    className="ai-analytics-sugestion"
                    onClick={analyseChart}
                  >
                    <span className="ai-analytics-sugestion-title">
                      <LanguageProvider
                        id={"dcp.ai.analytics.chart.analysis.title"}
                      />
                    </span>
                    <span className="ai-analytics-sugestion-content">
                      <LanguageProvider
                        id={"dcp.ai.analytics.chart.analysis.message"}
                      />
                    </span>
                  </div>
                )}
                <div className="ai-analytics-sugestion">
                  <span className="ai-analytics-sugestion-title">
                    <LanguageProvider
                      id={"dcp.ai.analytics.chart.history.title"}
                    />
                  </span>
                  <span className="ai-analytics-sugestion-content">
                    <LanguageProvider
                      id={"dcp.ai.analytics.chart.history.message"}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return;
    }
  };

  return (
    <Dialog
      className="ai-analytics-dialog"
      visible={visible}
      onHide={onClose}
      header={() => {
        return (
          <div className="ai-analytics-dialog-header">
            <LanguageProvider id={"gen.ai"} />
            <Icon
              icon={"lightbulb-02"}
              color={currentTheme.primary}
              size={20}
            />
          </div>
        );
      }}
    >
      <div className="ai-analytics-dialog-content">
        <div className="ai-analytics-dialog-content-container">
          <div className="ai-analytics-responses">
            {dialogMessages &&
              dialogMessages.map((dialogMessage, dialogMessageIndex) => {
                return (
                  <span
                    key={`ai-analytics-dialog-message-${dialogMessageIndex}`}
                    className={[
                      "ai-analytics-dialog-message",
                      dialogMessage.origin === "user"
                        ? "user-message"
                        : "system-message",
                    ].join(" ")}
                  >
                    {dialogMessage.content}
                  </span>
                );
              })}
          </div>
          <div className="ai-analytics-user-area">
            {firstInteraction && (
              <span className="ai-analytics-user-area-message">
                <span className="ai-analytics-user-area-message-user-name">
                  {user.userName}
                </span>
                <LanguageProvider id={"dcp.ai.analytics.dialog.user.message"} />
              </span>
            )}
            <RenderAiAnalyticsSuggestionsSection />
            <RenderAiAnalyticsInteractionSection />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const AiAnalyticCustomInput = ({ onSubmit, disabled }) => {
  const { currentTheme } = useContext(ThemeContext);

  const [value, setValue] = useState("");

  const inputRef = useRef();

  function submitChat() {
    onSubmit(value);
    setValue("");
  }

  return (
    <div
      className={[
        "ai-analytics-custom-input-wrapper",
        disabled ? "disabled" : "",
      ].join(" ")}
      onClick={() => inputRef.current.focus()}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          submitChat();
        }
      }}
    >
      <InputText
        ref={inputRef}
        className="ai-analytics-custom-input"
        value={value}
        disabled={disabled}
        onChange={(e) => setValue(e.target.value)}
        placeholder={LanguageProvider({
          id: "dcp.ai.analytics.dialog.input.placeholder",
        })}
      />
      <button className="btn-submit" onClick={submitChat}>
        <Icon icon={"send-01"} color={currentTheme.blueGray300} />
      </button>
    </div>
  );
};

const SystemSuggestionSelection = ({ option, label, onSelect }) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="ai-analytics-dialog-suggestion-option">
      <Checkbox
        checked={checked}
        onChange={(e) => {
          onSelect(option);
          setChecked(e.checked);
        }}
      />
      <span className="ai-analytics-dialog-message-option-label">{label}</span>
    </div>
  );
};

const SystemSuggestionRadioSelection = ({ label, option, value, onChange }) => {
  return (
    <div className="ai-analytics-dialog-suggestion-option">
      <RadioButton
        checked={value === option}
        value={option}
        onChange={(e) => {
          onChange(e.value);
        }}
      />
      <span className="ai-analytics-dialog-message-option-label">{label}</span>
    </div>
  );
};

const SystemResponseLoading = () => {
  return <span>...</span>;
};
export default AiAnalytics;
