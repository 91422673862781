import { ThemeContext } from "~/app";
import "./scss/page-header.scss";

import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { useContext } from "react";

export default function PageHeader({
  title,
  titleTemplate,
  recordsCount,
  onReturn,
  actions,
}) {
  const { currentTheme } = useContext(ThemeContext);

  return (
    <div className="PageHeader">
      <div className="left-side">
        {onReturn && (
          <div className="return">
            <div className="icon">
              <Icon
                icon={"arrow-narrow-left"}
                size="28px"
                color={currentTheme.reportBackIconColor}
                onClick={onReturn}
              />
            </div>
          </div>
        )}
        <div className="title-wrapper">
          {titleTemplate ? (
            titleTemplate()
          ) : (
            <div className="title">{title}</div>
          )}
          {recordsCount >= 0 && (
            <div className="records-count">
              {recordsCount}{" "}
              <LanguageProvider
                id={
                  recordsCount < 2 && recordsCount !== 0
                    ? "gen.register"
                    : "gen.registers"
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="right-side">{actions != null && actions()}</div>
    </div>
  );
}
