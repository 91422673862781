import React from "react";
import { FixedTextProps } from ".";

export function FixedText(props: FixedTextProps): JSX.Element {
  return (
    <>
      <span>{props.value}</span>
    </>
  );
}
