import LanguageProvider from "../../shared/components/language-provider";
import React from "react";

const NotFound = () => {
  return (
    <div className="home">
      <h1><LanguageProvider id={'page.not.found'} /></h1>
    </div>
  );
};

export default NotFound;
