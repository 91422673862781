import "./Table.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function Table(configs) {
  const data = [
    {
      location: "A-01-02",
      reads: 203,
      skus: 1,
    },
    {
      location: "A-01-03",
      reads: 282,
      skus: 2,
    },
  ];

  return (
    <div className="table-type">
      <DataTable value={data}>
        <Column field="location" header={"Locação"} />
        <Column field="reads" header={"Quantidade Leituras"} />
        <Column field="skus" header={"Quantidade SKUs"} />
      </DataTable>
    </div>
  );
}
