import { useCallback, useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";

const UseDatabaseProductProcess = () => {
  const [databaseProductProcessData, setDatabaseProductProcessData] =
    useState(null);
  const [error, setError] = useState(null);
  const dcpAxiosService = useDcpAxiosService();

  const loadDatabaseProductProcess = useCallback(
    async (databaseProccessType) => {
      try {
        const { data: databaseProductProcess } = await dcpAxiosService.get(
          `${settings.Urls.Rest.DatabasesProductProccess}/get-database-product-process`,
          "Platform",
          {
            params: {
              databaseProductProcessType: databaseProccessType,
            },
          }
        );

        let databaseProductProcessTypeDataObj = {
          idDatabase: databaseProductProcess.data.idDatabase,
          hasDetails:
            databaseProductProcess.data.uriDetails === "" ? false : true,
          uriDetails: databaseProductProcess.data.uriDetails,
          nameDatabase: databaseProductProcess.data.nameDatabase,
        };

        setDatabaseProductProcessData(databaseProductProcessTypeDataObj);
      } catch (error) {
        setError(error);
        console.error(error);
      }
    },
    []
  );

  return { databaseProductProcessData, error, loadDatabaseProductProcess };
};

export default UseDatabaseProductProcess;
