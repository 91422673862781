import React, { useContext, useRef, useState } from "react";
import LanguageProvider from "~/shared/components/language-provider";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputMask } from "primereact/inputmask";
import { RadioButton } from "primereact/radiobutton";
import { Draggable } from "react-beautiful-dnd";
import Icon from "~/shared/components/icons";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { Menu } from "primereact/menu";
import { EditFieldModal } from "./edit-field-modal";
import { Dropdown } from "primereact/dropdown";
import { ThemeContext } from "~/app";

export function FormField({
  index,
  field,
  fieldTypes,
  onEdit,
  onDelete,
  onDuplicate,
}) {
  const { currentTheme } = useContext(ThemeContext);
  const ref = useRef(null);

  // Dialog controll
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);

  const menuItemTemplate = (
    options,
    color,
    fontWeight,
    viewName,
    onClick = () => {}
  ) => {
    return (
      <div
        className={classNames(options.className)}
        style={{ color, fontWeight: fontWeight, fontSize: "14px" }}
        onClick={onClick}
      >
        {LanguageProvider({ id: viewName })}
      </div>
    );
  };

  const menuItems = [
    {
      template: (item, options) =>
        menuItemTemplate(
          options,
          currentTheme.textPrimary,
          "400",
          "field.database.edit",
          () => setEditModalVisible(true)
        ),
    },
    {
      template: (item, options) =>
        menuItemTemplate(
          options,
          currentTheme.textPrimary,
          "400",
          "field.database.duplicate",
          () => setDuplicateModalVisible(true)
        ),
    },
    { separator: true },
    {
      template: (item, options) =>
        menuItemTemplate(
          options,
          "#F9485B",
          "600",
          "field.database.delete",
          () => setDeleteModalVisible(true)
        ),
    },
  ];

  const getFieldInputComponent = (field) => {
    const _fieldType = fieldTypes.find(
      (fieldType) => fieldType.id === field.idTypeValue
    );

    if (!_fieldType) return;

    switch (_fieldType.name) {
      case "short-text":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "long-text":
        return (
          <InputTextarea
            className="p-inputtext-sm form-field-longText"
            disabled={true}
          />
        );
      case "fixed-text":
        return (
          <span
            className="p-inputtext-sm form-field-longText"
            disabled={true}
          />
        );
      case "unique-selection":
        return (
          <>
            <RadioButton checked={false} disabled={true} />
          </>
        );
      case "dropdown":
        return (
          <>
            <Dropdown
              className="p-dropdown form-field-dropdown"
              checked={false}
              disabled={true}
            />
          </>
        );
      case "currency":
        return (
          <InputMask
            id="date"
            mask="00.00"
            placeholder="00.00"
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "date":
        return (
          <InputMask
            id="date"
            mask="dd/mm/yyyy"
            placeholder="dd/mm/yyyy"
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "datetime":
        return (
          <InputMask
            id="date"
            mask="dd/mm/yyyy"
            placeholder="dd/mm/yyyy hh:mm"
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );

      case "due-date":
        return (
          <InputMask
            id="date"
            mask="dd/mm/yyyy"
            placeholder="dd/mm/yyyy"
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "email":
        return (
          <InputMask
            id="email"
            placeholder="your@email.com"
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "telephone":
        return (
          <InputMask
            id="telephone"
            placeholder="(99)99999-9999"
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "time":
        return (
          <InputMask
            id="time"
            placeholder="00:00"
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "number":
        return (
          <InputMask
            id="number"
            placeholder="0"
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "tipo-recebimento":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "receiving-storage":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "receiving-dock":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "type-conference":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "receiving-status":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "dispatch-status":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "type-separation":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "dispatch-dock":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );
      case "database":
        return (
          <InputText
            className="p-inputtext-sm form-field-text"
            disabled={true}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <Draggable
        key={`formField-${field.sequence}-${index}`}
        draggableId={`${field.sequence}`}
        index={field.sequence}
      >
        {(provided, snapshot) => (
          <div
            className={
              field.isReadOnly === false
                ? "form-field-wrapper"
                : "blocked-form-field-wrapper"
            }
            index={index}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              border:
                snapshot.isDragging &&
                "1px solid var(--blue-gray-400, #717bbc)",
            }}
          >
            <div className="header-field">
              <div className="field-label">
                <Icon
                  icon={field.iconName}
                  size={22}
                  color={"#8189A3"}
                  className={"icon-field-identificator"}
                />
                {field.required && (
                  <Icon
                    icon={"asterisk-01"}
                    size={10}
                    color={"#344054"}
                    className={"icon-field-required"}
                  />
                )}
                <InputText
                  value={field.viewName}
                  onChange={(e) => {
                    const editedField = {
                      ...field,
                    };
                    editedField.viewName = e.target.value;
                    if (onEdit) onEdit(editedField);
                  }}
                  className="p-inputtext-sm field-title"
                ></InputText>
                {field.hasTextHelp && (
                  <Icon
                    icon={"alert-circle"}
                    size={25}
                    color={"#717BBC"}
                    className={"icon-label"}
                  />
                )}
              </div>
              {field.isReadOnly === false && (
                <div className="field-manage-buttons">
                  <div
                    className="edit-button"
                    onClick={() => setEditModalVisible(true)}
                  >
                    <Icon
                      icon={"edit-02"}
                      color={"#8189A3"}
                      size={30}
                      className={"edit-field-button"}
                    />
                  </div>

                  <div
                    className="settings-button"
                    onClick={(e) => {
                      ref.current.toggle(e);
                      e.stopPropagation();
                    }}
                  >
                    <Icon
                      icon={"dots-vertical"}
                      size={30}
                      color={"#8189A3"}
                      className={"settings-field-button"}
                    />
                    <Menu popup model={menuItems} ref={ref}></Menu>
                  </div>
                </div>
              )}
            </div>
            {field.hasDescription && (
              <span className="field-description">{field.description}</span>
            )}
            <div className="form-field-body">
              {getFieldInputComponent(field)}
            </div>
          </div>
        )}
      </Draggable>

      <EditFieldModal
        isEditModalVisible={editModalVisible}
        model={field}
        fieldInput={() => getFieldInputComponent(field)}
        onSave={(data) => {
          if (onEdit) onEdit(data);
          setEditModalVisible(false);
        }}
        onClose={() => setEditModalVisible(false)}
      />

      <ModalConfirmation
        isOpen={deleteModalVisible}
        modalTitle={<LanguageProvider id="field.database.confirm.delete" />}
        bodyMessage={
          <LanguageProvider id="field.database.confirm.delete.message" />
        }
        isDelete={true}
        onConfirm={() => {
          if (onDelete) onDelete(field);
          setDeleteModalVisible(false);
        }}
        onCancel={() => setDeleteModalVisible(false)}
      />

      <ModalConfirmation
        isOpen={duplicateModalVisible}
        modalTitle={<LanguageProvider id="field.database.confirm.duplicate" />}
        bodyMessage={
          <LanguageProvider id="field.database.confirm.duplicate.message" />
        }
        isDelete={false}
        onConfirm={() => {
          if (onDuplicate) onDuplicate(field);
          setDuplicateModalVisible(false);
        }}
        onCancel={() => setDuplicateModalVisible(false)}
      />
    </>
  );
}
