import LanguageProvider from "~/shared/components/language-provider";

import "../scss/setting-group.scss";

export function SettingGroup({ name, children }) {
  return (
    <div className="group-container">
      <h3 className="page-header">
        {<LanguageProvider id={`setting.group.${name}`} />}
      </h3>
      {children}
    </div>
  );
}
