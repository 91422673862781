import "./scss/documentation-modal.scss";

import { useRef, useState } from "react";

import { InputText } from "primereact/inputtext";
import LanguageProvider from "./components/language-provider";
import { Menu } from "primereact/menu";

const DocumentationModal = () => {
  return (
    <>
      <div className="documentation-modal">
        <div className="content">
          {/* Documentation  */}
          <div className="documentation-group">
            <div className="documentation-item">
              <LanguageProvider id={"gen.documentation"} />
            </div>
            <div className="documentation-key-hot">
              <span className="tag">F1</span>
            </div>
          </div>

          {/* Search  */}
          <div className="documentation-group">
            <div className="documentation-item">
              <LanguageProvider id={"gen.research"} />
            </div>
            <div className="documentation-key-hot">
              <span className="tag">CTRL</span>
              <span className="no-tag">+</span>
              <span className="tag">K</span>
            </div>
          </div>

           {/* Meus Produtos  */}
           <div className="documentation-group">
            <div className="documentation-item">
              <LanguageProvider id={"gen.my.products"} />
            </div>
            <div className="documentation-key-hot">
              <span className="tag">F2</span>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="items">
            <div className="item">
              <span className="tag">Esc</span>{" "}
              <LanguageProvider id={"dpc.gen.leave.key.desc"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentationModal;
