import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import fakeData from "../../fake-data/amc/tasks-by-user.json";
import { defaultChartOptions } from "../../grid-item-helpers";
import { ThemeContext } from "~/app";

export default function TasksByUser(configs) {
  const useMockData = useContext(UseMockDataContext);

  const [data, setData] = useState({ labels: [], datasets: [] });
  const { currentTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (useMockData) {
      setData(fakeData);
    } else {
      setData({ labels: [], datasets: [] });
    }
  }, [useMockData]);

  const options = {
    ...defaultChartOptions,
    indexAxis: "y",
    plugins: {
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      options={options}
      data={data}
      width="100%"
      height="100%"
    />
  );
}
