import "../scss/area-history-location-dialog.scss";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import Icon from "~/shared/components/icons";
import locationImg2 from "~/theme/media/assets/Location.png";
import locationImg1 from "~/theme/media/assets/Location1.png";

import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ThemeContext } from "~/app";
import { useNavigate } from "react-router-dom";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

export function AreaHistoryLocationDialog({ isOpen, onClose, locations }) {
  console.log("locations", locations);

  const { currentTheme } = useContext(ThemeContext);
  const axiosService = useDcpAxiosService();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrentLocation, setSelectedCurrentLocation] = useState(
    locations[0]
  );
  const [selectedLocation, setSelectedLocation] = useState(
    locations[0]?.locationId
  );
  const [inventoryItemCountResultItens, setInventoryItemCountResultItens] =
    useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const selectImage = (index) => {
    setSelectedImage(index);
  };
  // Images URL's
  const images = [locationImg1, locationImg2];

  const getInventoryItemCount = async () => {
    try {
      const response = await axiosService.get(
        settings.Urls.Rest.Inventory + "/list-read-location-data",
        "Inventory",
        { params: { idLocation: selectedLocation } }
      );
      console.log("response", response);
      if (response.status === 200 && response.data.data) {
        setInventoryItemCountResultItens(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const CloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (locations.length !== 0) {
      setIsLoading(true);
      setSelectedCurrentLocation(locations[0]);
      setSelectedLocation(locations[0]?.locationId);
      getInventoryItemCount();
      console.log("selectedLocation", selectedLocation);
      console.log("selectedLocation", selectedCurrentLocation);
      setIsLoading(false);
    }
  }, [locations]);

  return (
    <>
      <Dialog
        visible={isOpen}
        onHide={CloseModal}
        className="dialog-area-history-details"
        maximized={false}
      >
        <div className="barcode-read">
          <div className="barcode-read-content">
            <div className="barcode-details">
              <div className="return-option-wrapper">
                <div className="icon-return">
                  <Icon
                    icon="arrow-narrow-left"
                    color="#4146FF"
                    size={20}
                    onClick={CloseModal}
                  />
                </div>
                <span className="return-text-title">
                  <LanguageProvider id="gen.area.history" />
                </span>
              </div>
              <div className="barcode-form-wrapper">
                <div className="dcp-form">
                  <div className="form-row">
                    <div className="input-container location-name">
                      <label className="identifier-location-label">
                        Confiabilidade da imagem
                      </label>
                      <span className="content-location">Não confiável</span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="input-container street-name">
                      <label className="identifier-location-label">
                        <LanguageProvider id="gen.street" />
                      </label>
                      <span className="content-location">
                        {selectedCurrentLocation?.locationName.split("-")[0]}
                      </span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="input-container location-name">
                      <label className="identifier-location-label">
                        <LanguageProvider id="gen.location" />
                      </label>
                      <span className="content-location">
                        {selectedCurrentLocation?.locationName}
                      </span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="input-container scan-date">
                      <label className="identifier-location-label">
                        <LanguageProvider id="gen.scanned" />
                      </label>
                      <span className="content-location">
                        {selectedCurrentLocation?.lastUpdate ? (
                          `${new Date(
                            selectedCurrentLocation.lastUpdate
                          ).toLocaleDateString()} ${new Date(
                            selectedCurrentLocation.lastUpdate
                          ).toLocaleTimeString()}`
                        ) : (
                          <span>Sem registro de data</span>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="input-container location-name">
                      <label className="identifier-location-label">
                        Inventário
                      </label>
                      <span className="content-location inventory-link">
                        {selectedCurrentLocation?.inventoryCode ? (
                          <span
                            onClick={() =>
                              window.open(
                                `/inventory-dev/inventory/${selectedCurrentLocation.inventoryId}`,
                                "_blank"
                              )
                            }
                          >
                            {selectedCurrentLocation.inventoryCode}
                          </span>
                        ) : (
                          <span>Sem registro</span>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="input-container location-name">
                      <span className="identifier-location-label">
                        Leituras
                      </span>
                      <div className="content-body">
                        <div className="dcp-table">
                          <div className="itens-table">
                            <DataTable
                              value={inventoryItemCountResultItens}
                              emptyMessage={"Sem registros"}
                            >
                              <Column field="sku" header="SKU" />
                              <Column field="result" header="Qtd" />
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="barcode-image">
              <div className="location-select-dropdown">
                <Icon
                  className={`left-icon-arrow${
                    locations.findIndex(
                      (location) => location.locationId === selectedLocation
                    ) === 0
                      ? "-disabled"
                      : ""
                  }`}
                  color={currentTheme.tableIconColor}
                  icon={"chevron-left"}
                  size={20}
                  onClick={() => {
                    const currentIndex = locations.findIndex(
                      (location) => location.locationId === selectedLocation
                    );
                    if (currentIndex > 0) {
                      const previousLocation = locations[currentIndex - 1];
                      setSelectedLocation(previousLocation.locationId);
                      setSelectedCurrentLocation(previousLocation);
                    }
                  }}
                />
                <Dropdown
                  className="location-name-dropdown"
                  options={
                    locations &&
                    locations.map((location) => ({
                      label: location?.locationName,
                      value: location?.locationId,
                    }))
                  }
                  value={selectedLocation && selectedLocation}
                  onChange={(e) => {
                    setSelectedLocation(e.value);
                    const selectedLocationObj = locations.find(
                      (location) => location.locationId === e.value
                    );
                    setSelectedCurrentLocation(selectedLocationObj);
                  }}
                  loading={isLoading}
                />
                <Icon
                  className={`right-icon-arrow${
                    locations.findIndex(
                      (location) => location.locationId === selectedLocation
                    ) ===
                    locations.length - 1
                      ? "-disabled"
                      : ""
                  }`}
                  color={currentTheme.tableIconColor}
                  icon={"chevron-right"}
                  size={20}
                  onClick={() => {
                    const currentIndex = locations.findIndex(
                      (location) => location.locationId === selectedLocation
                    );
                    if (currentIndex < locations.length - 1) {
                      const nextLocation = locations[currentIndex + 1];
                      setSelectedLocation(nextLocation.locationId);
                      setSelectedCurrentLocation(nextLocation);
                    }
                  }}
                />
              </div>
              <img
                src={images[selectedImage]}
                alt="Location Image"
                className="location-image"
              />
              <div className="paginator">
                <Button
                  className="paginator-icon"
                  link
                  icon={<Icon color={""} size={20} icon="chevron-left" />}
                  onClick={() =>
                    setSelectedImage((prevIndex) =>
                      prevIndex > 0 ? prevIndex - 1 : images.length - 1
                    )
                  }
                />
                <span>
                  <LanguageProvider id="gen.image" /> {selectedImage + 1}{" "}
                  <LanguageProvider id="gen.message.of" /> {images.length}
                </span>
                <Button
                  className="paginator-icon"
                  link
                  icon={<Icon color={""} size={20} icon="chevron-right" />}
                  onClick={() =>
                    setSelectedImage((prevIndex) =>
                      prevIndex < images.length - 1 ? prevIndex + 1 : 0
                    )
                  }
                />
              </div>
            </div>
            <div className="barcode-thumbnail-session">
              <label className="barcode-image-thumbnail-title">
                <LanguageProvider id="gen.images" />
              </label>
              <div className="thumbnail-gallery">
                {images.map((imageUrl, index) => (
                  <img
                    key={index}
                    src={imageUrl}
                    alt={`Thumbnail ${index + 1}`}
                    className={
                      selectedImage === index
                        ? "thumbnail selected"
                        : "thumbnail"
                    }
                    onClick={() => selectImage(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

function ItensTable() {
  const [inventoryItemCountResult, setInventoryItemCountResult] = useState([]);

  const [itens, setItens] = useState([
    { id: "GS30327581", quantity: 1, color: "#EE46BC" },
    { id: "GS30327582", quantity: 2, color: "#03CEB7" },
    { id: "GS30327583", quantity: 3, color: "#FFCF0F" },
  ]);

  return (
    <>
      <div className="itens-table">
        <DataTable value={itens}>
          <Column field="id" header="SKU" />
          <Column field="quantity" header="Qtd" />
        </DataTable>
      </div>
    </>
  );
}
