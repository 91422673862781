import React from "react";
import * as aop from "~/pages/shared-modules/occupation-map/occupation-map";

export function OccupationMap() {
  return (
    <div className="occupation-map" style={{ margin: "20px", height: "100%" }}>
      <aop.OccupationMap />
    </div>
  );
}
