import { useClickOutside, useResizeListener } from "primereact/hooks";
import "../../scss/add-item-button-popup.scss";

import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "~/app";
import DashboardDialogCreate from "~/pages/admin/dashboards/components/dashboard-dialog-create";
import DatalakeDialogCreate from "~/pages/admin/dashboards/components/datalake-dialog-create";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";

export function AddItemButtonPopup() {
  const { currentTheme } = useContext(ThemeContext);
  const [displayNewItensPopup, setDisplayNewItensPopup] = useState(false);
  const [newDatalakeDialogVisible, setNewDatalakeDialogVisible] =
    useState(false);
  const [newDashboardDialogVisible, setNewDashboardDialogVisible] =
    useState(false);
  const [screendDimensions, setScreendDimensions] = useState({
    width: 0,
    height: 0,
  });

  const overlayRef = useRef(null);
  const addButtonRef = useRef(null);

  const [bindWindowResizeListener, unbindWindowResizeListener] =
    useResizeListener({
      listener: (event) => {
        setScreendDimensions({
          width: event.currentTarget.innerWidth,
          height: event.currentTarget.innerHeight,
        });
      },
    });

  useClickOutside(overlayRef, () => {
    setDisplayNewItensPopup(false);
  });

  useClickOutside(overlayRef, () => {
    setDisplayNewItensPopup(false);
  });

  function toggleDisplayNewItens(value) {
    setDisplayNewItensPopup(value);
  }

  function toggleNewDatalakeDialogVisible(value) {
    setNewDatalakeDialogVisible(value);
  }
  function toggleNewDashboardDialogVisible(value) {
    setNewDashboardDialogVisible(value);
  }

  useEffect(() => {
    setScreendDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    setNewDatalakeDialogVisible(false);
  }, []);

  useEffect(() => {
    toggleDisplayNewItens(false);
  }, [screendDimensions]);

  return (
    <>
      <div className="add-item-popup-container">
        <button
          ref={addButtonRef}
          className="btn-new-dashboard"
          settings
          onClick={(e) => {
            e.stopPropagation();
            toggleDisplayNewItens(true);
          }}
        >
          <Icon
            icon={"plus"}
            size={16}
            color={currentTheme.databaseCardIconColor}
          />
        </button>
        {displayNewItensPopup && (
          <div
            ref={overlayRef}
            className="new-dashboard-pop-up"
            style={{
              top:
                addButtonRef.current.offsetTop +
                addButtonRef.current.offsetHeight,
              left: addButtonRef.current.offsetLeft,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="new-dashboard-pop-up-title">
              <LanguageProvider id={"dcp.side.menu.create.new"} />
            </div>
            <div
              className="new-dashboard-pop-up-action"
              onClick={() => {
                setNewDashboardDialogVisible(true);
              }}
            >
              <div className="icon-container dashboards">
                <Icon icon={"pie-chart-05"} color={"#FF8B62"} />
              </div>
              <LanguageProvider id={"dcp.side.menu.dashboard"} />
            </div>
            <div
              className="new-dashboard-pop-up-action"
              onClick={() => {
                setNewDatalakeDialogVisible(true);
                setDisplayNewItensPopup(false);
              }}
            >
              <div className="icon-container databases">
                <Icon icon={"database-03"} color={"#7893FF"} />
              </div>
              <LanguageProvider id={"dcp.side.menu.create.new.datalake"} />
            </div>
          </div>
        )}
        {newDatalakeDialogVisible && (
          <DatalakeDialogCreate
            onClose={() => {
              toggleNewDatalakeDialogVisible(false);
            }}
          />
        )}
        {newDashboardDialogVisible && (
          <DashboardDialogCreate
            onClose={() => {
              toggleNewDashboardDialogVisible(false);
            }}
          />
        )}
      </div>
    </>
  );
}
