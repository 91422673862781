import React from "react";
import DashboardGraphs from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";

const HomePrint = () => {
  return (
    <div className="home">
      <DashboardGraphs previewMode={true} isMain={true} />
    </div>
  );
};

export default HomePrint;
