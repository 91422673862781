import "./theme/app.scss";

import { createContext, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MenuItemsContextProvider } from "./context/MenuItemsContext";
import { ToastContextProvider } from "./context/ToastContext";
import NavProvider from "./nav-provider";
import { DcpAxiosProvider } from "./services/axios/dcp-axios-service";
import { getFromStorage } from "./services/storage/storage-access";
import "./shared/scss/database-details.scss";

export const UserContext = createContext();
export const ThemeContext = createContext();

function App() {
  let loggedIn = false;
  let authentication = getFromStorage("authentication");
  if (authentication !== undefined) {
    loggedIn = true;
  }

  const [user, setUser] = useState({ loggedIn: loggedIn, userName: "" });

  useEffect(() => {
    setUser(getFromStorage("authentication"));
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <DcpAxiosProvider>
        <ToastContainer icon={false} />
        <ToastContextProvider>
          <MenuItemsContextProvider>
            <NavProvider />
          </MenuItemsContextProvider>
        </ToastContextProvider>
      </DcpAxiosProvider>
    </UserContext.Provider>
  );
}

export default App;
