import { GenericCounter } from "../../custom-charts-components/generic-counter";
import LanguageProvider from "~/shared/components/language-provider";
import "./AndonLastIntegrationOrder.scss";

export default function AndonLastIntegrationOrderNumber() {
  const data = "#2938";

  return (
    <div className="andon-last-integration-order">
      <GenericCounter
        title={
          <LanguageProvider id="dashboard.andon.andon.last.integration.order.number" />
        }
        value={data}
      />
    </div>
  );
}
