import "../scss/create-group.scss";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useRef, useState } from "react";

import { Button } from "~/shared/components/dcp-button";
import DeleteConfirmationOverlay from "~/shared/components/confirmation-popup";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import { InputContainer } from "~/shared/components/dcp-form";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import LanguageProvider from "~shared/components/language-provider";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { RackModel } from "~/shared/components/rack-model";
import { Tooltip } from "primereact/tooltip";
import { classNames } from "primereact/utils";
import rackHorizontal from "~/theme/media/assets/rack-horizontal-base.svg";
import rackVertical from "~/theme/media/assets/rack-vertical-base.svg";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";

export const CreateGroup = ({ area, group, onCancel }) => {
  const [loading, setLoading] = useState();
  const [saving, setSaving] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [racks, setRacks] = useState([]);
  const [newGroup, setNewGroup] = useState(true);
  const [filteredRack, setFilteredRacks] = useState([]);
  const [groupRackTypes, setGroupRackTypes] = useState([]);
  const [areaGroupRacks, setAreaGroupRacks] = useState([]);
  const [selectedGroupRack, setSelectedGroupRack] = useState();
  const [groupDimentions, setGroupDimentions] = useState({ x: 0, y: 0 });
  const [pendingRackDelete, setPendingRackDelete] = useState([]);
  const dcpAxiosService = useDcpAxiosService();

  const op = useRef(null);

  const loadRacks = async () => {
    setLoading(true);
    const { data, status } = await dcpAxiosService.get(
      `${settings.Urls.Rest.Layout}/list-racks`,
      "Platform"
    );

    if (status === 200) {
      setRacks(data.data);
      setFilteredRacks(data.data);

      // if is editing
      if (group) {
        const _rackTypes = group.groupRacks.map((gr) => {
          const type = data.data.find((r) => r.id === gr.rackType.id);
          if (type) {
            return {
              ...type,
              shelves: type.shelves.toReversed(),
              _groupRackId: gr.rack.id,
            };
          }
        });

        formik.setValues(group.areaGroup);
        setGroupRackTypes(_rackTypes);
        setAreaGroupRacks(group.groupRacks);
      }
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      idArea: area.id,
      name: "",
      orientation: "horizontal",
      horizontalNaming: "1n",
      verticalNaming: "az",
      horizontalNamingStart: "",
      verticalNamingStart: "",
      customNaming: false,
    },
  });

  const onSaveGroup = async () => {
    try {
      setSaving(true);
      await deleteRackGroups();
      const { data, status } = await dcpAxiosService.post(
        `${settings.Urls.Rest.AreaGroup}/create`,
        { areaGroup: { ...formik.values }, groupRacks: areaGroupRacks },
        "Platform"
      );
      if (status === 200) {
        onCancel();
      }
      setSaving(false);
    } catch (error) {
      console.error(error);
    }
  };

  const duplicateGroup = async () => {
    try {
      try {
        await deleteRackGroups();
        const { data, status } = await dcpAxiosService.post(
          `${settings.Urls.Rest.AreaGroup}/duplicate`,
          { params: { areaGroupId: formik.values.id } },
          "Platform"
        );
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isFormFieldInvalid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const namingOrientationTemplate = (e) => {
    if (e) {
      switch (e) {
        case "az":
          return "A-Z";
        case "za":
          return "Z-A";
        case "1n":
          return "1-N";
        case "n1":
          return "N-1";
        default:
          return "";
      }
    }
    return "";
  };

  const filterRackList = (search) => {
    setSearchValue(search);
    if (search.length > 0) {
      const _racks = racks.filter((rack) =>
        rack.name.toUpperCase().includes(search.toUpperCase())
      );
      setFilteredRacks(_racks);
    } else {
      setFilteredRacks(racks);
    }
  };

  const removeRack = async () => {
    const groupRackId = areaGroupRacks[selectedGroupRack].rack.id;

    if (groupRackId !== 0)
      setPendingRackDelete([...pendingRackDelete, groupRackId]);

    const _rackTypes = [...groupRackTypes];
    _rackTypes.splice(selectedGroupRack, 1);
    setGroupRackTypes([..._rackTypes]);
    op.current.hide();
  };

  const deleteRackGroups = async () => {
    try {
      for (let i = 0; i < pendingRackDelete.length; i++) {
        const { response } = await dcpAxiosService.delete(
          settings.Urls.Rest.AreaGroup + "/delete-group-rack",
          "Platform",
          { params: { groupRackId: pendingRackDelete[i] } }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onAddRackToGroup = async (rack) => {
    try {
      let reversedShelvesRack = { ...rack };
      reversedShelvesRack.shelves = reversedShelvesRack.shelves.toReversed();
      reversedShelvesRack._groupRackId = 0;
      setGroupRackTypes([...groupRackTypes, reversedShelvesRack]);
    } catch (error) {
      console.error(error);
    }
  };

  const updateGroupDimentions = (_groupRacks) => {
    let x = 0;
    let y = 0;

    if (_groupRacks.length > 0) {
      for (const gr of _groupRacks) {
        x += parseInt(gr.rack.rack.width);
        if (gr.rack.rack.height > y) y = gr.rack.rack.height;
      }
    }

    setGroupDimentions({ x, y });
  };

  /**
   * Convert the racks array to fit the formik groupRacks prop.
   * @param {*} racks racks array
   */
  const formatLocationNaming = (racks) => {
    const formatLocations = (rack) => {
      let locations = [];
      const shelvesQtd = rack.shelves.length;
      for (let i = 0; i < shelvesQtd; i++) {
        let shelf = rack.shelves[i];

        for (let j = 0; j < shelf.locations.length; j++) {
          const _location = shelf.locations[j];

          locations.push({
            shelfLevel: i + 1,
            sequenceLocation: j + 1,
            name: _location.name,
            color: rack.color,
            height: shelf.height,
          });
        }
      }

      return locations;
    };

    let _groupRacks = [];
    racks.forEach((rack, index) => {
      const _groupRack = {
        rack: {
          id: rack._groupRackId,
          sequenceRack: index + 1,
          idRack: rack.id,
          idAreaGroup: formik.values.id,
          rack,
        },
        rackLocations: formatLocations(rack),
      };

      _groupRacks.push({ ..._groupRack });
    });

    updateGroupDimentions(_groupRacks);
    setAreaGroupRacks(_groupRacks);
  };

  /**
   * Update the name of all racks on group.
   * @param {*} groupRacks (optional) racks array
   */
  const setLocationNaming = () => {
    const groupName = formik.values.name;
    const verticalRule = formik.values.verticalNaming;
    const horizontalRule = formik.values.horizontalNaming;
    const horizontalStartingCharacter = formik.values.horizontalNamingStart;
    const verticalStartingCharacter = formik.values.verticalNamingStart;

    let racks = [...groupRackTypes];

    let startCharacterHorizontal;
    let startCharacterVertical;
    let newNamedRacks = [];
    racks.map((rack, rackIndex) => {
      let newRack = {
        ...rack,
        shelves: [],
      };
      let rackName = "";
      switch (horizontalRule) {
        case "az":
          if (horizontalStartingCharacter) {
            startCharacterHorizontal =
              horizontalStartingCharacter.charCodeAt(0);
          } else {
            startCharacterHorizontal = 65;
          }
          rackName = String.fromCharCode(startCharacterHorizontal + rackIndex);
          break;
        case "za":
          if (horizontalStartingCharacter) {
            startCharacterHorizontal =
              horizontalStartingCharacter.charCodeAt(0);
          } else {
            startCharacterHorizontal = 90;
          }
          rackName = String.fromCharCode(90 - rackIndex);
          break;
        case "1n":
          if (horizontalStartingCharacter) {
            startCharacterHorizontal = parseInt(horizontalStartingCharacter);
            rackName = `${startCharacterHorizontal + rackIndex}`;
          } else {
            rackName = `${rackIndex + 1}`;
          }
          break;
        case "n1":
          if (horizontalStartingCharacter) {
            startCharacterHorizontal = parseInt(horizontalStartingCharacter);
            rackName = `${startCharacterHorizontal - rackIndex}`;
          } else {
            rackName = `${racks.length - rackIndex}`;
          }
          break;
      }
      rack.shelves.map((shelf, shelfIndex) => {
        let newShelf = {
          ...shelf,
          locations: [],
        };
        let shelfName = "";
        switch (verticalRule) {
          case "az":
            if (verticalStartingCharacter) {
              startCharacterVertical =
                verticalStartingCharacter.charCodeAt(0) - 1;
            } else {
              startCharacterVertical = 64;
            }
            let azShelfInitialCharacterASC =
              startCharacterVertical + rack.shelves.length;
            shelfName = String.fromCharCode(
              azShelfInitialCharacterASC - shelfIndex
            );
            break;
          case "za":
            if (verticalStartingCharacter) {
              startCharacterVertical =
                verticalStartingCharacter.charCodeAt(0) + 1;
            } else {
              startCharacterVertical = 91;
            }
            let zaShelfInitialCharacterASC =
              startCharacterVertical - rack.shelves.length;
            shelfName = String.fromCharCode(
              zaShelfInitialCharacterASC + shelfIndex
            );
            break;
          case "1n":
            if (verticalStartingCharacter) {
              startCharacterVertical = parseInt(verticalStartingCharacter);
              shelfName = `${
                startCharacterVertical - shelfIndex + rack.shelves.length - 1
              }`;
            } else {
              let nShelfInitialNumber = rack.shelves.length;
              shelfName = `${nShelfInitialNumber - shelfIndex}`;
            }
            break;
          case "n1":
            if (verticalStartingCharacter) {
              startCharacterVertical = parseInt(verticalStartingCharacter);
              shelfName = `${
                startCharacterVertical + shelfIndex - rack.shelves.length + 1
              }`;
            } else {
              shelfName = `${shelfIndex + 1}`;
            }
            break;
        }

        for (let index = 0; index < shelf.locationsAmount; index++) {
          const locationName = `${groupName}-${rackName}-${shelfName}${
            index + 1
          }`;

          newShelf.locations[index] = {
            name: locationName,
          };

          if (formik.values.customNaming) {
            areaGroupRacks.forEach((groupRack, groupIndex) => {
              if (rackIndex == groupIndex) {
                groupRack.rackLocations.forEach((location) => {
                  if (
                    location.shelfLevel == shelfIndex + 1 &&
                    location.sequenceLocation == index + 1
                  ) {
                    newShelf.locations[index] = {
                      name: location.name,
                    };
                  }
                });
              }
            });
          }
        }
        newRack.shelves.push(newShelf);
      });
      newNamedRacks.push(newRack);
    });

    formatLocationNaming(newNamedRacks);
  };

  const updateLocationName = (e, sequenceRack, location) => {
    let _areaGroupsRacks = [...areaGroupRacks];

    _areaGroupsRacks.forEach((_areaGroup, groupIndex) => {
      if (groupIndex + 1 == sequenceRack) {
        _areaGroup.rackLocations.forEach((_location) => {
          if (
            _location.shelfLevel === location.shelfLevel &&
            _location.sequenceLocation == location.sequenceLocation
          ) {
            _location.name = e;
          }
        });
      }
    });

    setAreaGroupRacks(_areaGroupsRacks);
  };

  const reorder = (result) => {
    const { destination, source } = result;

    if (destination.index == source.index) return;

    // update rack types
    const _groupRackTypes = [...groupRackTypes];
    const removedRackType = _groupRackTypes.splice(source.index, 1)[0];
    _groupRackTypes.splice(destination.index, 0, removedRackType);

    // update area groups
    const _areaGroupRacks = [...areaGroupRacks];
    const removedGroupRack = _areaGroupRacks.splice(source.index, 1)[0];
    _areaGroupRacks.splice(destination.index, 0, removedGroupRack);

    // update rack sequence
    _areaGroupRacks.forEach((groupRack, index) => {
      groupRack.rack.sequenceRack = index + 1;
    });

    setAreaGroupRacks(_areaGroupRacks);
    setGroupRackTypes(_groupRackTypes);
  };

  useEffect(() => {
    loadRacks();
  }, [group]);

  useEffect(() => {
    setLocationNaming();
  }, [formik.values, groupRackTypes]);

  return (
    <>
      <div className="create-group">
        <div className="left-column">
          <div className="header">
            <p className="title">
              <LanguageProvider id={"gen.rack.types"} />
            </p>
            <p className="description">
              <LanguageProvider id={"area.map.new.group.description"} />
            </p>
          </div>
          <span className="p-input-icon-left search-input">
            <div className="icon-search">
              <Icon icon={"search-md"} color="#98A2B3" size={20} />
            </div>
            <InputText
              value={searchValue}
              onChange={(e) => {
                filterRackList(e.target.value);
              }}
              placeholder={LanguageProvider({
                id: "search.field.placeholder",
              })}
            />
          </span>
          <div className="rack-list">
            {loading ? (
              <LoadingIndicator size="small" color="var(--primary)" />
            ) : (
              <ul>
                {filteredRack.map((rack) => {
                  return (
                    <li
                      className={`rack rack-${rack.id}`}
                      key={`rack-${rack.id}`}
                      onClick={() => onAddRackToGroup(rack)}
                    >
                      <span className="rack-name" title={rack.name}>
                        {rack.name}
                      </span>
                      <span className="icon pi pi-plus"></span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div className="right-column">
          <div className="form-container">
            <div className="config">
              <div className="title">
                <LanguageProvider id="area.map.rack.config" />
              </div>
              <div className="dcp-form">
                <div className="form-row">
                  <InputContainer
                    label={<LanguageProvider id="area.map.group.name" />}
                  >
                    <InputText
                      placeholder={LanguageProvider({
                        id: "area.map.rack.name.placeholder",
                      })}
                      value={formik.values.name}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                    />
                  </InputContainer>
                </div>
              </div>
            </div>
            <div className="config">
              <div className="title">
                <LanguageProvider id="area.map.location.config" />
              </div>
              <div className="dcp-form">
                <div className="form-row">
                  <InputContainer
                    label={
                      <LanguageProvider id="area.map.rack.vertical.naming" />
                    }
                  >
                    <div className="p-inputgroup">
                      <Dropdown
                        value={formik.values.verticalNaming}
                        options={["az", "za", "1n", "n1"]}
                        itemTemplate={namingOrientationTemplate}
                        valueTemplate={namingOrientationTemplate}
                        onChange={(e) => {
                          formik.setFieldValue("verticalNaming", e.value);
                        }}
                      ></Dropdown>
                      <InputText
                        placeholder={LanguageProvider({
                          id: "area.map.rack.vertical.placeholder",
                        })}
                        value={formik.values.verticalNamingStart}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "verticalNamingStart",
                            e.target.value
                          )
                        }
                      />{" "}
                      <Tooltip
                        target=".p-inputgroup-addon.vertical"
                        position="right"
                      >
                        <img
                          alt="logo"
                          src={rackVertical}
                          style={{ width: "300px", height: "167" }}
                        />
                        <div className="flex align-items-center">
                          <span
                            style={{ minWidth: "226px", fontWeight: "600" }}
                          >
                            Classificar da base esquerda vertical
                          </span>
                          <p style={{ width: "300px", textAlign: "left" }}>
                            Classifique as locações verticais iniciando na base,
                            no sentido base para topo. Este campo é
                            alfanumérico. Utilize Z-A para ordem decrescente das
                            letras.
                          </p>
                        </div>
                      </Tooltip>
                      <span className="p-inputgroup-addon vertical">
                        <i className="pi pi-question-circle"></i>
                      </span>
                    </div>
                  </InputContainer>
                  <InputContainer
                    label={
                      <LanguageProvider id="area.map.rack.horizontal.naming" />
                    }
                  >
                    <div className="p-inputgroup">
                      <Dropdown
                        options={["az", "za", "1n", "n1"]}
                        value={formik.values.horizontalNaming}
                        itemTemplate={namingOrientationTemplate}
                        valueTemplate={namingOrientationTemplate}
                        onChange={(e) => {
                          formik.setFieldValue("horizontalNaming", e.value);
                        }}
                      ></Dropdown>
                      <InputText
                        placeholder={LanguageProvider({
                          id: "area.map.rack.horizontal.placeholder",
                        })}
                        value={formik.values.horizontalNamingStart}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "horizontalNamingStart",
                            e.target.value
                          )
                        }
                      />{" "}
                      <Tooltip
                        target=".p-inputgroup-addon.horizontal"
                        position="right"
                      >
                        <img
                          alt="logo"
                          src={rackHorizontal}
                          style={{ width: "300px", height: "167" }}
                        />
                        <div className="flex align-items-center">
                          <span style={{ width: "226px", fontWeight: "600" }}>
                            Classificar da base esquerda horizontal
                          </span>
                          <p style={{ width: "300px", textAlign: "left" }}>
                            Classifique as locações horizontais iniciando na
                            base, no sentido esquerda para direita. Este campo é
                            alfanumérico. Utilize N-1 para ordem decrescente dos
                            números.
                          </p>
                        </div>
                      </Tooltip>
                      <span className="p-inputgroup-addon horizontal">
                        <i className="pi pi-question-circle"></i>
                      </span>
                    </div>
                  </InputContainer>

                  <InputContainer
                    label={
                      <LanguageProvider id="area.map.rack.manual.config" />
                    }
                  >
                    <InputSwitch
                      checked={formik.values.customNaming}
                      className="manual-config-switch"
                      onChange={(e) =>
                        formik.setFieldValue("customNaming", e.value)
                      }
                    />
                  </InputContainer>
                </div>
              </div>
            </div>
          </div>
          <div className="rack-container">
            <div className="header">
              <span>
                <LanguageProvider id={"rack.front.side"} />
              </span>
              <span>
                <LanguageProvider id={"rack.size"} />:{" "}
                {`X: ${groupDimentions.x}cm Y: ${groupDimentions.y}cm`}
              </span>
            </div>
            <div className="rack-render-area">
              <div className="generated-rack">
                <DragDropContext onDragEnd={reorder}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                        {...provided.droppableProps}
                      >
                        {areaGroupRacks.length > 0 &&
                          areaGroupRacks.map((groupRack, index) => {
                            return (
                              <Draggable
                                draggableId={`draggable-${index}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="wrapper"
                                    key={"rack-model-" + index}
                                  >
                                    <div
                                      className="header"
                                      {...provided.dragHandleProps}
                                    >
                                      <div className="name">
                                        <div
                                          className="color"
                                          style={{
                                            background:
                                              groupRack.rack.rack.color,
                                          }}
                                        />
                                        <span
                                          className="rack-name"
                                          style={{
                                            color: groupRack.rack.rack.color,
                                          }}
                                        >
                                          {groupRack.rack.rack.name}
                                        </span>
                                      </div>
                                      <div
                                        onClick={(e) => {
                                          op.current.toggle(e);
                                          setSelectedGroupRack(index);
                                        }}
                                      >
                                        <Icon
                                          icon="trash-02"
                                          className="icon"
                                        />
                                      </div>
                                    </div>
                                    <RackModel
                                      groupRack={groupRack}
                                      onUpdateName={updateLocationName}
                                      manualConfig={formik.values.customNaming}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
          <div className="buttons-bar">
            <div className="button-container">
              <Button
                label={<LanguageProvider id="area.map.duplicate" />}
                className="p-button-secondary"
                appearance="subtle"
                onClick={duplicateGroup}
              ></Button>
              <Button onClick={onSaveGroup} loading={saving}>
                <LanguageProvider id="gen.save.button" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmationOverlay
        refProp={op}
        onConfirm={() => {
          removeRack();
        }}
        onCancel={(e) => op.current.hide(e)}
      />
    </>
  );
};
