import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import GenericDatabaseHeader from "../components/generic-database-header";
import UseDatabaseProductProcess from "../hooks/database-product-process";

const DispatchDetails = () => {
  const { itemId } = useParams();

  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(settings.DatabaseProcessType.DispatchItem);
  }, [loadDatabaseProductProcess]);

  return (
    <>
      <div className="database-items-details">
        <div className="database-items-details-header">
          <div className="header-container">
            <h1>
              <LanguageProvider id="database.detail.order" />
              {settings.Module.Database.Dispatch}
            </h1>
            <div className="customized-buttons"></div>
          </div>
          <GenericDatabaseHeader itemId={itemId} />
        </div>
      </div>
      {databaseProductProcessData && (
        <DatabaseList
          id={databaseProductProcessData.idDatabase}
          itemId={itemId}
          hasDetails={databaseProductProcessData.hasDetails}
          uriDetails={databaseProductProcessData.uriDetails}
          showIcon={false}
        />
      )}
    </>
  );
};

export default DispatchDetails;
