import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {getReportCountSummary} from "~/services/api"
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import settings from "~/services/settings.json";
import LanguageProvider from "~/shared/components/language-provider";
import "./scss/counts-table.scss";

export function CountsTable() {
  const { idInventory } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dcpAxiosService = useDcpAxiosService();

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getReportCountSummary(idInventory);
     if (Array.isArray(data)) setData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [idInventory]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="counts-table">
      <DataTable loading={loading} value={data}>
        <Column field="status" header={<LanguageProvider id="gen.status" />} />
        <Column field="sku" header={<LanguageProvider id="gen.sku" />} />
        <Column
          field="systemAmount"
          header={<LanguageProvider id="inventory.system.qtd" />}
        />
        <Column
          field="countedAmount"
          header={<LanguageProvider id="inventory.qtd" />}
        />
        <Column
          body={(rowData) =>
            Math.abs(rowData.systemAmount - rowData.countedAmount)
          }
          header={<LanguageProvider id="inventory.difference" />}
        />
        <Column
          field="numberOfLocations"
          header={<LanguageProvider id="area.map.locations" />}
        />
      </DataTable>
    </div>
  );
}
