import React, { MouseEvent, useRef } from "react";
import { Button } from "../dcp-button";
import Icon from "../icons";
import LanguageProvider from "../language-provider";
import "./scss/see-example-button.scss";

interface SeeExempleButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
}

export function SeeExampleButton(props: SeeExempleButtonProps): JSX.Element {
  return (
    <Button
      className="example-btn"
      text
      size="small"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Icon
        icon={"eye"}
        size={20}
        className="example-icon"
        color={"var(--gray-500)"}
      ></Icon>
      <span>
        <LanguageProvider id="gen.see.example.message" />
      </span>
    </Button>
  );
}
