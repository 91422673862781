import { ThemeContext } from "../../app";
import { useContext } from "react";
import "./scss/dcp-loading-indicator.scss";

const LoadingIndicator = ({ color = "#4147FF", size = "medium" }) => {
  const themeContext = useContext(ThemeContext);

  //** Defaults to DPC primary color if theme context is not provided */
  if (!themeContext) {
    color = "#4147FF";
  }

  return (
    <div className="loading-indicator-container">
      <div
        className={`loading-indicator loading-indicator-${size}`}
        style={{ "--spinnerColor": color }}
      />
    </div>
  );
};

export default LoadingIndicator;
