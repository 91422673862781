import { ColorPicker } from "primereact/colorpicker";

export function ColorPickerConfig({ config, updateValue }) {
  return (
    <ColorPicker
      style={{
        border: "2px solid #eaecf5",
        width: "fit-content",
        borderRadius: "10px",
      }}
      value={config.value}
      onChange={(e) => {
        updateValue(e.value);
      }}
    />
  );
}
