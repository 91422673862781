import { useClickOutside, useResizeListener } from "primereact/hooks";
import { useContext, useEffect, useRef, useState } from "react";

import DatalakeDialogCreate from "~/pages/admin/dashboards/components/datalake-dialog-create";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { NavLink } from "react-router-dom";
import settings from "~/services/settings.json";
import DashboardDialogCreate from "~/pages/admin/dashboards/components/dashboard-dialog-create";
import { ThemeContext } from "~/app";

///Renders current project template with add actions
export const DcpNewItemPopupMenuTemplate = (item, options) => {
  const { currentTheme } = useContext(ThemeContext);
  const [displayNewItensPopup, setDisplayNewItensPopup] = useState(false);
  const [newDatalakeDialogVisible, setNewDatalakeDialogVisible] =
    useState(false);
  const [newDashboardDialogVisible, setNewDashboardDialogVisible] =
    useState(false);

  const overlayRef = useRef(null);
  const addButtonRef = useRef(null);

  const [screendDimensions, setScreendDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [bindWindowResizeListener, unbindWindowResizeListener] =
    useResizeListener({
      listener: (event) => {
        setScreendDimensions({
          width: event.currentTarget.innerWidth,
          height: event.currentTarget.innerHeight,
        });
      },
    });

  useClickOutside(overlayRef, () => {
    setDisplayNewItensPopup(false);
  });

  useClickOutside(overlayRef, () => {
    setDisplayNewItensPopup(false);
  });

  function toggleDisplayNewItens(value) {
    setDisplayNewItensPopup(value);
  }

  function toggleNewDatalakeDialogVisible(value) {
    setNewDatalakeDialogVisible(value);
  }
  function toggleNewDashboardDialogVisible(value) {
    setNewDashboardDialogVisible(value);
  }

  useEffect(() => {
    setScreendDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    setNewDatalakeDialogVisible(false);
  }, []);

  useEffect(() => {
    bindWindowResizeListener();

    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);

  useEffect(() => {
    toggleDisplayNewItens(false);
  }, [screendDimensions]);

  return (
    <>
      <div
        className={`dcp-dashboard-nav-item-container ${options.className}`}
        role="menuitem"
        onKeyDown={(e) => {
          if (e.key === " ") e.stopPropagation();
        }}
      >
        <div className="nav-item-area" onClick={options.onClick}>
          {item.items && <span className={options.submenuIconClassName} />}
          <span className={`dcp-menu-item-label ${options.labelClassName}`}>
            {item.label}
          </span>
        </div>
        {/* <button
          ref={addButtonRef}
          className="btn-new-dashboard"
          settings
          onClick={(e) => {
            e.stopPropagation();
            toggleDisplayNewItens(true);
          }}
        >
          <Icon
            icon={"plus"}
            size={16}
            color={currentTheme.databaseCardIconColor}
          />
        </button> */}
      </div>
      {displayNewItensPopup && (
        <div
          ref={overlayRef}
          className="new-dashboard-pop-up"
          style={{
            top:
              addButtonRef.current.offsetTop +
              addButtonRef.current.offsetHeight,
            left: addButtonRef.current.offsetLeft,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="new-dashboard-pop-up-title">
            <LanguageProvider id={"dcp.side.menu.create.new"} />
          </div>
          <div
            className="new-dashboard-pop-up-action"
            onClick={() => {
              setNewDashboardDialogVisible(true);
            }}
          >
            <div className="icon-container dashboards">
              <Icon icon={"pie-chart-05"} color={"#FF8B62"} />
            </div>
            <LanguageProvider id={"dcp.side.menu.dashboard"} />
          </div>
          <div
            className="new-dashboard-pop-up-action"
            onClick={() => {
              setNewDatalakeDialogVisible(true);
              setDisplayNewItensPopup(false);
            }}
          >
            <div className="icon-container databases">
              <Icon icon={"database-03"} color={"#7893FF"} />
            </div>
            <LanguageProvider id={"dcp.side.menu.create.new.datalake"} />
          </div>
        </div>
      )}
      {newDatalakeDialogVisible && (
        <DatalakeDialogCreate
          onClose={() => {
            toggleNewDatalakeDialogVisible(false);
          }}
        />
      )}
      {newDashboardDialogVisible && (
        <DashboardDialogCreate
          onClose={() => {
            toggleNewDashboardDialogVisible(false);
          }}
        />
      )}
    </>
  );
};

///Renders all options inside a menu
export const DcpNavMenuItemTemplate = (item, options) => {
  return (
    <NavLink
      to={item.url}
      className={(isActive, isPending, isTransitioning) =>
        [
          options.className,
          isActive ? "active" : "",
          isPending ? "pending" : "",
          isTransitioning ? "transitioning" : "",
        ].join(" ")
      }
      role="menuitem"
      onClick={options.onClick}
    >
      {item.icon && (
        <Icon className={options.iconClassName} icon={item.icon} size={16} />
      )}

      {item.type === settings.Urls.Rest.Dashboard && (
        <div className="icon-container dashboards">
          <Icon icon={"pie-chart-05"} color={"#FF8B62"} />
        </div>
      )}

      {item.type === settings.DatalakeType.Database && (
        <div className="icon-container databases">
          <Icon icon={"database-03"} color={"#7893FF"} />
        </div>
      )}
      {item.type === settings.DatalakeType.Report && (
        <div className="icon-container reports">
          <Icon icon={"data"} color={"#ff8b62"} />
        </div>
      )}
      <span className={`dcp-menu-item-label ${options.labelClassName}`}>
        {item.label}
      </span>

      {item.items && item.iconPosition === "end" && (
        <Icon
          className={["chevron-right", options.iconClassName].join(" ")}
          icon={"chevron-right"}
          size={16}
        />
      )}
    </NavLink>
  );
};

export default DcpNavMenuItemTemplate;
