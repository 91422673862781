import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import "./scss/dcp-home-product.scss";

const HomeProduct = ({ title, bodyMessage, image, isVisible }) => {
  return (
    <div className="home-product">
      <div className="img-empty">
        <img src={image} alt="Image empty product" />
      </div>
      <div className="body-wrapper">
        <p className="title">Nenhum(a) {title} criado(a)</p>
        <div className="home-product-content">
          <p className="home-product-body">{bodyMessage}</p>

          <div className="button-wrapper">
            <Button
              appearance="primary"
              className="p-button product-btn"
              icon={<Icon icon={"plus"} color="#fff" />}
              iconPos="left"
              onClick={() => {
                isVisible(true);
              }}
            >
              Criar {title}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProduct;
