import { useContext, useEffect, useRef, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import LanguageProvider from "~/shared/components/language-provider";
import { getConfigValue } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import "./InventoryAccuracy.scss";

export default function InventoryAccuracy(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({
    currentValue: 0,
    thresholdValues: [0, 0],
    inventoryName: "",
  });

  const parentRef = useRef(null);

  useEffect(() => {
    if (useMockData) {
      const currentValue = 80;
      const inventoryName = "Inventário exemplo";
      const thresholdValues = [60, 40];
      setData({ currentValue, thresholdValues, inventoryName });
    } else {
      // load data from API
      const inventoryIdConfig = getConfigValue(configs, "inventory-id") || 0;
      const inventoryId = parseInt(inventoryIdConfig);
      setData({ currentValue: 0, thresholdValues: [0, 0], inventoryName: "" });
    }
  }, [useMockData, configs]);

  let mainColor = "var(--red-500)";
  if (data.currentValue >= data.thresholdValues[0])
    mainColor = "var(--green-500)";
  else if (data.currentValue >= data.thresholdValues[1])
    mainColor = "var(--yellow-500)";

  const showThresholdIndicator =
    data.thresholdValues[0] !== 0 && data.thresholdValues[1] !== 0;

  if (!showThresholdIndicator) mainColor = "#03CEB7";

  function percentageInRadians(percentage) {
    return percentage * (Math.PI / 100);
  }

  function getPath(percentage) {
    const gaugeRadius = 107;
    const startingY = 120;
    const startingX = 12;

    const zeroBasedY = gaugeRadius * Math.sin(percentageInRadians(percentage));
    const y = -zeroBasedY + startingY;
    const zeroBasedX = gaugeRadius * Math.cos(percentageInRadians(percentage));
    const x = -zeroBasedX + gaugeRadius + startingX;

    return `M ${startingX} ${startingY}
           A ${gaugeRadius} ${gaugeRadius} 0 0 1 ${x} ${y}
           `;
  }

  function getInnerPath(percentage) {
    const gaugeRadius = 107 - 24;
    const startingY = 120;
    const startingX = 12 + 24;

    const zeroBasedY = gaugeRadius * Math.sin(percentageInRadians(percentage));
    const y = -zeroBasedY + startingY;
    const zeroBasedX = gaugeRadius * Math.cos(percentageInRadians(percentage));
    const x = -zeroBasedX + gaugeRadius + startingX;

    return `M ${startingX} ${startingY}
           A ${gaugeRadius} ${gaugeRadius} 0 0 1 ${x} ${y}
           `;
  }

  return (
    <div className="inventory-accuracy" ref={parentRef}>
      <p className="inventory-title">{data.inventoryName}</p>
      <div className="performance-percentage" ref={parentRef}>
        <div className="label-container">
          <p className="label">
            <LanguageProvider id="inventory.accuracy" />
          </p>
          <p className="percentage">{data.currentValue.toFixed(1)}%</p>
        </div>
        <svg width={240} height={137} xmlns="http://www.w3.org/2000/svg">
          <path
            d="M 12 120
          A 107 107 0 0 1 226 120
          "
            stroke="#F5F5FD"
            stroke-width="24"
            stroke-linecap="round"
            fill="none"
          />
          <path
            d={getPath(data.currentValue)}
            stroke={mainColor}
            stroke-width="24"
            stroke-linecap="round"
            fill="none"
          />
          <g id="svg-graph-meter-value"></g>
        </svg>
        {showThresholdIndicator && (
          <svg width={240} height={137} xmlns="http://www.w3.org/2000/svg">
            <path
              d={getInnerPath(100)}
              stroke="var(--green-500)"
              stroke-width="6"
              fill="none"
              stroke-linecap="round"
            />
            <path
              d={getInnerPath(data.thresholdValues[0])}
              stroke={"var(--yellow-500)"}
              stroke-width="6"
              fill="none"
              stroke-linecap="round"
            />
            <path
              d={getInnerPath(data.thresholdValues[1])}
              stroke={"var(--red-500)"}
              stroke-width="6"
              fill="none"
              stroke-linecap="round"
            />

            <g id="svg-graph-meter-value"></g>
          </svg>
        )}
      </div>
    </div>
  );
}
