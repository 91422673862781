import React from "react";
import { DatabaseFieldFormatter } from ".";

export function TypeConference({ data }: DatabaseFieldFormatter) {
  return (
    <div className="type-conference-wrapper">
      <p className="type-conference-text">{data}</p>
    </div>
  );
}
