import { MultiSelect } from "primereact/multiselect";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import { Button } from "~/shared/components/dcp-button";
import { InputText } from "~/shared/components/dcp-form";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import { CountsTable } from "./counts-table";
import { Location } from "./location";
import { Map } from "./map";
import "./scss/area-counts.scss";
import { listAreaGroups } from "~/services/api/platform/area";
import { getInventoryLocations } from "~/services/api";
import { ThemeContext } from "~/app";

const statusOptions = [
  {
    status: "divergent",
    label: LanguageProvider({ id: "inventory.divergent" }),
  },
  { status: "audited", label: LanguageProvider({ id: "inventory.audited" }) },
  { status: "matches", label: LanguageProvider({ id: "inventory.matches" }) },
];

export function AreaCounts({ inventory }) {
  const { currentTheme } = useContext(ThemeContext);
  // Data
  const [selectedAreaIndex, setSelectedAreaIndex] = useState();
  const [groups, setGroups] = useState(null);
  const [formattedLocations, setFormattedLocations] = useState(null);
  const [locations, setLocations] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState([]);

  // Loading indicators
  const [loadingGroups, setLoadingGroups] = useState(false);

  // Element visibility
  const [fullScreen, setFullScreen] = useState(false);
  const [oppenedLocationPanelId, setOppenedLocationPanelId] = useState(null);
  const [selectedPanel, setSelectedPanel] = useState("list");

  // Calculated values
  const areaAccurracy = useMemo(() => getAreaAccuracy(locations), [locations]);
  const completedStatusRatio = useMemo(
    () => getAreaCompletedStatusRatio(locations),
    [locations]
  );

  function onExceptionClick(location) {
    setSelectedPanel("list");
    setOppenedLocationPanelId(location.id);
  }

  useEffect(() => {
    const loadGroups = async () => {
      try {
        setLoadingGroups(true);
        const area = inventory.areas[selectedAreaIndex];
        if (!area) return;

        const data = await listAreaGroups(area.id);

        if (Array.isArray(data)) {
          setGroups(data);
          await loadLocations(area.id, inventory.id);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingGroups(false);
      }
    };

    const loadLocations = async (areaId, inventoryId) => {
      try {
        const data = await getInventoryLocations(areaId, inventoryId);

        if (Array.isArray(data)) {
          setFormattedLocations(groupByGroupRackName(data));
          setLocations(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadGroups();
  }, [selectedAreaIndex, inventory]);

  useEffect(() => {
    if (inventory.areas && inventory.areas.length > 0) {
      setSelectedAreaIndex(0);
    }
  }, [inventory]);

  return (
    <div
      className={`inventory-details-content ${
        fullScreen ? "fullscreen" : null
      }`}
    >
      <div className="backdrop">
        <div className="header">
          <div className="area-selection">
            <div className="selector">
              <div
                className="icon"
                onClick={() => {
                  if (selectedAreaIndex != null && selectedAreaIndex > 0) {
                    setSelectedAreaIndex(selectedAreaIndex - 1);
                  }
                }}
              >
                <Icon
                  icon="chevron-left"
                  size={24}
                  color={
                    selectedAreaIndex != null && selectedAreaIndex > 0
                      ? "#344054"
                      : "#D0D5DD"
                  }
                />
              </div>
              <span className="name">
                {selectedAreaIndex != null &&
                inventory.areas &&
                inventory.areas.length > 0
                  ? inventory.areas[selectedAreaIndex].name
                  : null}
              </span>
              <div
                className="icon"
                onClick={() => {
                  if (
                    selectedAreaIndex != null &&
                    selectedAreaIndex < inventory.areas.length - 1
                  ) {
                    setSelectedAreaIndex(selectedAreaIndex + 1);
                  }
                }}
              >
                <Icon
                  icon="chevron-right"
                  size={24}
                  color={
                    selectedAreaIndex != null &&
                    selectedAreaIndex < inventory.areas.length - 1
                      ? "#344054"
                      : "#D0D5DD"
                  }
                />
              </div>
            </div>
            <div className="icon" onClick={() => setFullScreen(!fullScreen)}>
              <Icon
                icon={fullScreen ? "minimize-01" : "maximize-01"}
                size={18}
                color={currentTheme.iconDefaultColor}
              />
            </div>
          </div>
          <div className="search">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder={LanguageProvider({
                  id: "search.field.placeholder",
                })}
              />
            </span>
            <MultiSelect
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.value)}
              options={statusOptions}
              optionLabel="label"
              display="chip"
            />
            <div className="locations">
              <span className="title">
                <LanguageProvider id="area.map.locations" />:{" "}
              </span>
              <span className="value">{completedStatusRatio}</span>
            </div>
            <div className="accuracy">
              <span className="title">
                <LanguageProvider id="inventory.accuracy" />:{" "}
              </span>
              <span className="value">{areaAccurracy}%</span>
            </div>
            <div className="buttons">
              <Button
                className="p-button-text"
                onClick={() => setSelectedPanel("list")}
              >
                <Icon icon="list" color="#4146FF" size={20} />
              </Button>
              <Button
                className="p-button-text"
                onClick={() => setSelectedPanel("map")}
              >
                <Icon icon="map-01" color="#4146FF" size={20} />
              </Button>
              <Button
                className="p-button-text"
                onClick={() => setSelectedPanel("table")}
              >
                <Icon icon="table" color="#4146FF" size={20} />
              </Button>
            </div>
          </div>
        </div>
        {loadingGroups && <LoadingIndicator />}

        {!loadingGroups && selectedPanel === "list" && (
          <div className="main-content">
            {formattedLocations &&
              Object.keys(formattedLocations).map((key, index) => {
                const _locations = formattedLocations[key];

                return (
                  <div key={"isle-" + index} className="aisle">
                    <p className="label">{"Rua " + key}</p>
                    {_locations.map((location, index) => (
                      <Location
                        location={location}
                        key={"location-panel-" + index}
                        oppened={oppenedLocationPanelId === location.id}
                      />
                    ))}
                  </div>
                );
              })}
          </div>
        )}

        {!loadingGroups && selectedPanel === "map" && (
          <Map
            area={inventory.areas[selectedAreaIndex]}
            groups={groups}
            exceptions={getLocationsWithDivergency(locations)}
            onExceptionClick={onExceptionClick}
          />
        )}

        {!loadingGroups && selectedPanel === "table" && <CountsTable />}
      </div>
    </div>
  );
}

function groupByGroupRackName(array) {
  return array.reduce((grouped, item) => {
    const name = item.areaGroupName;
    if (!grouped[name]) {
      grouped[name] = [];
    }
    grouped[name].push(item);
    return grouped;
  }, {});
}

function getAreaAccuracy(locations) {
  try {
    if (!locations) return 0;

    const locationsTotal = locations.reduce((acc, location) => {
      return acc + location.accuracyPercent;
    }, 0);

    return locationsTotal > 0
      ? Math.round(locationsTotal / locations.length)
      : 0;
  } catch (error) {
    console.error(error);
  }
}

function getLocationsWithDivergency(locations) {
  try {
    if (!locations) return [];
    return locations.filter((location) => location.locationStatus !== 1);
  } catch (error) {
    console.error(error);
  }
}

function getAreaCompletedStatusRatio(locations) {
  try {
    if (!locations) return;

    const locationsCompleted = locations.reduce((acc, location) => {
      if (location.locationStatus === 1) return acc + 1;
      return acc;
    }, 0);

    return `${locationsCompleted}/${locations.length}`;
  } catch (error) {
    console.error(error);
  }
}
