import { Column } from "primereact/column";
import "./scss/template-list.scss";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "~/context/ToastContext";
import {
  createTemplate,
  DeleteTemplate,
  listTemplates,
} from "~/services/api/platform/template";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import PageHeader from "~/shared/components/page-header/page-header";
import { ThemeContext } from "~/app";

export function TemplateList() {
  const { currentTheme } = useContext(ThemeContext);
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentTemplate, setCurrentTemplate] = useState();
  //Modal dialog states
  const [modalConfirmationIsOpen, setModalConfirmationIsOpen] = useState(false);
  const [modalIsDelete, setModalIsDelete] = useState(false);
  const [modalConfirmationTitle, setModalConfirmationTitle] = useState();
  const [modalConfirmationBodyMessage, setModalConfirmationBodyMessage] =
    useState("");

  async function loadTemplates() {
    try {
      setLoading(true);
      const response = await listTemplates();

      if (response?.data?.data?.length > 0) {
        setTemplates(response.data.data);
      } else {
        showToast({
          severity: "error",
          message: LanguageProvider({ id: response.data.message }),
        });
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id="unhandled.error" />,
      });
    }
    setLoading(false);
  }

  async function onEdit(id: number) {
    try {
      navigate("edit/" + id);
    } catch (error) {
      console.error(error);
    }
  }

  async function onCreate() {
    try {
      navigate("edit/0");
    } catch (error) {
      console.error(error);
    }
  }

  function headerActions() {
    return (
      <div className="header-actions">
        <Button onClick={onCreate}>
          <LanguageProvider id="gen.template.create" />
        </Button>
      </div>
    );
  }

  const confirmResponse = async (confirmed) => {
    if (confirmed) {
      if (modalIsDelete) {
        const status = await DeleteTemplate(selectedTemplateId);
        if (status) {
          showToast({
            severity: "success",
            message: LanguageProvider({
              id: "gen.message.template.deleted",
            }),
          });
        } else {
          showToast({
            severity: "error when deleting template.",
          });
        }
      } else {
        try {
          const responseDuplicateTemplate = await createTemplate(
            currentTemplate
          );
          if (responseDuplicateTemplate.data.success) {
            showToast({
              severity: "success",
              message: (
                <LanguageProvider
                  id={"gen.message.template.duplicated.success"}
                />
              ),
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
      CloseModalConfirmationUser();
      loadTemplates();
    }
  };

  const CloseModalConfirmationUser = () => {
    setModalConfirmationIsOpen(false);
  };

  const OpenModalConfirmationDuplicate = () => {
    setModalIsDelete(false);
    setModalConfirmationTitle(
      LanguageProvider({ id: "gen.confirm.template.duplicate" })
    );
    setModalConfirmationBodyMessage(
      LanguageProvider({ id: "gen.message.template.duplicate" })
    );
    setModalConfirmationIsOpen(true);
  };

  const OpenModalConfirmationDelete = () => {
    setModalIsDelete(true);
    setModalConfirmationTitle(
      LanguageProvider({ id: "gen.confirm.template.delete" })
    );
    setModalConfirmationBodyMessage(
      LanguageProvider({ id: "gen.message.template.delete" })
    );
    setModalConfirmationIsOpen(true);
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  return (
    <div className="template-list-container">
      <div className="table-wrapper">
        <PageHeader
          titleTemplate={undefined}
          recordsCount={templates.length}
          onReturn={undefined}
          actions={headerActions}
          title={LanguageProvider({ id: "gen.template" })}
        ></PageHeader>
        <div className="datatable-container">
          <DataTable
            value={templates}
            loading={loading}
            paginator
            rows={10}
            scrollable
            scrollHeight="flex"
            rowsPerPageOptions={[5, 10, 25, 50]}
            emptyMessage={<LanguageProvider id="gen.no.registers" />}
          >
            <Column field="code" header={<LanguageProvider id="gen.code" />} />
            <Column field="name" header={<LanguageProvider id="gen.name" />} />
            <Column
              header={<LanguageProvider id="gen.status" />}
              body={(rowData) => {
                return (
                  <div className="status-cell">
                    {rowData.isActive ? (
                      <span
                        className="status-identification active"
                        title="Ativo"
                      >
                        <Icon
                          icon={"dot"}
                          size={8}
                          color={currentTheme.tableStatusIconActiveColor}
                        />
                        {<LanguageProvider id="gen.active" />}
                      </span>
                    ) : (
                      <span
                        className="status-identification inactive"
                        title="Inativo"
                      >
                        <Icon
                          icon={"dot"}
                          size={8}
                          color={currentTheme.tableStatusIconInactiveColor}
                        />
                        {<LanguageProvider id="gen.inactive" />}
                      </span>
                    )}
                  </div>
                );
              }}
            />
            <Column
              field="createdBy"
              header={<LanguageProvider id="gen.create.by" />}
            />
            <Column
              header={<LanguageProvider id="gen.create.at" />}
              body={(rowData) => {
                return new Date(rowData.createdAt).toLocaleString();
              }}
            />
            <Column
              field="updatedBy"
              header={<LanguageProvider id="gen.modified.by" />}
            />
            <Column
              header={<LanguageProvider id="gen.modified.at" />}
              body={(rowData) => {
                return new Date(rowData.updatedAt).toLocaleString();
              }}
            />
            <Column
              headerStyle={{ width: "10%", minWidth: "100px" }}
              body={(rowData) => {
                return (
                  <div className="column-wrapper">
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => onEdit(rowData.id)}
                      >
                        <Icon
                          icon="edit-02"
                          size={20}
                          color="#667085"
                          className="icon-edit"
                        />
                      </div>
                    </div>
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => {
                          setSelectedTemplateId(rowData.id);
                          setCurrentTemplate(rowData);
                          OpenModalConfirmationDuplicate();
                        }}
                      >
                        <Icon
                          icon="copy-04"
                          size={20}
                          color="#667085"
                          className="icon-duplicate"
                        />
                      </div>
                    </div>
                    <div className="column-actions">
                      <div
                        className="icon-wrapper"
                        onClick={() => {
                          setSelectedTemplateId(rowData.id);
                          OpenModalConfirmationDelete();
                        }}
                      >
                        <Icon
                          icon="trash-02"
                          size={20}
                          color="#667085"
                          className="icon-delete"
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          </DataTable>
        </div>
      </div>
      <ModalConfirmation
        isOpen={modalConfirmationIsOpen}
        modalTitle={modalConfirmationTitle}
        bodyMessage={modalConfirmationBodyMessage}
        isDelete={modalIsDelete}
        onConfirm={confirmResponse}
        onCancel={CloseModalConfirmationUser}
      />
    </div>
  );
}
