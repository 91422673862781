import LanguageProvider from "~/shared/components/language-provider";
import * as GridItems from "../../../shared-modules/dashboard-graphs/components/grid-item/items";
import { DashboardMenuItemCategory } from "~/pages/shared-modules/dashboard-graphs/components/item-list-dialog/menu-items";

export const AndonTripMenuItems: DashboardMenuItemCategory[] = [
  {
    category: "layout",
    productCode: "platform",
    icon: "layout-grid-01",
    label: LanguageProvider({ id: "dashboard.category.layout" }),
    items: [
      {
        type: "andon-text",
        label: LanguageProvider({ id: "dashboard.item.type.text" }),
        icon: "align-left",
        component: GridItems.AndonText,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
    ],
  },
  {
    category: "andon",
    productCode: "sequence",
    icon: "layout-grid-01",
    label: LanguageProvider({ id: "dashboard.category.andon" }),
    items: [
      {
        type: "waiting-shipping-orders",
        label: LanguageProvider({
          id: "dashboard.item.type.andon.waiting-shipping-orders",
        }),
        icon: "align-left",
        component: GridItems.AndonWaitingShippingOrders,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
      {
        type: "pending-orders",
        label: LanguageProvider({
          id: "dashboard.item.type.andon.pending-orders",
        }),
        icon: "align-left",
        component: GridItems.AndonPendingOrders,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
      {
        type: "open-trips",
        label: LanguageProvider({ id: "dashboard.item.type.andon.open-trips" }),
        icon: "align-left",
        component: GridItems.AndonOpenTrips,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
      {
        type: "last-integration-order",
        label: LanguageProvider({
          id: "dashboard.item.type.andon.last-integration-order",
        }),
        icon: "align-left",
        component: GridItems.AndonLastIntegrationOrder,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
      {
        type: "last-integration-order-number",
        label: LanguageProvider({
          id: "dashboard.item.type.andon.last-integration-order-number",
        }),
        icon: "align-left",
        component: GridItems.AndonLastIntegrationOrderNumber,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
      {
        type: "errors",
        label: LanguageProvider({ id: "dashboard.item.type.andon.errors" }),
        icon: "align-left",
        component: GridItems.AndonErrors,
        description:
          "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
      },
    ],
  },
];
