import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { MapItemModel } from "~/shared/interfaces";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function listMapItems(
  areaId: string | number
): Promise<MapItemModel[]> {
  try {
    const { data } = await axiosService().get(
      settings.Urls.Rest.AreaMap + "/list-map-items",
      "Platform",
      { params: { areaId: areaId } }
    );

    if (Array.isArray(data.data)) return data.data;
    else return [];
  } catch (error) {
    console.error(error);
  }
}
