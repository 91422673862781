function writeStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function removeStorage(key) {
  localStorage.removeItem(key);
}

function getFromStorage(key) {
  try {
    const valueString = localStorage.getItem(key);
    const value = JSON.parse(valueString);
    return value;
  } catch {
    return null;
  }
}

function propertyFrommStorage(key, property) {
  try {
    const valueString = localStorage.getItem(key);
    const value = JSON.parse(valueString);
    if (property) return value[property];
    return value;
  } catch {
    return "";
  }
}

export { writeStorage, removeStorage, getFromStorage, propertyFrommStorage };
