import LanguageProvider from "~/shared/components/language-provider";
import * as GridItems from "../grid-item/items";

export interface DashboardMenuItem {
  type: string;
  label: string;
  icon: string;
  component: React.FC;
  description: string;
}

export interface DashboardMenuItemCategory {
  category: string;
  productCode: string;
  icon: string;
  label: string;
  items: DashboardMenuItem[];
}

export function getMenuItems(reports = []): DashboardMenuItemCategory[] {
  return [
    {
      category: "layout",
      productCode: "platform",
      icon: "layout-grid-01",
      label: LanguageProvider({ id: "dashboard.category.layout" }),
      items: [
        {
          type: "text",
          label: LanguageProvider({ id: "dashboard.item.type.text" }),
          icon: "align-left",
          component: GridItems.Text,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "title",
          label: LanguageProvider({ id: "dashboard.item.type.title" }),
          icon: "type-01",
          component: GridItems.Title,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "platform",
      productCode: "platform",
      icon: "bar-line-chart",
      label: LanguageProvider({ id: "gen.platform" }),
      items: [
        {
          type: "grafana",
          label: LanguageProvider({
            id: "dashboard.item.type.external-url",
          }),
          icon: "menu-01",
          component: GridItems.Grafana,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "inventory",
      productCode: "drone-inventory",
      icon: "bar-line-chart",
      label: LanguageProvider({ id: "gen.inventory" }),
      items: [
        {
          type: "planned-locations",
          label: LanguageProvider({
            id: "dashboard.item.type.planned-locations",
          }),
          icon: "menu-01",
          component: GridItems.PlannedLocations,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "inventory-accuracy",
          label: LanguageProvider({
            id: "dashboard.item.type.inventory-accuracy",
          }),
          icon: "type-01",
          component: GridItems.InventoryAccuracy,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "open-inventory-accuracy",
          label: LanguageProvider({
            id: "dashboard.item.type.open-inventory-accuracy",
          }),
          icon: "type-01",
          component: GridItems.OpenInventoryAccuracy,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "mes",
      productCode: "mes",
      icon: "bar-line-chart",
      label: LanguageProvider({ id: "dashboard.category.mes" }),
      items: [
        {
          type: "libracom-producao-stack-chart",
          label: LanguageProvider({
            id: "dashboard.item.type.libracom-producao-stack-chart",
          }),
          icon: "menu-01",
          component: GridItems.LibracomParadaProducaoStackChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "libracom-producao-bar-chart",
          label: LanguageProvider({
            id: "dashboard.item.type.libracom-producao-bar-chart",
          }),
          icon: "bar-chart-10",
          component: GridItems.LibracomProducaoBarChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "libracom-ensaque-line-chart",
          label: LanguageProvider({
            id: "dashboard.item.type.libracom-ensaque-line-chart",
          }),
          icon: "bar-line-chart",
          component: GridItems.LibracomEnsaqueLineChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "performance-percentage",
          label: LanguageProvider({
            id: "dashboard.item.type.performance-percentage",
          }),
          icon: "speedometer-03",
          component: GridItems.PerformancePercentage,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "availability-horizontal-bar",
          label: LanguageProvider({
            id: "dashboard.item.type.availability-horizontal-bar",
          }),
          icon: "time-quarter-to",
          component: GridItems.AvailabilityHorizontalBar,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "oee-history",
          label: LanguageProvider({ id: "dashboard.item.type.oee-history" }),
          icon: "line-chart-up-02",
          component: GridItems.OEEHistory,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "productivity-horizontal-bar",
          label: LanguageProvider({
            id: "dashboard.item.type.productivity-horizontal-bar",
          }),
          icon: "line-chart-up-02",
          component: GridItems.ProductivityHorizontalBar,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "productivity-hour-bar",
          label: LanguageProvider({
            id: "dashboard.item.type.productivity-hour-bar",
          }),
          icon: "line-chart-up-02",
          component: GridItems.ProductivityHourBar,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "production-count",
          label: LanguageProvider({
            id: "dashboard.item.type.production-count",
          }),
          icon: "number",
          component: GridItems.ProductionCount,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "wms",
      productCode: "wms",
      icon: "bar-line-chart",
      label: LanguageProvider({ id: "dashboard.category.wms" }),
      items: [
        {
          type: "occupation-level",
          label: LanguageProvider({
            id: "dashboard.item.type.occupation-level",
          }),
          icon: "box",
          component: GridItems.OccupationLevel,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "item-picking-hour",
          label: LanguageProvider({
            id: "dashboard.item.type.item-picking-hour",
          }),
          icon: "box",
          component: GridItems.ItemPickingHour,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "process-volume",
          label: LanguageProvider({ id: "dashboard.item.type.process-volume" }),
          icon: "box",
          component: GridItems.ProcessVolume,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "wms-generic-counter",
          label: LanguageProvider({
            id: "dashboard.item.type.generic-counter",
          }),
          icon: "box",
          component: GridItems.GenericCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "user-shipped-items",
          label: LanguageProvider({
            id: "dashboard.item.type.user-shipped-items",
          }),
          icon: "box",
          component: GridItems.UserShippedItems,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "inbound-heat-map",
          label: LanguageProvider({
            id: "dashboard.item.type.inbound-heat-map",
          }),
          icon: "box",
          component: GridItems.InboundHeatMap,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "average-order-time-cycle",
          label: LanguageProvider({
            id: "dashboard.item.type.average-order-time-cycle",
          }),
          icon: "box",
          component: GridItems.AverageOrderTimeCycle,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "sequence",
      productCode: "sequence",
      icon: "bar-line-chart",
      label: LanguageProvider({ id: "product.sequence" }),
      items: [
        {
          type: "dispatch-volume-by-part-number",
          label: LanguageProvider({
            id: "dashboard.item.type.dispatch-volume-by-part-number",
          }),
          icon: "box",
          component: GridItems.DispatchVolumeByPartNumber,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "dispatch-demand",
          label: LanguageProvider({
            id: "dashboard.item.type.dispatch-demand",
          }),
          icon: "box",
          component: GridItems.DispatchDemand,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "total-dispatch-volume",
          label: LanguageProvider({
            id: "dashboard.item.type.total-dispatch-volume",
          }),
          icon: "box",
          component: GridItems.TotalDispatchVolume,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "dispatch-by-user",
          label: LanguageProvider({
            id: "dashboard.item.type.dispatch-by-user",
          }),
          icon: "box",
          component: GridItems.DispatchByUser,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "errors-by-user",
          label: LanguageProvider({
            id: "dashboard.item.type.errors-by-user",
          }),
          icon: "box",
          component: GridItems.ErrorsByUser,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "sequence-generic-counter",
          label: LanguageProvider({
            id: "dashboard.item.type.sequence-generic-counter",
          }),
          icon: "box",
          component: GridItems.SequenceGenericCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "warehouse",
      productCode: "warehouse",
      icon: "bar-line-chart",
      label: LanguageProvider({ id: "product.warehouse" }),
      items: [
        {
          type: "most-consumed-items",
          label: LanguageProvider({
            id: "dashboard.item.type.most-consumed-items",
          }),
          icon: "box",
          component: GridItems.MostConsumedItems,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "finance-counter",
          label: LanguageProvider({
            id: "dashboard.item.type.finance-counter",
          }),
          icon: "box",
          component: GridItems.FinanceCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "warehouse-generic-counter",
          label: LanguageProvider({
            id: "dashboard.item.type.warehouse-generic-counter",
          }),
          icon: "box",
          component: GridItems.WarehouseGenericCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "input-output",
          label: LanguageProvider({
            id: "dashboard.item.type.input-output",
          }),
          icon: "box",
          component: GridItems.InputOutput,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "Area Movement Control",
      productCode: "area-movement-control",
      icon: "bar-line-chart",
      label: LanguageProvider({ id: "product.area.movement.control" }),
      items: [
        {
          type: "amc-generic-counter",
          label: LanguageProvider({
            id: "dashboard.item.type.amc-generic-counter",
          }),
          icon: "box",
          component: GridItems.AmcGenericCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "tasks-by-user",
          label: LanguageProvider({
            id: "dashboard.item.type.tasks-by-user",
          }),
          icon: "box",
          component: GridItems.TasksByUser,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "movement-by-address",
          label: LanguageProvider({
            id: "dashboard.item.type.movement-by-address",
          }),
          icon: "box",
          component: GridItems.MovmentByAddress,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "request-volume",
          label: LanguageProvider({
            id: "dashboard.item.type.request-volume",
          }),
          icon: "box",
          component: GridItems.RequestVolume,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "user-average-task-time",
          label: LanguageProvider({
            id: "dashboard.item.type.user-average-task-time",
          }),
          icon: "box",
          component: GridItems.UserAverageTaskTime,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "reports",
      productCode: "platform",
      icon: "layout-alt-01",
      label: LanguageProvider({ id: "dashboard.category.reports" }),
      items: reports.map((report) => {
        return {
          type: "table",
          label: report.title,
          icon: "layout-alt-01",
          component: GridItems.Table,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        };
      }),
    },
  ];
}
