import "../scss/setting-subgroup.scss";

import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";

import { InputSwitch } from "primereact/inputswitch";
import ColorPickerButton from "~/shared/components/dcp-color-picker-button";
import LanguageProvider from "~/shared/components/language-provider";

export function SettingSubgroup({ subgroup, onChange = () => {} }) {
  const renderSettingField = (setting) => {
    switch (setting.defaultValues.propertyType) {
      case 1:
        return (
          <InputText
            value={setting.value}
            placeholder={setting.defaultValues.value}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "" || !isNaN(value)) {
                onChange(e.target.value, setting);
              }
            }}
          />
        );

      case 2:
        return (
          <InputText
            value={setting.value}
            placeholder={setting.defaultValues.value}
            onChange={(e) => {
              onChange(e.target.value, setting);
            }}
          />
        );

      case 3:
        return (
          <InputSwitch
            trueValue={true}
            falseValue={false}
            checked={
              setting.value === "true" ||
              (setting.value === null && setting.defaultValues === "true")
            }
            onChange={(e) => {
              onChange(e.value.toString(), setting);
            }}
          />
        );
      case 4:
        return (
          <>
            <ColorPickerButton
              color={setting.value}
              onChange={(hexColor) => {
                onChange(hexColor, setting);
              }}
            />
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <div className="subgroup-container">
      <h3>
        <LanguageProvider id={subgroup.name} />
      </h3>
      <p>
        <LanguageProvider id={subgroup.description} />
      </p>
      <div className="settings">
        <Form className="form-subgroup">
          {subgroup.settings &&
            subgroup.settings.map((setting, index) => {
              return (
                <FormRow
                  className="setting"
                  key={`${setting.defaultValues.id}-${index}`}
                >
                  <InputContainer
                    label={
                      <LanguageProvider id={setting.defaultValues.nameView} />
                    }
                  >
                    {renderSettingField(setting)}
                  </InputContainer>
                </FormRow>
              );
            })}
        </Form>
      </div>
    </div>
  );
}
