import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { Button } from "~/shared/components/dcp-button";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import settings from "~/services/settings.json";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "~/context/ToastContext";

const DashboardDialogCreate = ({ onClose }) => {
  const { showToast } = useToastContext();
  const dcpAxiosService = useDcpAxiosService();

  const [dashboards, setDashboards] = useState([]);
  const newDashboardDialogVisible = useState(true);
  const [submiting, setSubmiting] = useState(false);
  const navigate = useNavigate();

  async function loadDashboards() {
    try {
      const dashboardResponse = await dcpAxiosService.get(
        settings.Urls.Rest.Dashboard + "/list",
        "Platform"
      );
      if (dashboardResponse.status === 200 && dashboardResponse.data.status) {
        setDashboards(dashboardResponse.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Dashboard
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validate: (values) => {
      let errors = {};

      if (!values.name || values.name.length < 1) {
        errors.name = LanguageProvider({ id: "gen.message.field.required" });
      }

      if (dashboards.find((dashboard) => dashboard.name === values.name)) {
        errors.name = LanguageProvider({
          id: "dashboard.duplicated.name.error",
        });
      }
      return errors;
    },
    onSubmit: ({ name }) => onDashboardCreate(name),
  });

  const onDashboardCreate = async () => {
    setSubmiting(true);
    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Dashboard,
        {
          name: formik.values.name,
          dashboardAccess: [
            {
              isRole: false,
            },
          ],
        },
        "Platform"
      );

      if (status === 200) {
        loadDashboards();
        navigate(`dashboard-graphs/${data.data.id}`);
        formik.resetForm();
        onClose();
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setSubmiting(false);
  };

  return (
    <Dialog
      visible={newDashboardDialogVisible}
      onHide={onClose}
      headerStyle={{ padding: "20px" }}
      contentStyle={{
        paddingTop: "20px",
        borderBottom: "1px solid var(--systemBackground)",
        borderTop: "1px solid var(--systemBackground)",
      }}
      header={
        <span className="dashboards-header">
          <LanguageProvider id="dashboard.add.new" />
        </span>
      }
      footer={
        <div>
          <Button
            text
            onClick={onClose}
            style={{ marginTop: "20px" }}
            className="p-button p-button-text p-button-plain"
            label={<LanguageProvider id="gen.cancel" />}
          />
          <Button
            label={<LanguageProvider id="dashboard.add.new" />}
            appearance="primary"
            onClick={formik.submitForm}
            loading={submiting}
          />
        </div>
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Form style={{ padding: 0 }}>
        <FormRow>
          <InputContainer label={<LanguageProvider id="gen.name" />}>
            <InputText
              width="700px"
              value={formik.values.name}
              style={{ minWidth: "540px" }}
              onChange={(e) => formik.setFieldValue("name", e.target.value)}
            />
            {formik.touched.name && formik.errors.name ? (
              <small className="p-error">{formik.errors.name}</small>
            ) : (
              <small className="p-error">&nbsp;</small>
            )}
          </InputContainer>
        </FormRow>
      </Form>
    </Dialog>
  );
};

export default DashboardDialogCreate;
