import "../../scss/output-dialog.scss";

import { useFormik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import LanguageProvider from "~/shared/components/language-provider";
import { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

const SetUpOutputRegistrationDialog = ({ visible, onHide, outputSettings }) => {
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const [submitting, setSubmitting] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      showApplicantDetails: outputSettings,
    },
    onSubmit: (values) => onSaveOutputSettings(values),
  });

  const onSaveOutputSettings = async () => {
    setSubmitting(true);

    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Settings + "/update-output-settings",
        {
          showApplicantDetails: formik.values.showApplicantDetails,
        },
        "Warehouse"
      );
      if (status === 200) {
        showToast({
          severity: "success",
          message: LanguageProvider({
            id: "dcp.platform.warehouse.input.entry.data.saved",
          }),
        });
        onHide();
      }
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      className="output-dialog"
      visible={visible}
      onHide={onHide}
      header={() => {
        return (
          <div className="output-dialog-header">
            <span className="output-dialog-header-title">
              <LanguageProvider
                id={"dcp.platform.warehouse.entry.dialog.entry.configure"}
              />
            </span>
          </div>
        );
      }}
      footer={() => {
        return (
          <div className="output-dialog-footer">
            <div className="actions">
              <Button
                onClick={onHide}
                label={<LanguageProvider id={"gen.cancel"} />}
                className="p-button p-button-text p-button-plain"
              />
              <Button
                loading={submitting}
                type="submit"
                className="p-button"
                onClick={formik.submitForm}
                label={<LanguageProvider id={"gen.save.button"} />}
              />
            </div>
          </div>
        );
      }}
    >
      <Form className="output-dialog-content">
        <FormRow>
          <InputContainer
            label={
              <LanguageProvider
                id={"dcp.platform.warehouse.output.dialog.show.details"}
              />
            }
          >
            <InputSwitch
              checked={formik.values.showApplicantDetails}
              onChange={(e) => {
                formik.setFieldValue("showApplicantDetails", e.value);
              }}
              autoFocus
            />
          </InputContainer>
        </FormRow>
      </Form>
    </Dialog>
  );
};

export default SetUpOutputRegistrationDialog;
