import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { defaultChartOptions } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakeData from "../../fake-data/wms/average-order-time-cycle.json";
import { ThemeContext } from "~/app";

export default function AverageOrderTimeCycle() {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (useMockData) {
      setData(fakeData);
    } else {
      // load data from api
      setData([]);
    }
  }, [useMockData]);

  const options = {
    ...defaultChartOptions,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (value) {
            return value + "h";
          },
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
    plugins: {
      ...defaultChartOptions.plugins,
      legend: {
        labels: {
          color: currentTheme.dashboardDataColor,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            if (label) {
              return label + ": " + context.parsed.y + "h";
            }
            return context.parsed.y + "h";
          },
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={data}
      options={options}
      height="100%"
      width="100%"
    />
  );
}
