import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable, DataTableStateEvent } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import DcpAxiosService, {
  useDcpAxiosService,
} from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import SkeletonDatabase from "~/shared/components/SkeletonDatabase";
import { Button } from "~/shared/components/dcp-button";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import PageHeader from "~/shared/components/page-header/page-header";
import "./scss/report-view.scss";
import "jspdf-autotable";
import { getFromStorage } from "~/services/storage/storage-access";

export interface ReportViewProps {
  reportId: string | number;
}

export function ReportView() {
  const axiosService = useDcpAxiosService();
  const { idReport, projectId } = useParams();
  const defaultQtyToExportFile = getFromStorage("defaultQtyToExportFile");

  // Refs
  const columnsOpRef = useRef(null);
  const downloadOptionsOpRef = useRef(null);

  // Data
  const [filterColumns, setFilterColumns] = useState([]);
  const [originalColumnOrder, setOriginalColumnOrder] = useState([]);
  const [globalSearchFilter, setGlobalSearchFilter] = useState("");
  const [report, setReport] = useState({
    title: "",
    dataSource: {
      applicationId: 0,
    },
  });
  const [reportInfoExport, setReportInfoExport] = useState({
    dataSource: {
      applicationId: 0,
    },
  });

  const [reportData, setReportData] = useState({
    reportName: "",
    rows: [],
    totalRows: 0,
    columns: [],
  });
  const [filters, setFilters] = useState<DataTableStateEvent>({
    first: 0,
    rows: 10,
    sortField: null,
    sortOrder: 1,
    multiSortMeta: [],
    filters: {},
    page: 0,
    pageCount: 0,
  });

  // Element Visibility
  const [loadingReport, setLoadingReport] = useState(true);
  const [loadingReportData, setLoadingReportData] = useState(true);

  const loadReport = useCallback(async () => {
    try {
      const reportInfo = await fetchReport(idReport, axiosService);
      setReportInfoExport(reportInfo);

      if (!reportInfo) return;
      return reportInfo;
    } catch (error) {
      console.error(error);
    }
  }, [axiosService, idReport]);

  const loadReportData = useCallback(
    async (
      applicationId: number,
      applyFilters: boolean = true,
      rowsLimit: number | null = null
    ) => {
      let requestData = applyFilters
        ? {
            ...filters,
            filters: [],
          }
        : {};

      if (!applyFilters && rowsLimit !== null) {
        requestData = { ...requestData, rows: rowsLimit };
      }

      const reportData = await fetchReportData(
        idReport,
        applicationId,
        axiosService,
        requestData
      );

      if (!reportData || !reportData.columns) return;
      return reportData;
    },
    [axiosService, filters, idReport]
  );

  function titleTemplate() {
    return loadingReport && report.title.length === 0 ? (
      <div className="title-template">
        <Icon className="icon" icon="data" size={24} color={"#7893FF"} />
        <Skeleton width="180px" height="24px" />
      </div>
    ) : (
      <div className="title-template">
        <Icon className="icon" icon="data" size={24} color={"#7893FF"} />
        <span>{report.title}</span>
      </div>
    );
  }

  function actions() {
    return (
      <div className="actions">
        <div className="options-wrapper">
          <span className="search-input">
            <Icon icon={"search-md"} size={20} color={"#667085"} />
            <InputText
              className="p-inputtext-md"
              placeholder={LanguageProvider({
                id: "search.field.placeholder",
              })}
              value={globalSearchFilter}
              onChange={(e) => setGlobalSearchFilter(e.target.value)}
            />
          </span>
          <div className="icon-options">
            <Tooltip target=".btn-column" position="top">
              <span>
                <LanguageProvider id="datatable.list.hide.columns" />
              </span>
            </Tooltip>
            <Button
              text
              className="p-button p-button-text btn-column"
              onClick={(e) => columnsOpRef.current.toggle(e)}
            >
              <Icon icon="columns-04" color={"#717BBC"} size={undefined} />
            </Button>
            <Tooltip target=".btn-edit" position="top">
              <span>
                <LanguageProvider id="gen.edit.report" />
              </span>
            </Tooltip>
            <NavLink
              className="p-button p-button-text btn-edit"
              to={`${
                projectId ? "/" + projectId : ""
              }/reports/edit/${idReport}`}
            >
              <Icon icon="edit-05" color={"#717BBC"} size={undefined} />
            </NavLink>
          </div>
        </div>
        <div className="buttons">
          <Tooltip target=".btn-download-database-list" position="top">
            <span>
              <LanguageProvider id="gen.download" />
            </span>
          </Tooltip>
          <Button
            className="p-button p-button-secondary btn-download-database-list"
            onClick={(e) => downloadOptionsOpRef.current.toggle(e)}
            icon={
              <Icon
                icon="download-01"
                color={"var(--primary)"}
                size={undefined}
              />
            }
          />
        </div>
      </div>
    );
  }

  const onColumnFilterChange = (e) => {
    try {
      let _filterColumns = [...filterColumns];
      if (e.checked) _filterColumns.push(e.value);
      else
        _filterColumns = _filterColumns.filter((column) => column !== e.value);
      const orderedColumns = originalColumnOrder.filter((column) =>
        _filterColumns.some((item) => item === column)
      );
      setFilterColumns(orderedColumns);
    } catch (error) {
      console.error(error);
    }
  };

  const exportExcel = async () => {
    const reportDataForExport = await fetchReportDataForExport(
      idReport,
      report.dataSource.applicationId,
      axiosService,
      defaultQtyToExportFile
    );

    if (reportDataForExport && reportDataForExport.rows) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(reportDataForExport.rows);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        saveFile(excelBuffer, reportData.reportName, ".xlsx");
      });
    }
  };

  const exportCsv = async () => {
    const reportDataForExport = await fetchReportDataForExport(
      idReport,
      report.dataSource.applicationId,
      axiosService,
      defaultQtyToExportFile
    );

    if (reportDataForExport && reportDataForExport.rows) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(reportDataForExport.rows);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const csvBuffer = xlsx.write(workbook, {
          bookType: "csv",
          type: "array",
        });

        saveFile(csvBuffer, reportData.reportName, ".csv");
      });
    }
  };

  const saveFile = (buffer, fileName, ext) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let FILE_EXTENTION = ext;
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_" + new Date().toLocaleString() + FILE_EXTENTION
        );
      }
    });
  };

  useEffect(() => {
    async function load() {
      setLoadingReport(true);
      const reportInfo = await loadReport();
      if (reportInfo) {
        setReport(reportInfo.report);
      }
      setLoadingReport(false);

      setLoadingReportData(true);
      const reportData = await loadReportData(
        reportInfo.dataSource.applicationId
      );

      if (reportData) {
        setReportData(reportData);
        setFilterColumns(reportData.columns);
        setOriginalColumnOrder(reportData.columns);
      }
      setLoadingReportData(false);
    }
    load();
  }, [loadReport, loadReportData]);

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  const exportPdf = async () => {
    const columns = reportData.columns.map((column) => {
      let columnLabel = LanguageProvider({ id: `datasource.column.${column}` });
      return { title: columnLabel, dataKey: column };
    });
    console.log(columns);

    const reportDataFiltered = await loadReportData(
      reportInfoExport.dataSource.applicationId,
      false,
      defaultQtyToExportFile
    );

    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc: any = new jsPDF.default("p", "cm");
        doc.autoTable({ columns: columns, body: reportDataFiltered.rows });
        doc.save(
          reportDataFiltered.reportName +
            "_" +
            new Date().toLocaleString() +
            ".pdf"
        );
      });
    });
  };

  return (
    <div className="report-view">
      <div className="main-content-container">
        <header>
          <PageHeader
            title={report.title}
            recordsCount={reportData.totalRows ?? 0}
            titleTemplate={titleTemplate}
            onReturn={undefined}
            actions={actions}
          />
        </header>
        {loadingReportData ? (
          <SkeletonDatabase
            columnNames={
              reportData.columns
                ? reportData.columns.map((colName) =>
                    LanguageProvider({ id: `datasource.column.${colName}` })
                  )
                : []
            }
          />
        ) : (
          <>
            {reportData.columns && reportData.columns.length > 0 ? (
              <DataTable
                lazy
                value={reportData.rows}
                onSort={setFilters}
                onPage={setFilters}
                sortField={filters.sortField}
                sortOrder={filters.sortOrder}
                globalFilter={globalSearchFilter}
                rows={filters.rows}
                first={filters.first}
                totalRecords={reportData.totalRows}
                scrollable
                scrollHeight="flex"
                paginator
                rowsPerPageOptions={[10, 20, 30, 50]}
                className="export-datatable"
              >
                {Array.isArray(filterColumns) &&
                  filterColumns.map((column, index) => (
                    <Column
                      key={"column-" + index}
                      field={column}
                      header={LanguageProvider({
                        id: `datasource.column.${column}`,
                      })}
                      sortable
                    />
                  ))}
              </DataTable>
            ) : (
              <DataTable
                emptyMessage={
                  <LanguageProvider id={"gen.table.empty.message"} />
                }
              >
                <Column header={<LanguageProvider id="reports.no.columns" />} />
              </DataTable>
            )}
          </>
        )}
      </div>

      {/* Overlay Panels */}
      <OverlayPanel ref={columnsOpRef} className="database-list-columns">
        <div className="popup-wrapper">
          <div className="popup-header">
            <span className="popup-title">
              <LanguageProvider id="gen.columns" />
            </span>
          </div>
          <div className="popup-column-list-wrapper">
            {reportData.columns.map((column) => {
              return (
                <div
                  key={`column-filter-${column}`}
                  className="popup-column-list"
                >
                  <Checkbox
                    className="checkbox-icon"
                    inputId={column}
                    name="columnFilter"
                    value={column}
                    onChange={onColumnFilterChange}
                    checked={filterColumns.some((item) => item === column)}
                  />
                  <label className="column-list-label">
                    <LanguageProvider id={`datasource.column.${column}`} />
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </OverlayPanel>
      <OverlayPanel
        ref={downloadOptionsOpRef}
        className="download-list-columns-report-view"
      >
        <div className="popup-wrapper">
          <div className="popup-header">
            <span className="popup-title">
              <LanguageProvider id="gen.download" />
            </span>
          </div>
          <div className="popup-column-download-wrapper">
            <div
              className="popup-excel-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportExcel();
              }}
            >
              <Icon color={"#98A2B3"} size={undefined} icon={"excel-icon"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.excel"} />
              </label>
            </div>
            <div
              className="popup-pdf-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportPdf();
              }}
            >
              <Icon color={"#98A2B3"} size={undefined} icon={"pdf-icon"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.pdf"} />
              </label>
            </div>
            <div
              className="popup-csv-download-option"
              onClick={(e) => {
                e.preventDefault();
                exportCsv();
              }}
            >
              <Icon color={"#98A2B3"} size={undefined} icon={"csv-icon"} />
              <label className="popup-download-label">
                <LanguageProvider id={"gen.csv"} />
              </label>
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
}

async function fetchReport(
  reportId: number | string,
  axiosService: DcpAxiosService
): Promise<any> {
  try {
    const { data } = await axiosService.get(
      `${settings.Urls.Rest.Report}/${reportId}`,
      "Platform"
    );

    if (!data.data.dataSource) return null;
    return data.data;
  } catch (error) {
    console.error(error);
  }
}

async function fetchReportData(
  reportId: number | string,
  projectId: number | string,
  axiosService: DcpAxiosService,
  filter: Object
): Promise<any> {
  try {
    const apiList = await loadProducts(axiosService);
    const apiObject = apiList.find((api) => api.id === projectId);

    if (!apiObject) {
      throw new Error(`Project ID ${projectId} is not supported`);
    }
    const apiKey =
      apiObject.productCode.charAt(0).toUpperCase() +
      apiObject.productCode.slice(1);
    const { data } = await axiosService.post(
      `${settings.Urls.Rest.Report}/get-data`,
      filter,
      apiKey,
      {
        params: { reportId },
      }
    );

    return data.data ?? {};
  } catch (error) {
    console.error(error);
  }
}

async function fetchReportDataForExport(
  reportId: number | string,
  applicationId: number,
  axiosService: DcpAxiosService,
  rowsLimit: number
): Promise<any> {
  try {
    let requestData = {
      rows: rowsLimit,
    };
    const apiList = await loadProducts(axiosService);
    const apiObject = apiList.find((api) => api.id === applicationId);

    if (!apiObject) {
      throw new Error(`Project ID ${applicationId} is not supported`);
    }

    const apiKey =
      apiObject.productCode.charAt(0).toUpperCase() +
      apiObject.productCode.slice(1);

    const { data } = await axiosService.post(
      `${settings.Urls.Rest.Report}/get-data`,
      requestData,
      apiKey,
      {
        params: { reportId },
      }
    );

    if (!data || !data.data) {
      console.error("Failed to fetch data for export");
      return null;
    }

    return data.data;
  } catch (error) {
    console.error("Error fetching data for export:", error);
    return null;
  }
}
async function loadProducts(axiosService: DcpAxiosService): Promise<any> {
  try {
    const { data: apiData, status } = await axiosService.get(
      `${settings.Urls.Admin.Application}/list`,
      "Auth"
    );

    if (status) {
      return apiData.data;
    }
  } catch (error) {
    console.error(error);
    return;
  }
}
