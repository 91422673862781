import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { getConfigValue } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import "./AvailabilityHorizontalBar.scss";

export default function AvailabilityHorizontalBar(configs) {
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({
    runningTime: 0,
    stopTime: 0,
  });
  const [thresholdValues, setThresholdValues] = useState([0, 0]);

  useEffect(() => {
    if (useMockData) {
      setData({
        runningTime: 794,
        stopTime: 227.4,
      });
      setThresholdValues([70, 40]);
    } else {
      // get value from api
      setData({
        runningTime: 0,
        stopTime: 0,
      });
      setThresholdValues(
        getConfigValue(configs, "persentage-threshold") ?? [0, 0]
      );
    }
  }, [useMockData, configs]);

  const totalTime = data.runningTime + data.stopTime;
  let runningPercentage = (data.runningTime / totalTime) * 100;
  if (isNaN(runningPercentage)) runningPercentage = 0;

  let barColor = "var(--red-500)";
  if (runningPercentage >= thresholdValues[0]) barColor = "var(--green-500)";
  else if (runningPercentage >= thresholdValues[1])
    barColor = "var(--yellow-500)";

  return (
    <div className="availability-horizontal-bar">
      <div className="bar-wrapper">
        <div className="bar">
          <span className="background-fill"></span>
          <span
            className="value"
            style={{ background: barColor, width: `${runningPercentage}%` }}
          ></span>
        </div>
        <span className="percentage-label">
          {runningPercentage.toFixed(1)}%
        </span>
      </div>
      <div className="legend">
        <p>
          Tempo total: <span className="label-value">{`${totalTime} min`}</span>
        </p>
        <p>
          Rodando:{" "}
          <span className="label-value">{`${data.runningTime} min`}</span>
        </p>
        <p>
          Parado: <span className="label-value">{`${data.stopTime} min`}</span>
        </p>
      </div>
    </div>
  );
}
