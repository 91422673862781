import React from "react";
import { classNames } from "primereact/utils";
import { LongTextProps } from ".";
import { InputTextarea } from "primereact/inputtextarea";

export function LongText(props: LongTextProps): JSX.Element {
  return (
    <>
      <InputTextarea
        tooltipOptions={{ position: "top" }}
        tooltip={props.tooltip}
        style={{ width: "100%" }}
        className={classNames({
          "p-invalid": props.error !== null || props.error !== undefined,
        })}
        value={props.value}
        id={props.fieldKey}
        onChange={props.onChange}
        onBlur={(e) => {
          if (props.formik) props.formik.handleBlur(e);
        }}
      />
      {props.error ? (
        <small className="p-error">{props.error}</small>
      ) : (
        <small className="p-error">&nbsp;</small>
      )}
    </>
  );
}
