import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import Icon from "~/shared/components/icons";
import LanguageProvider from "~/shared/components/language-provider";
import "./scss/confirmation-popup.scss";

const DeleteConfirmationOverlay = ({ refProp, onConfirm, onCancel }) => {
  return (
    <OverlayPanel ref={refProp} className="dcp-popup-delete-confirmation">
      <div className="popup-wrapper">
        <div className="popup-header">
          <div className="popup-icon-wrapper">
            <div className="popup-icon-delete">
              <Icon icon="alert-circle" color="#E91F35" size={"20px"} />
            </div>
          </div>
          <div className="popup-body-wrapper">
            <div className="popup-title-wrapper">
              <p className="popup-title-message">
                <LanguageProvider id="gen.confirm.delete.item" />
              </p>
            </div>
            <div className="popup-description-wrapper">
              <p className="popup-body-message">
                <LanguageProvider id="gen.message.confirm.delete.message" />
              </p>
            </div>
          </div>
        </div>
        <div className="popup-footer-wrapper">
          <Button
            label={<LanguageProvider id="gen.message.no" />}
            appearance="subtle"
            className="p-button p-button-text p-button-plain"
            size="small"
            onClick={onCancel}
          ></Button>

          <Button
            label={<LanguageProvider id="gen.message.yes" />}
            appearance="danger"
            className="p-button-danger"
            autoFocus
            size="small"
            onClick={onConfirm}
          ></Button>
        </div>
      </div>
    </OverlayPanel>
  );
};

export default DeleteConfirmationOverlay;
